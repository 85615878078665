import React, {Component} from 'react';

class Popup extends React.Component {
    render() {
        return (
            <div className='popup'>
                <button className="cross-closure" onClick={() => {
                    this.props.handleClose()
                }}>X
                </button>
                {this.props.message}
            </div>
        );
    }
}

export default Popup;