import { babelGetPrestation, babelGetModel} from '../babel/babelVehiclesService'
import {getTotalDaysDifference} from './getTotalDaysDifference'
import { test_remise ,remise,agence_remise,cat_remise,date_fin_remise} from "../configuration"
const _ = require('lodash');

export async function getVehiclesFromAgency(startDate, endDate, AgencyCode) {

    let priceAll = []
    let tabAvailableVehicles = []
    let listAvailableCategorie = []

    const prestation = await babelGetPrestation(14);

    const allModels = await babelGetModel(startDate, endDate, AgencyCode);
    if (allModels[0]) {
        if (allModels[0].model_info) {
            for (let i = 0; i < allModels.length; i++) {
                let obj = {
                    ...allModels[i],
                    model: allModels[i].model_info.reference,
                };
                tabAvailableVehicles = [...tabAvailableVehicles, obj];
            }
        }

        tabAvailableVehicles.forEach(vehicle => {
            for (let i = 0; i < priceAll.length; i++) {
                if (vehicle.cat_code === priceAll[i].catcode) {
                    vehicle = {
                        ...vehicle,
                        tarif: priceAll[i],
                    };
                }
            }
            tabAvailableVehicles = [vehicle, ...tabAvailableVehicles];
        });

        tabAvailableVehicles = _.uniqBy(tabAvailableVehicles, "model");



        tabAvailableVehicles.forEach(vehicle => {
            let franchise
            let location
            let old_location
            let prixKm = 0
            vehicle.vehicle.tariff.prestations.forEach(tarif => {
                if(tarif.code === 'location'){ location = Math.round(+tarif.price_with_VAT) }
                if(tarif.code === 'assRachat'){
                    franchise = Math.round(+tarif.price_with_VAT * getTotalDaysDifference(startDate, endDate))
                }
                if(tarif.code === 'prix_km'){ prixKm = +tarif.price_with_VAT }
            });

            old_location = location + (franchise ? franchise : 0)

            var dateNow = new Date();
            var endDateRemise = new Date(date_fin_remise);
 
            // location = (test_remise === 1 &&  vehicle.vehicle.agency_code == agence_remise && cat_remise.some( category_code => category_code == vehicle.vehicle.category_code) && dateNow < endDateRemise) ? old_location - (old_location * (remise/100)) : old_location
            location = (test_remise === 1) ? old_location - (old_location * (vehicle.vehicle.tariff.discount/100)) : old_location

            let prestations = []
            let objPresta = {}

            for (let i = 0; i < vehicle.vehicle.tariff.prestations.length; i++) {
                let presta = vehicle.vehicle.tariff.prestations[i]
                if(presta.code === "assZen" || presta.code === "yamSecuGants" || presta.code === "yamSecuCasque"){
                    objPresta = {
                        Code: presta.code,
                        Description: presta.description,
                        Id: presta.id,
                        Label: presta.code === 'assZen' ? "Pack zen" : presta.label,
                        Precoche: true,
                        Tarifs: { TTC: parseInt(presta.price_with_VAT), frequence: "j", HT: parseInt(presta.price_without_VAT) },
                        Type: presta.description
                    }
                    prestations.push(objPresta)
                }
            }

            let objVehicle = {
                usol_tarif: vehicle.vehicle.tariff.prestations,

                type: vehicle.category_info.type_code,
                tarif: parseFloat(location.toFixed(2) + ''),
                old_tarif: old_location.toFixed(2),
                franchise,
                location,
                kmInclu: vehicle.vehicle.tariff.kilometers,
                prixKm,

                nomVehicule: vehicle.model_info.name,
                img: vehicle.model_info.image,
                permis: vehicle.model_info.licence,
                cylindree: parseInt(vehicle.model_info.engine_cylinder_capacity, 10),
                transmission: vehicle.model_info.transmission,
                consommation: vehicle.model_info.consumption,
                puissanceMax: vehicle.model_info.max_power,
                hauteurSelle: vehicle.model_info.seat_height,
                gardeSol: vehicle.model_info.ground_clearance,
                poids: vehicle.model_info.total_weight,
                description: vehicle.model_info.descriptive,

                prestation,
                categorie: vehicle.vehicle.category_code,
                totalDays: vehicle.vehicle.tariff.duration,
                tariff_title: vehicle.vehicle.tariff.title,


                vehicle: {
                    ext_id: vehicle.vehicle.id.toString(),
                    model: vehicle.vehicle.model,
                    brand: "Yamaha",
                    category_code: vehicle.vehicle.category_code,
                    plate_number: vehicle.vehicle.plate,
                    picture_uri: vehicle.model_info.image
                },
            };
            listAvailableCategorie.push(objVehicle);
        });
    }

    listAvailableCategorie = _.orderBy(listAvailableCategorie, ['tarif'], ['asc']);
    return listAvailableCategorie;
}
