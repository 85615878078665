import React, { Component } from "react";
import { Link } from "react-router-dom";

// REDUX
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { saveAllTypeVehicles } from "../redux/action";

//BABEL
import { babelGetAllVehicles } from "../babel/babelVehiclesService";
import { Helmet } from "react-helmet";
import { getUserIP } from "../services/getIpAdress";

//TRAD
import { Trans, withTranslation } from "react-i18next";
import { localStorageAgencyCode } from "../configuration";

class VehiclePresentation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tabVehicle: [],
    };
  }

  componentDidMount = () => {
    localStorage.setItem("localStorageSetShopId", localStorageAgencyCode);

    babelGetAllVehicles("SCO,MOT").then((data) => {
      data["MOT"].forEach((element) => {
        //console.log(element.name);
      });

      data["SCO"].forEach((element) => {
        //console.log(element.name);
      });

      this.setState({
        allVehicle: data,
        tabVehicle: data.SCO.sort(this.compare),
      });
    });
  };

  componentDidUpdate(
    prevProps: Readonly<P>,
    prevState: Readonly<S>,
    prevContext: any
  ): void {
    getUserIP((ip) => {
      this.pushTagGtm(ip);
    });
  }

  compare = (a, b) => {
    if (a.category < b.category) return -1;
    if (a.category > b.category) return 1;

    return 0;
  };

  /*----tag GTM-----*/
  pushTagGtm(ip) {
    const internal = [
      "176.149.189.93", //IP Unitec Fibre
      "82.64.74.58", //IP Unitec Guest
      "82.229.26.224", //IP Eric
      "78.237.92.155", //IP CJA
      "172.19.0.1",
    ];

    let traffic = internal.indexOf(ip) === -1 ? "externe" : "interne";

    window.dataLayer.push({
      event: "virtualpageview",
      virtualPath: window.location.pathname,
      page_name: "nos_" + this.props.type,
      page_type: "liste_produit",
      category: this.props.type,
      sub_category_lvl1: "",
      langage: "fr",
      country: "FR",
      product_name: "",
      traffic: traffic,
      agency: "",
    });
  }

  render() {
    /*---------Tag SEO-----------*/
    let metaDescription = "";
    let Description1 = "";
    let Description2 = "";
    let title = "";

    if (this.props.type === "scooters") {
      metaDescription = this.props.t(
        "Louez un scooter 2 ou 3 roues directement dans votre agence Yamaha Rent. Découvrez les gammes de scooters Yamaha TMax, XMax, NMax."
      );
      Description1 = (
        <Trans i18nKey="scooters_desc1">
          Louez un scooter Yamaha pour une journée, un week-end, une semaine ou même un mois. Réservez votre véhicule en ligne avec le paiement 100% sécurisé ou directement dans votre agence Yamaha Rent.
        </Trans>
      );
      Description2 = (
        <Trans i18nKey="scooters_desc2">
          Découvrez notre gamme de scooters Yamaha en location courte durée, TMAX, XMAX 300 ou encore NMAX 125 et réservez celui correspondant à vos besoins. Yamaha Rent, c’est la garantie d’utiliser des scooters récents et entretenus, pour partir l’esprit libre.
        </Trans>
      );
      title = this.props.t(
        "Location scooters Yamaha – Location 2 et 3 roues – Yamaha Rent"
      );
    } else {
      metaDescription = this.props.t(
        "Louez une moto 2 ou 3 roues directement dans votre agence Yamaha Rent. Découvrez les gammes de motos Yamaha et réservez pour une courte durée."
      );
      Description1 = (
        <Trans i18nKey="motos_desc1">
          Louez une moto Yamaha pour une courte durée : une journée, un week-end, une semaine ou plus... via la réservation en ligne 100% sécurisée ou directement dans votre agence Yamaha Rent.
        </Trans>
      );
      Description2 = (
        <Trans i18nKey="motos_desc2">
          Découvrez les motos Yamaha à la location et choisissez un véhicule selon vos besoins : Yamaha MT-07, Tracer 9 GT... Yamaha Rent, c’est la garantie d’utiliser des motos récentes et entretenues, pour partir l’esprit libre.
        </Trans>
      );
      title = this.props.t(
        "Location motos Yamaha – Location 2 et 3 roues – Yamaha Rent"
      );
    }

    let typeVehicles = this.props.type === "scooters" ? "SCO" : "MOT";
    let tabToShow = this.state.allVehicle
      ? this.state.allVehicle[typeVehicles].sort(this.compare)
      : [];

    let tabToShow_50 = tabToShow.filter(
      (data) => data.engine_cylinder_capacity == "50 cm³"
    );
    let tabToShow_e = tabToShow.filter(
      (data) => ["E", "S1"].indexOf(data.category) !== -1
    );
    let tabToShow_125 = tabToShow.filter(
      (data) =>
        data.engine_cylinder_capacity == "125 cm³" ||
        data.engine_cylinder_capacity == "124 cm³"
    );
    // let tabToShow_295 = tabToShow.filter(
    //   (data) => data.engine_cylinder_capacity == "295 cm³"
    // );
    // let tabToShow_325 = tabToShow.filter(
    //   (data) => data.engine_cylinder_capacity == "325 cm³"
    // );

    let tabToShow_300 = tabToShow.filter(
      (data) => 
        data.engine_cylinder_capacity == "300 cm³" ||
        data.engine_cylinder_capacity == "295 cm³"
    );
    // let tabToShow_395 = tabToShow.filter(
    //   (data) => data.engine_cylinder_capacity == "395 cm³"
    // );
    let tabToShow_560 = tabToShow.filter(
      (data) => data.engine_cylinder_capacity == "560 cm³"
    );
    // let tabToShow_689 = tabToShow.filter(
    //   (data) => data.engine_cylinder_capacity == "689 cm³"
    // );

    let tabToShow_400 = tabToShow.filter(
      (data) => data.engine_cylinder_capacity == "325 cm³"
    );

    let tabToShow_700 = tabToShow.filter(
      (data) => 
        data.engine_cylinder_capacity == "689 cm³" ||
        data.engine_cylinder_capacity == "690 cm³"
    );
    // let tabToShow_850 = tabToShow.filter(
    //   (data) => data.engine_cylinder_capacity == "850 cm³"
    // );
    // let tabToShow_889 = tabToShow.filter(
    //   (data) => data.engine_cylinder_capacity == "889 cm³"
    // );
    // let tabToShow_945 = tabToShow.filter(
    //   (data) => data.engine_cylinder_capacity == "945 cm³"
    // );

    let tabToShow_900 = tabToShow.filter(
      (data) => 
        data.engine_cylinder_capacity == "850 cm³" ||
        data.engine_cylinder_capacity == "889 cm³" || 
        data.engine_cylinder_capacity == "890 cm³"
    );

    console.log(tabToShow);

    let tabToShow_1000 = tabToShow.filter(
      (data) => data.engine_cylinder_capacity == "1000 cm³"
    );
    let tabToShow_1200 = tabToShow.filter(
      (data) => data.engine_cylinder_capacity == "1200 cm³"
    );
    let tabToShow_1300 = tabToShow.filter(
      (data) => data.engine_cylinder_capacity == "1300 cm³"
    );

    let objPrice = {
      E: 39,
      S1: 39,
      S2: 49,
      S3: 59,
      S4: 99,
      S5: 139,
      M1: 49,
      M2: 59,
      M3: 99,
      M4: 119,
      M5: 139,
      M3B: 99,
      M4B: 119
    };

    return (
      <div className="vehiclePresentationContainer">
        <Helmet>
          <meta name="description" content={metaDescription} />
          <meta name="title" content={title} />
          <title>{title}</title>
        </Helmet>
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              {" "}
              <Link to="/">
                <Trans i18nKey="Accueil">Accueil</Trans>
              </Link>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              <Trans i18nKey="location_de">Location de </Trans>
              {this.props.type}
              <Trans i18nKey="location_Yamaha"> Yamaha</Trans>
            </li>
          </ol>
        </nav>
        <h1>
          {" "}
          <Trans i18nKey="location_de">Location de </Trans>
          {this.props.type}
          <Trans i18nKey="location_Yamaha"> Yamaha</Trans>
        </h1>
        <p>{Description1}<br/>{Description2}</p>
        {tabToShow_e.length > 0 ? (
          <h2 class="mb-1">
            <Trans i18nKey="location_de">Location </Trans>
            {this.props.type} <Trans i18nKey="électriques">électriques </Trans>
          </h2>
        ) : (
          ""
        )}
        {tabToShow_e.length > 0 ? (
          <div id="PresentationContent_50">
            {tabToShow_e.map((val, i) => {
              return (
                <div key={i} className="vehiclePresentation">
                  <Link
                    to={{
                      pathname:
                        "/" +
                        this.props.t("vehicule") +
                        "/" +
                        val.type.toLowerCase() +
                        "s/" +
                        val.reference,
                    }}
                  >
                    <div className="imagePresentation">
                      <img alt="véhicule" src={val.image} />
                      <p className="grey alignWithBtn" style={{ fontSize: 10 }}>
                        <Trans i18nKey="photo">
                          Photo non contractuelle, la version et le coloris
                          peuvent différer en fonction des disponibilités en
                          agence.
                        </Trans>
                      </p>
                    </div>
                    <div className="nomPresentation">{val.name}</div>
                    <div className="cylindreePresentation">
                    {val.Moteur == "Électrique" ?
                 <Trans i18nKey="équivalent">Équivalent </Trans>
                 : null
                  }
                 {val.Moteur == "Électrique" ?
                 val.engine_cylinder_capacity
                  :
                  val.engine_cylinder_capacity
                } 
                    </div>

                    <div className="prixPresentation">
                                     <b>
                                       <Trans i18nKey="a_partir">à partir de</Trans>
                                     </b>{" "}
                                     {objPrice[val.category]},00 €{" "}
                                     <Trans i18nKey="jour">/ jour</Trans>
                                   </div>

                    
                  </Link>
                </div>
              );
            })}
          </div>
        ) : (
          ""
        )}
        {tabToShow_50.length > 0 ? (
          <h2 class="mb-1">
            <Trans i18nKey="location_de">Location </Trans>
            {this.props.type} 50 cm³
          </h2>
        ) : (
          ""
        )}
        {tabToShow_50.length > 0 ? (
          <div id="PresentationContent_50">
            {tabToShow_50.map((val, i) => {
              return (
                <div key={i} className="vehiclePresentation">
                  <Link
                    to={{
                      pathname:
                        "/" +
                        this.props.t("vehicule") +
                        "/" +
                        val.type.toLowerCase() +
                        "s/" +
                        val.reference,
                    }}
                  >
                    <div className="imagePresentation">
                      <img alt="véhicule" src={val.image} />
                      <p className="grey alignWithBtn" style={{ fontSize: 10 }}>
                        <Trans i18nKey="photo">
                          Photo non contractuelle, la version et le coloris
                          peuvent différer en fonction des disponibilités en
                          agence.
                        </Trans>
                      </p>
                    </div>
                    <div className="nomPresentation">{val.name}</div>
                    <div className="cylindreePresentation">
                    {val.Moteur == "Électrique" ?
                 <Trans i18nKey="équivalent">Équivalent </Trans>
                 : null
                  }
                 {val.Moteur == "Électrique" ?
                 val.engine_cylinder_capacity
                  :
                  val.engine_cylinder_capacity
                } 
                    </div>

                    <div className="prixPresentation">
                                     <b>
                                       <Trans i18nKey="a_partir">à partir de</Trans>
                                     </b>{" "}
                                     {objPrice[val.category]},00 €{" "}
                                     <Trans i18nKey="jour">/ jour</Trans>
                                   </div>

                    
                  </Link>
                </div>
              );
            })}
          </div>
        ) : (
          ""
        )}
        {tabToShow_125.length > 0 ? (
          <h2 class="mb-1">
            <Trans i18nKey="location_de">Location </Trans>
            {this.props.type} 125 cm³
          </h2>
        ) : (
          ""
        )}
        {tabToShow_125.length > 0 ? (
          <div id="PresentationContent_125">
            {tabToShow_125.map((val, i) => {
              return (
                <div key={i} className="vehiclePresentation">
                  <Link
                    to={{
                      pathname:
                        "/" +
                        this.props.t("vehicule") +
                        "/" +
                        val.type.toLowerCase() +
                        "s/" +
                        val.reference,
                    }}
                  >
                    <div className="imagePresentation">
                      <img alt="véhicule" src={val.image} />
                      <p className="grey alignWithBtn" style={{ fontSize: 10 }}>
                        <Trans i18nKey="photo">
                          Photo non contractuelle, la version et le coloris
                          peuvent différer en fonction des disponibilités en
                          agence.
                        </Trans>
                      </p>
                    </div>
                    <div className="nomPresentation">{val.name}</div>
                    <div className="cylindreePresentation">
                      {val.engine_cylinder_capacity}
                    </div>
                    <div className="prixPresentation">
                      <b>
                        <Trans i18nKey="a_partir">à partir de</Trans>
                      </b>{" "}
                      {objPrice[val.category]},00 €{" "}
                      <Trans i18nKey="jour">/ jour</Trans>
                    </div>
                  </Link>
                </div>
              );
            })}
          </div>
        ) : (
          ""
        )}
        {/* {tabToShow_295.length > 0 ? (
          <h2 class="mb-1">
            <Trans i18nKey="location_de">Location </Trans>
            {this.props.type} 295 cc
          </h2>
        ) : (
          ""
        )} */}
        {/* {tabToShow_295.length > 0 ? (
          <div id="PresentationContent_295">
            {tabToShow_295.map((val, i) => {
              return (
                <div key={i} className="vehiclePresentation">
                  <Link
                    to={{
                      pathname:
                        "/" +
                        this.props.t("vehicule") +
                        "/" +
                        val.type.toLowerCase() +
                        "s/" +
                        val.reference,
                    }}
                  >
                    <div className="imagePresentation">
                      <img alt="véhicule" src={val.image} />
                      <p className="grey alignWithBtn" style={{ fontSize: 10 }}>
                        <Trans i18nKey="photo">
                          Photo non contractuelle, la version et le coloris
                          peuvent différer en fonction des disponibilités en
                          agence.
                        </Trans>
                      </p>
                    </div>
                    <div className="nomPresentation">{val.name}</div>
                    <div className="cylindreePresentation">
                      {val.engine_cylinder_capacity}
                    </div>
                    <div className="prixPresentation">
                      <b>
                        <Trans i18nKey="a_partir">à partir de</Trans>
                      </b>{" "}
                      {objPrice[val.category]},00 €{" "}
                      <Trans i18nKey="jour">/ jour</Trans>
                    </div>
                  </Link>
                </div>
              );
            })}
          </div>
        ) : (
          ""
        )} */}
        {tabToShow_300.length > 0 ? (
          <h2 class="mb-1">
            <Trans i18nKey="location_de">Location </Trans>
            {this.props.type} 300 cm³
          </h2>
        ) : (
          ""
        )}
        {tabToShow_300.length > 0 ? (
          <div id="PresentationContent_300">
            {tabToShow_300.map((val, i) => {
              return (
                <div key={i} className="vehiclePresentation">
                  <Link
                    to={{
                      pathname:
                        "/" +
                        this.props.t("vehicule") +
                        "/" +
                        val.type.toLowerCase() +
                        "s/" +
                        val.reference,
                    }}
                  >
                    <div className="imagePresentation">
                      <img alt="véhicule" src={val.image} />
                      <p className="grey alignWithBtn" style={{ fontSize: 10 }}>
                        <Trans i18nKey="photo">
                          Photo non contractuelle, la version et le coloris
                          peuvent différer en fonction des disponibilités en
                          agence.
                        </Trans>
                      </p>
                    </div>
                    <div className="nomPresentation">{val.name}</div>
                    <div className="cylindreePresentation">
                      {val.engine_cylinder_capacity}
                    </div>
                    <div className="prixPresentation">
                      <b>
                        <Trans i18nKey="a_partir">à partir de</Trans>
                      </b>{" "}
                      {objPrice[val.category]},00 €{" "}
                      <Trans i18nKey="jour">/ jour</Trans>
                    </div>
                  </Link>
                </div>
              );
            })}
          </div>
        ) : (
          ""
        )}
        {tabToShow_560.length > 0 ? (
          <h2 class="mb-1">
            <Trans i18nKey="location_de">Location </Trans>
            {this.props.type} 560 cm³
          </h2>
        ) : (
          ""
        )}
        {tabToShow_560.length > 0 ? (
          <div id="PresentationContent_300">
            {tabToShow_560.map((val, i) => {
              return (
                <div key={i} className="vehiclePresentation">
                  <Link
                    to={{
                      pathname:
                        "/" +
                        this.props.t("vehicule") +
                        "/" +
                        val.type.toLowerCase() +
                        "s/" +
                        val.reference,
                    }}
                  >
                    <div className="imagePresentation">
                      <img alt="véhicule" src={val.image} />
                      <p className="grey alignWithBtn" style={{ fontSize: 10 }}>
                        <Trans i18nKey="photo">
                          Photo non contractuelle, la version et le coloris
                          peuvent différer en fonction des disponibilités en
                          agence.
                        </Trans>
                      </p>
                    </div>
                    <div className="nomPresentation">{val.name}</div>
                    <div className="cylindreePresentation">
                      {val.engine_cylinder_capacity}
                    </div>
                    <div className="prixPresentation">
                      <b>
                        <Trans i18nKey="a_partir">à partir de</Trans>
                      </b>{" "}
                      {objPrice[val.category]},00 €{" "}
                      <Trans i18nKey="jour">/ jour</Trans>
                    </div>
                  </Link>
                </div>
              );
            })}
          </div>
        ) : (
          ""
        )}

        {tabToShow_400.length > 0 ? (
          <h2 class="mb-1">
            <Trans i18nKey="location_de">Location </Trans>
            {this.props.type} 400 cm³
          </h2>
        ) : (
          ""
        )}
        {tabToShow_400.length > 0 ? (
          <div id="PresentationContent_300">
            {tabToShow_400.map((val, i) => {
              return (
                <div key={i} className="vehiclePresentation">
                  <Link
                    to={{
                      pathname:
                        "/" +
                        this.props.t("vehicule") +
                        "/" +
                        val.type.toLowerCase() +
                        "s/" +
                        val.reference,
                    }}
                  >
                    <div className="imagePresentation">
                      <img alt="véhicule" src={val.image} />
                      <p className="grey alignWithBtn" style={{ fontSize: 10 }}>
                        <Trans i18nKey="photo">
                          Photo non contractuelle, la version et le coloris
                          peuvent différer en fonction des disponibilités en
                          agence.
                        </Trans>
                      </p>
                    </div>
                    <div className="nomPresentation">{val.name}</div>
                    <div className="cylindreePresentation">
                      {val.engine_cylinder_capacity}
                    </div>
                    <div className="prixPresentation">
                      <b>
                        <Trans i18nKey="a_partir">à partir de</Trans>
                      </b>{" "}
                      {objPrice[val.category]},00 €{" "}
                      <Trans i18nKey="jour">/ jour</Trans>
                    </div>
                  </Link>
                </div>
              );
            })}
          </div>
        ) : (
          ""
        )}

        {tabToShow_700.length > 0 ? (
          <h2 class="mb-1">
            <Trans i18nKey="location_de">Location </Trans>
            {this.props.type} 700 cm³
          </h2>
        ) : (
          ""
        )}
        {tabToShow_700.length > 0 ? (
          <div id="PresentationContent_300">
            {tabToShow_700.map((val, i) => {
              return (
                <div key={i} className="vehiclePresentation">
                  <Link
                    to={{
                      pathname:
                        "/" +
                        this.props.t("vehicule") +
                        "/" +
                        val.type.toLowerCase() +
                        "s/" +
                        val.reference,
                    }}
                  >
                    <div className="imagePresentation">
                      <img alt="véhicule" src={val.image} />
                      <p className="grey alignWithBtn" style={{ fontSize: 10 }}>
                        <Trans i18nKey="photo">
                          Photo non contractuelle, la version et le coloris
                          peuvent différer en fonction des disponibilités en
                          agence.
                        </Trans>
                      </p>
                    </div>
                    <div className="nomPresentation">{val.name}</div>
                    <div className="cylindreePresentation">
                      {val.engine_cylinder_capacity}
                    </div>
                    <div className="prixPresentation">
                      <b>
                        <Trans i18nKey="a_partir">à partir de</Trans>
                      </b>{" "}
                      {objPrice[val.category]},00 €{" "}
                      <Trans i18nKey="jour">/ jour</Trans>
                    </div>
                  </Link>
                </div>
              );
            })}
          </div>
        ) : (
          ""
        )}

        {tabToShow_900.length > 0 ? (
          <h2 class="mb-1">
            <Trans i18nKey="location_de">Location </Trans>
            {this.props.type} 900 cm³
          </h2>
        ) : (
          ""
        )}
        {tabToShow_900.length > 0 ? (
          <div id="PresentationContent_300">
            {tabToShow_900.map((val, i) => {
              return (
                <div key={i} className="vehiclePresentation">
                  <Link
                    to={{
                      pathname:
                        "/" +
                        this.props.t("vehicule") +
                        "/" +
                        val.type.toLowerCase() +
                        "s/" +
                        val.reference,
                    }}
                  >
                    <div className="imagePresentation">
                      <img alt="véhicule" src={val.image} />
                      <p className="grey alignWithBtn" style={{ fontSize: 10 }}>
                        <Trans i18nKey="photo">
                          Photo non contractuelle, la version et le coloris
                          peuvent différer en fonction des disponibilités en
                          agence.
                        </Trans>
                      </p>
                    </div>
                    <div className="nomPresentation">{val.name}</div>
                    <div className="cylindreePresentation">
                      {val.engine_cylinder_capacity}
                    </div>
                    <div className="prixPresentation">
                      <b>
                        <Trans i18nKey="a_partir">à partir de</Trans>
                      </b>{" "}
                      {objPrice[val.category]},00 €{" "}
                      <Trans i18nKey="jour">/ jour</Trans>
                    </div>
                  </Link>
                </div>
              );
            })}
          </div>
        ) : (
          ""
        )}

        {/* {tabToShow_325.length > 0 ? (
          <h2 class="mb-1">
            <Trans i18nKey="location_de">Location </Trans>
            {this.props.type} 325 cm³
          </h2>
        ) : (
          ""
        )}
        {tabToShow_325.length > 0 ? (
          <div id="PresentationContent_300">
            {tabToShow_325.map((val, i) => {
              return (
                <div key={i} className="vehiclePresentation">
                  <Link
                    to={{
                      pathname:
                        "/" +
                        this.props.t("vehicule") +
                        "/" +
                        val.type.toLowerCase() +
                        "s/" +
                        val.reference,
                    }}
                  >
                    <div className="imagePresentation">
                      <img alt="véhicule" src={val.image} />
                      <p className="grey alignWithBtn" style={{ fontSize: 10 }}>
                        <Trans i18nKey="photo">
                          Photo non contractuelle, la version et le coloris
                          peuvent différer en fonction des disponibilités en
                          agence.
                        </Trans>
                      </p>
                    </div>
                    <div className="nomPresentation">{val.name}</div>
                    <div className="cylindreePresentation">
                      {val.engine_cylinder_capacity}
                    </div>
                    <div className="prixPresentation">
                      <b>
                        <Trans i18nKey="a_partir">à partir de</Trans>
                      </b>{" "}
                      {objPrice[val.category]},00 €{" "}
                      <Trans i18nKey="jour">/ jour</Trans>
                    </div>
                  </Link>
                </div>
              );
            })}
          </div>
        ) : (
          ""
        )} */}

        {/* {tabToShow_395.length > 0 ? (
          <h2 class="mb-1">
            <Trans i18nKey="location_de">Location </Trans>
            {this.props.type} 395 cm³
          </h2>
        ) : (
          ""
        )} */}
        {/* {tabToShow_395.length > 0 ? (
          <div id="PresentationContent_395">
            {tabToShow_395.map((val, i) => {
              return (
                <div key={i} className="vehiclePresentation">
                  <Link
                    to={{
                      pathname:
                        "/" +
                        this.props.t("vehicule") +
                        "/" +
                        val.type.toLowerCase() +
                        "s/" +
                        val.reference,
                    }}
                  >
                    <div className="imagePresentation">
                      <img alt="véhicule" src={val.image} />
                      <p className="grey alignWithBtn" style={{ fontSize: 10 }}>
                        <Trans i18nKey="photo">
                          Photo non contractuelle, la version et le coloris
                          peuvent différer en fonction des disponibilités en
                          agence.
                        </Trans>
                      </p>
                    </div>
                    <div className="nomPresentation">{val.name}</div>
                    <div className="cylindreePresentation">
                      {val.engine_cylinder_capacity}
                    </div>
                    <div className="prixPresentation">
                      <b>
                        <Trans i18nKey="a_partir">à partir de</Trans>
                      </b>{" "}
                      {objPrice[val.category]},00 €{" "}
                      <Trans i18nKey="jour">/ jour</Trans>
                    </div>
                  </Link>
                </div>
              );
            })}
          </div>
        ) : (
          ""
        )} */}
        {/* {tabToShow_530.length > 0 ? (
          <h2 class="mb-1">
            <Trans i18nKey="location_de">Location </Trans>
            {this.props.type} 560 cm³
          </h2>
        ) : (
          ""
        )}
        {tabToShow_530.length > 0 ? (
          <div id="PresentationContent_530">
            {tabToShow_530.map((val, i) => {
              return (
                <div key={i} className="vehiclePresentation">
                  <Link
                    to={{
                      pathname:
                        "/" +
                        this.props.t("vehicule") +
                        "/" +
                        val.type.toLowerCase() +
                        "s/" +
                        val.reference,
                    }}
                  >
                    <div className="imagePresentation">
                      <img alt="véhicule" src={val.image} />
                      <p className="grey alignWithBtn" style={{ fontSize: 10 }}>
                        <Trans i18nKey="photo">
                          Photo non contractuelle, la version et le coloris
                          peuvent différer en fonction des disponibilités en
                          agence.
                        </Trans>
                      </p>
                    </div>
                    <div className="nomPresentation">{val.name}</div>
                    <div className="cylindreePresentation">
                      {val.engine_cylinder_capacity}
                    </div>
                    <div className="prixPresentation">
                      <b>
                        <Trans i18nKey="a_partir">à partir de</Trans>
                      </b>{" "}
                      {objPrice[val.category]},00 €{" "}
                      <Trans i18nKey="jour">/ jour</Trans>
                    </div>
                  </Link>
                </div>
              );
            })}
          </div>
        ) : (
          ""
        )} */}
        {/* {tabToShow_689.length > 0 ? (
          <h2 class="mb-1">
            <Trans i18nKey="location_de">Location </Trans>
            {this.props.type} 689 cc
          </h2>
        ) : (
          ""
        )} */}
        {/* {tabToShow_689.length > 0 ? (
          <div id="PresentationContent_689">
            {tabToShow_689.map((val, i) => {
              return (
                <div key={i} className="vehiclePresentation">
                  <Link
                    to={{
                      pathname:
                        "/" +
                        this.props.t("vehicule") +
                        "/" +
                        val.type.toLowerCase() +
                        "s/" +
                        val.reference,
                    }}
                  >
                    <div className="imagePresentation">
                      <img alt="véhicule" src={val.image} />
                      <p className="grey alignWithBtn" style={{ fontSize: 10 }}>
                        <Trans i18nKey="photo">
                          Photo non contractuelle, la version et le coloris
                          peuvent différer en fonction des disponibilités en
                          agence.
                        </Trans>
                      </p>
                    </div>
                    <div className="nomPresentation">{val.name}</div>
                    <div className="cylindreePresentation">
                      {val.engine_cylinder_capacity}
                    </div>
                    <div className="prixPresentation">
                      <b>
                        <Trans i18nKey="a_partir">à partir de</Trans>
                      </b>{" "}
                      {objPrice[val.category]},00 €{" "}
                      <Trans i18nKey="jour">/ jour</Trans>
                    </div>
                  </Link>
                </div>
              );
            })}
          </div>
        ) : (
          ""
        )} */}
        {/* {tabToShow_690.length > 0 ? (
          <h2 class="mb-1">
            <Trans i18nKey="location_de">Location </Trans>
            {this.props.type} 700 cm³
          </h2>
        ) : (
          ""
        )}
        {tabToShow_690.length > 0 ? (
          <div id="PresentationContent_690">
            {tabToShow_690.map((val, i) => {
              return (
                <div key={i} className="vehiclePresentation">
                  <Link
                    to={{
                      pathname:
                        "/" +
                        this.props.t("vehicule") +
                        "/" +
                        val.type.toLowerCase() +
                        "s/" +
                        val.reference,
                    }}
                  >
                    <div className="imagePresentation">
                      <img alt="véhicule" src={val.image} />
                      <p className="grey alignWithBtn" style={{ fontSize: 10 }}>
                        <Trans i18nKey="photo">
                          Photo non contractuelle, la version et le coloris
                          peuvent différer en fonction des disponibilités en
                          agence.
                        </Trans>
                      </p>
                    </div>
                    <div className="nomPresentation">{val.name}</div>
                    <div className="cylindreePresentation">
                      {val.engine_cylinder_capacity}
                    </div>
                    <div className="prixPresentation">
                      <b>
                        <Trans i18nKey="a_partir">à partir de</Trans>
                      </b>{" "}
                      {objPrice[val.category]},00 €{" "}
                      <Trans i18nKey="jour">/ jour</Trans>
                    </div>
                  </Link>
                </div>
              );
            })}
          </div>
        ) : (
          ""
        )} */}
        {/* {tabToShow_850.length > 0 ? (
          <h2 class="mb-1">
            <Trans i18nKey="location_de">Location </Trans>
            {this.props.type} 850 cc
          </h2>
        ) : (
          ""
        )} */}
        {/* {tabToShow_850.length > 0 ? (
          <div id="PresentationContent_850">
            {tabToShow_850.map((val, i) => {
              return (
                <div key={i} className="vehiclePresentation">
                  <Link
                    to={{
                      pathname:
                        "/" +
                        this.props.t("vehicule") +
                        "/" +
                        val.type.toLowerCase() +
                        "s/" +
                        val.reference,
                    }}
                  >
                    <div className="imagePresentation">
                      <img alt="véhicule" src={val.image} />
                      <p className="grey alignWithBtn" style={{ fontSize: 10 }}>
                        <Trans i18nKey="photo">
                          Photo non contractuelle, la version et le coloris
                          peuvent différer en fonction des disponibilités en
                          agence.
                        </Trans>
                      </p>
                    </div>
                    <div className="nomPresentation">{val.name}</div>
                    <div className="cylindreePresentation">
                      {val.engine_cylinder_capacity}
                    </div>
                    <div className="prixPresentation">
                      <b>
                        <Trans i18nKey="a_partir">à partir de</Trans>
                      </b>{" "}
                      {objPrice[val.category]},00 €{" "}
                      <Trans i18nKey="jour">/ jour</Trans>
                    </div>
                  </Link>
                </div>
              );
            })}
          </div>
        ) : (
          ""
        )} */}

        {/* {tabToShow_889.length > 0 ? (
          <h2 class="mb-1">
            <Trans i18nKey="location_de">Location </Trans>
            {this.props.type} 900 cm³
          </h2>
        ) : (
          ""
        )}
        {tabToShow_889.length > 0 ? (
          <div id="PresentationContent_889">
            {tabToShow_889.map((val, i) => {
              return (
                <div key={i} className="vehiclePresentation">
                  <Link
                    to={{
                      pathname:
                        "/" +
                        this.props.t("vehicule") +
                        "/" +
                        val.type.toLowerCase() +
                        "s/" +
                        val.reference,
                    }}
                  >
                    <div className="imagePresentation">
                      <img alt="véhicule" src={val.image} />
                      <p className="grey alignWithBtn" style={{ fontSize: 10 }}>
                        <Trans i18nKey="photo">
                          Photo non contractuelle, la version et le coloris
                          peuvent différer en fonction des disponibilités en
                          agence.
                        </Trans>
                      </p>
                    </div>
                    <div className="nomPresentation">{val.name}</div>
                    <div className="cylindreePresentation">
                      {val.engine_cylinder_capacity}
                    </div>
                    <div className="prixPresentation">
                      <b>
                        <Trans i18nKey="a_partir">à partir de</Trans>
                      </b>{" "}
                      {objPrice[val.category]},00 €{" "}
                      <Trans i18nKey="jour">/ jour</Trans>
                    </div>
                  </Link>
                </div>
              );
            })}
          </div>
        ) : (
          ""
        )} */}

        {/* {tabToShow_945.length > 0 ? (
          <h2 class="mb-1">
            <Trans i18nKey="location_de">Location </Trans>
            {this.props.type} 945 cc
          </h2>
        ) : (
          ""
        )} */}
        {/* {tabToShow_945.length > 0 ? (
          <div id="PresentationContent_945">
            {tabToShow_945.map((val, i) => {
              return (
                <div key={i} className="vehiclePresentation">
                  <Link
                    to={{
                      pathname:
                        "/" +
                        this.props.t("vehicule") +
                        "/" +
                        val.type.toLowerCase() +
                        "s/" +
                        val.reference,
                    }}
                  >
                    <div className="imagePresentation">
                      <img alt="véhicule" src={val.image} />
                      <p className="grey alignWithBtn" style={{ fontSize: 10 }}>
                        <Trans i18nKey="photo">
                          Photo non contractuelle, la version et le coloris
                          peuvent différer en fonction des disponibilités en
                          agence.
                        </Trans>
                      </p>
                    </div>
                    <div className="nomPresentation">{val.name}</div>
                    <div className="cylindreePresentation">
                      {val.engine_cylinder_capacity}
                    </div>
                    <div className="prixPresentation">
                      <b>
                        <Trans i18nKey="a_partir">à partir de</Trans>
                      </b>{" "}
                      {objPrice[val.category]},00 €{" "}
                      <Trans i18nKey="jour">/ jour</Trans>
                    </div>
                  </Link>
                </div>
              );
            })}
          </div>
        ) : (
          ""
        )} */}
        {tabToShow_1000.length > 0 ? (
          <h2 class="mb-1">
            <Trans i18nKey="location_de">Location </Trans>
            {this.props.type} 1000 cm³
          </h2>
        ) : (
          ""
        )}
        {tabToShow_1000.length > 0 ? (
          <div id="PresentationContent_1000">
            {tabToShow_1000.map((val, i) => {
              return (
                <div key={i} className="vehiclePresentation">
                  <Link
                    to={{
                      pathname:
                        "/" +
                        this.props.t("vehicule") +
                        "/" +
                        val.type.toLowerCase() +
                        "s/" +
                        val.reference,
                    }}
                  >
                    <div className="imagePresentation">
                      <img alt="véhicule" src={val.image} />
                      <p className="grey alignWithBtn" style={{ fontSize: 10 }}>
                        <Trans i18nKey="photo">
                          Photo non contractuelle, la version et le coloris
                          peuvent différer en fonction des disponibilités en
                          agence.
                        </Trans>
                      </p>
                    </div>
                    <div className="nomPresentation">{val.name}</div>
                    <div className="cylindreePresentation">
                      {val.engine_cylinder_capacity}
                    </div>
                    <div className="prixPresentation">
                      <b>
                        <Trans i18nKey="a_partir">à partir de</Trans>
                      </b>{" "}
                      {objPrice[val.category]},00 €{" "}
                      <Trans i18nKey="jour">/ jour</Trans>
                    </div>
                  </Link>
                </div>
              );
            })}
          </div>
        ) : (
          ""
        )}
        {/* {tabToShow_1200.length > 0 ? (
          <h2 class="mb-1">
            <Trans i18nKey="location_de">Location </Trans>
            {this.props.type} 1200 cc
          </h2>
        ) : (
          ""
        )} */}
        {/* {tabToShow_1200.length > 0 ? (
          <div id="PresentationContent_1200">
            {tabToShow_1200.map((val, i) => {
              return (
                <div key={i} className="vehiclePresentation">
                  <Link
                    to={{
                      pathname:
                        "/" +
                        this.props.t("vehicule") +
                        "/" +
                        val.type.toLowerCase() +
                        "s/" +
                        val.reference,
                    }}
                  >
                    <div className="imagePresentation">
                      <img alt="véhicule" src={val.image} />
                      <p className="grey alignWithBtn" style={{ fontSize: 10 }}>
                        <Trans i18nKey="photo">
                          Photo non contractuelle, la version et le coloris
                          peuvent différer en fonction des disponibilités en
                          agence.
                        </Trans>
                      </p>
                    </div>
                    <div className="nomPresentation">{val.name}</div>
                    <div className="cylindreePresentation">
                      {val.engine_cylinder_capacity}
                    </div>
                    <div className="prixPresentation">
                      <b>
                        <Trans i18nKey="a_partir">à partir de</Trans>
                      </b>{" "}
                      {objPrice[val.category]},00 €{" "}
                      <Trans i18nKey="jour">/ jour</Trans>
                    </div>
                  </Link>
                </div>
              );
            })}
          </div>
        ) : (
          ""
        )} */}
        {tabToShow_1300.length > 0 ? (
          <h2 class="mb-1">
            <Trans i18nKey="location_de">Location </Trans>
            {this.props.type} 1300 cm³
          </h2>
        ) : (
          ""
        )}
        {tabToShow_1300.length > 0 ? (
          <div id="PresentationContent_1300">
            {tabToShow_1300.map((val, i) => {
              return (
                <div key={i} className="vehiclePresentation">
                  <Link
                    to={{
                      pathname:
                        "/" +
                        this.props.t("vehicule") +
                        "/" +
                        val.type.toLowerCase() +
                        "s/" +
                        val.reference,
                    }}
                  >
                    <div className="imagePresentation">
                      <img alt="véhicule" src={val.image} />
                      <p className="grey alignWithBtn" style={{ fontSize: 10 }}>
                        <Trans i18nKey="photo">
                          Photo non contractuelle, la version et le coloris
                          peuvent différer en fonction des disponibilités en
                          agence.
                        </Trans>
                      </p>
                    </div>
                    <div className="nomPresentation">{val.name}</div>
                    <div className="cylindreePresentation">
                      {val.engine_cylinder_capacity}
                    </div>
                    <div className="prixPresentation">
                      <b>
                        <Trans i18nKey="a_partir">à partir de</Trans>
                      </b>{" "}
                      {objPrice[val.category]},00 €{" "}
                      <Trans i18nKey="jour">/ jour</Trans>
                    </div>
                  </Link>
                </div>
              );
            })}
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    typeVehicles: state.reducer.typeVehicles,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      saveAllTypeVehicles,
    },
    dispatch
  );
}

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(VehiclePresentation)
);
