import React, {Component} from 'react';
import PlacesAutocomplete from 'react-places-autocomplete';

import {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';

import geolib from 'geolib';
import {babelGetHoraires} from '../babel/babelAgenciesService'

//STYLE
import {Container, Row, Col, Button, Input} from 'reactstrap';

// REDUX
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import {saveSelectedAgency, saveCityPosition, saveStartDate, saveEndDate, saveStartDateUnformated, saveEndDateUnformated, saveSearchHour, saveUnformatedDate} from '../redux/action'

// ROUTER
import {Link} from 'react-router-dom'

//MODULES
import DayPicker from '../modules/dayPicker'

import logo_planning from '../assets/img/logo_planning_psd_home_cyril.png'
import {VehiclesOffersSearch} from "../services/vehiclesOffers/VehiclesOffersSearch";
import {Helmet} from "react-helmet";
import {IdTagGeolid} from "../services/analytics/geolid/idTagGeolid";

//TRANSLATION
import {Trans, withTranslation} from 'react-i18next';

class Search extends Component {
     isvalid = true;
    constructor(props) {
        super(props);
		
        this.state = {
            address: '',
            closeDays: [],
            showAgency: false,
            agencies: [],
            agencyArround: [],
            showHourPicker: 0,
            startHour: '08',
            endHour: '18',
            DateDepart:0,
            horaire: [],
            numberDays: 7,
            tabWeek: [],
            horaireAgence: [],
            startTabHour: [],
            endTabHour: [],
            startSelectHour: false,
            endSelectHour: false
        };
		
        this.showStartDate = this.showStartDate.bind(this)
        this.endDate = this.showEndDate.bind(this)
        this.placesAutoCompleteRref = React.createRef()
    }

    componentDidUpdate = (prev) => {
        if (prev.startDateUnformated !== this.props.startDateUnformated && this.props.selectedAgency && !this.state.startSelectHour) {
            babelGetHoraires(this.props.selectedAgency.AgenceCode).then(data => {
                this.defineHours(data, 'start')
            })
        }
		
        if (prev.endDateUnformated !== this.props.endDateUnformated && this.props.selectedAgency && !this.state.endSelectHour) {
            babelGetHoraires(this.props.selectedAgency.AgenceCode).then(data => {
                this.defineHours(data, 'end')
            })
        }
    }

    componentDidMount() {
        this.props.onAutoSearch(this)
    }

    componentWillUnmount() {
        this.props.onAutoSearch(null)
    }

    handleChange = address => {
        if (address.includes('Choisir') || address.includes('agence') || address.includes('autour')) {
            address = ''
        }

        this.setState({
            address,
            showAgency: false
        });
    };

    autoSearch(address, agency) {
        this.placesAutoCompleteRref.current.autocompleteService.getQueryPredictions({
            input: address
        }, (results) => {
        })
    }

    showStartDate() {
        //TODO: fix it
        //document.querySelector('#searchBg [placeholder="Départ"], #searchBg [placeholder="Departure"]').focus()
    }

    showEndDate() {
        //TODO: fix it
        //document.querySelector('#searchBg [placeholder="Retour"], #searchBg [placeholder="Return"]').focus()
    }

    handleSelect = address => {
        this.setState({address: this.props.t('Choisir une agence autour de ') + address, showAgency: true});
		
        geocodeByAddress(address)
            .then(results => getLatLng(results[0]))
            .then(latLng => {
                let agencyArround = []
                let listCodeAgencies = ''
                let listAgencies = JSON.parse(localStorage.getItem('listAllAgencies'))

                for (let i = 0; i < listAgencies.length; i++) {
                    let isAgencyArround
                    
					if (listAgencies[i].GpsLatitude && listAgencies[i].GpsLatitude !== 'vide') {
                        isAgencyArround = geolib.isPointInCircle(
                            {latitude: latLng.lat, longitude: latLng.lng},
                            {latitude: listAgencies[i].GpsLatitude, longitude: listAgencies[i].GpsLongitude},
                            100000)
                    }

                    if (isAgencyArround) {
                        let distance = geolib.getDistance(
                            {latitude: latLng.lat, longitude: latLng.lng},
                            {latitude: listAgencies[i].GpsLatitude, longitude: listAgencies[i].GpsLongitude}
                        )

                        let formatAgencieDistance = {...listAgencies[i], distance}
                        agencyArround.push(formatAgencieDistance)

                        if (listAgencies[i].typeService === "Agence") {
                            listCodeAgencies = listCodeAgencies === ''
                                ? listAgencies[i].agencyCode
                                : listCodeAgencies + ';' + listAgencies[i].agencyCode
                        }
                    }
                }
				
                agencyArround.sort((a, b) => (a.distance > b.distance) ? 1 : ((b.distance > a.distance) ? -1 : 0))
                this.setState({agencies: agencyArround})
                this.props.saveCityPosition(latLng)
            })
            .catch(error => {
                console.error('ERROR IN SEARCH@HANDLE SELECT', error)
            })
    };

    pushTagGeolid() {
        IdTagGeolid.setShopId(localStorage.getItem('localStorageSetShopId'))
    }

    handleSelectAgency = (agency) => {
        this.setState({address: agency.Intitule, showAgency: false, closeDays: agency.JoursFermetures});
        this.props.saveSelectedAgency(agency)
        
        //call pushtag Geolid
        //localStorage setShopId
        const localStorageAgencyCode = agency.AgenceCode
        localStorage.setItem('localStorageSetShopId', localStorageAgencyCode)
        this.pushTagGeolid(agency)

        VehiclesOffersSearch.setAgencySchedule(this.props.agencySchedule)
        VehiclesOffersSearch.setAgencyClosingDays(agency.JoursFermetures)

        this.setState({startSelectHour: false, endSelectHour: false})
		
        if (this.props.startDateUnformated && this.props.endDateUnformated) {
            this.defineHours(agency.HorairesOuverture, 'start')
            this.defineHours(agency.HorairesOuverture, 'end')
        }

        localStorage.setItem('storeRedux', JSON.stringify(this.props.store))
        this.setDateDepart(agency);
    } 

    setDateDepart(agency){
        var today = new Date();        
        var timeAfter7days = new Date(today);
        var JoursFermetures = agency.JoursFermetures
        var HorairesOuverture = agency.HorairesOuverture
        var langDate = 'fr-Fr'
        var date = timeAfter7days.setDate(today.getDate() + this.state.numberDays);

        if (window.location.href.indexOf("/en") > -1) {
            langDate="en-Us";
        }

        var dateDepart = new Intl.DateTimeFormat(langDate, {year: 'numeric', month: 'long', day: '2-digit'}).format(date);
        var isholiday = false;

        JoursFermetures.forEach(element => {  
            var dateHolidays = new Date(Date.UTC(element.annee,element.mois-1,element.jour));
            var holidayDate = new Intl.DateTimeFormat(langDate, {year: 'numeric', month: 'long', day: '2-digit'}).format(dateHolidays);
           
            if(holidayDate == dateDepart ){
                isholiday = true;         
            }
        });

        if(today != null){
            let day = new Date(today).getDay() === 0 ? 7 : new Date(today).getDay() - 1
            
            HorairesOuverture.forEach(elemOverture => {
                if(elemOverture.hre_jour === day ){
                    let Thours = today.getHours();

                    if(elemOverture.fermeture_aprem != "None"){
                        let horaire_femAprs = elemOverture.fermeture_aprem.match(/ (.[0-9])/g)[0].match(/(..)$/g)[0]
                        
                        if(parseInt(Thours) >= parseInt(horaire_femAprs))
                        {
                            if(this.isvalid == true){
                                var holdate={jour:today.getDate(),mois:(today.getMonth()+1),annee:today.getFullYear(),tiers:"T"}
                                agency.JoursFermetures.push(holdate);
                                this.isvalid = false;
                            }   
                        }
                    } else if (elemOverture.fermeture_matin != "None") {
                        let horaire_femMatin = elemOverture.fermeture_matin.match(/ (.[0-9])/g)[0].match(/(..)$/g)[0]

                        if(parseInt(Thours) >= parseInt(horaire_femMatin))
                        {
                            if(this.isvalid == true){
                                var holdate={jour:today.getDate(),mois:(today.getMonth()+1),annee:today.getFullYear(),tiers:"T"}
                                agency.JoursFermetures.push(holdate);
                                this.isvalid = false;
                            }   
                        }
                    }
                }
            });
        }

        if(isholiday == true){
            this.setState({
                numberDays:this.state.numberDays+1
            });

            setTimeout(() => {
                this.setDateDepart(agency);
            }, 2);
        } 
        else {
            var numday = new Date(date).getDay();
            
            if(numday == 1)
            {
                numday = 0;
            }
            else if(numday == 2){
                numday = 1;
            }
            else if(numday == 3){
                numday = 2;
            }
            else if(numday == 4){
                numday = 3;
            }
            else if(numday == 5){
                numday = 4;
            }
            else if(numday == 6){
                numday = 5;
            }
            else if(numday == 0){
                numday = 6;
            }

            HorairesOuverture.forEach(elemOverture => {
                if(elemOverture.hre_jour == numday ){
                    if(elemOverture.fermeture_aprem =="None" 
                        && elemOverture.fermeture_matin =="None" 
                        && elemOverture.ouverture_aprem =="None" 
                        && elemOverture.ouverture_matin =="None" 
                        )
                    {
                        this.setState({
                            numberDays:this.state.numberDays+1
                        });
            
                        setTimeout(() => {
                            this.setDateDepart(agency);
                        }, 2);
                    }
                }
            }); 
        }
		
        setTimeout(() => {
            this.props.saveStartDate(new Date(date).toISOString())
            this.props.saveEndDate(new Date(date).toISOString())
            this.props.saveStartDateUnformated(new Date(date).toISOString())
            this.props.saveEndDateUnformated(new Date(date).toISOString())

            this.defineHoursAutomatic(HorairesOuverture, 'start', new Date(date))
            this.defineHoursAutomatic(HorairesOuverture, 'end', new Date(date))
        }, 1000);

        this.setState({
            DateDepart:dateDepart
          }, () => {
          });
    }

    defineHours = (horaire, typeDate) => {
        let tabHoraire = horaire
        let tabHour = []
        let day = new Date(this.props[typeDate + 'DateUnformated']).getDay() === 0 ? 7 : new Date(this.props[typeDate + 'DateUnformated']).getDay() - 1
        let newDay = new Date(this.props[typeDate + 'DateUnformated'])
        let today =  new Date();
        var datetoday = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
        var Newdate = newDay.getFullYear()+'-'+(newDay.getMonth()+1)+'-'+newDay.getDate();

        if(datetoday == Newdate){
            if (tabHoraire[day].ouverture_matin) {
                if (tabHoraire[day] && tabHoraire[day].ouverture_matin.match(/ (.[0-9])/g)) {
                    let ouverture_matin = tabHoraire[day].ouverture_matin.match(/ (.[0-9])/g)[0].match(/(..)$/g)[0]
                    let fermeture_matin = tabHoraire[day].fermeture_matin.match(/ (.[0-9])/g)[0].match(/(..)$/g)[0]
                    let ouverture_aprem = tabHoraire[day].ouverture_aprem.match(/ (.[0-9])/g)[0].match(/(..)$/g)[0]
                    let fermeture_aprem = tabHoraire[day].fermeture_aprem.match(/ (.[0-9])/g)[0].match(/(..)$/g)[0]
                    var itoday = today.getHours()
                    var isbetwen = false;
                    
                    itoday =  itoday < 10 ? '0' + itoday : itoday;
                    
                    if(parseInt(itoday) < parseInt(ouverture_matin)){
                        itoday = ouverture_matin;
                    }

                    if(itoday >= fermeture_matin  && itoday < ouverture_aprem ) {
                        itoday = ouverture_aprem;   
                        ouverture_matin = itoday - 1
                        tabHoraire[day].ouverture_matin.match(/ (.[0-9])/g)[0].match(/(..)$/g)[0] = ouverture_matin;
                    }
                    else if((itoday +1)>= fermeture_matin  && (itoday+1) < ouverture_aprem ){
                        itoday = ouverture_aprem;   
                        ouverture_matin = itoday - 1;
                    }
                    else {
                        ouverture_matin = itoday;
                        tabHoraire[day].ouverture_matin.match(/ (.[0-9])/g)[0].match(/(..)$/g)[0] = ouverture_matin;
                    }

                    for (let i = parseInt(itoday)  ; i < +fermeture_aprem; i++) {
                        tabHour.push(i)
                    }
    
                    this.setState({[typeDate + 'TabHour']: tabHour, [typeDate + 'SelectHour']: false})
                    
                    if (this.props.startDate && typeDate === 'start') {
                        this.props.saveSearchHour('start', +ouverture_matin + 1 < 10 ? '0' + (+ouverture_matin + 1) : +ouverture_matin + 1)
                    }
                    
                    if (this.props.endDate && typeDate === 'end') {
                        this.props.saveSearchHour('end', +fermeture_aprem - 1)
                    }
    
                    for (let i = 0; i < tabHour.length; i++) {
                        if (tabHour[i] >= fermeture_matin && tabHour[i] < ouverture_aprem) {
                            delete tabHour[i]
                        }
                        else {
                            if(itoday == tabHour[i]){
                                delete tabHour[i]
                            }
                        }
                    }
                }
            }
        }
        else{
            if (tabHoraire[day].ouverture_matin) {
                if (tabHoraire[day] && tabHoraire[day].ouverture_matin.match(/ (.[0-9])/g)) {
                    let ouverture_matin = tabHoraire[day].ouverture_matin.match(/ (.[0-9])/g)[0].match(/(..)$/g)[0]
                    let fermeture_matin = tabHoraire[day].fermeture_matin.match(/ (.[0-9])/g)[0].match(/(..)$/g)[0]
                    let ouverture_aprem = tabHoraire[day].ouverture_aprem.match(/ (.[0-9])/g)[0].match(/(..)$/g)[0]
                    let fermeture_aprem = tabHoraire[day].fermeture_aprem.match(/ (.[0-9])/g)[0].match(/(..)$/g)[0]

                    for (let i = 0; i <= +fermeture_aprem; i++) {
                        tabHour.push(i < 10 ? '0' + i : i)
                    }
    
                    tabHour = tabHour.splice(+ouverture_matin)
                    this.setState({[typeDate + 'TabHour']: tabHour, [typeDate + 'SelectHour']: false})
                    
                    if (this.props.startDate && typeDate === 'start') {
                        this.props.saveSearchHour('start', +ouverture_matin < 10 ? '0' + (+ouverture_matin) : +ouverture_matin)
                    }
                    
                    if (this.props.endDate && typeDate === 'end') {
                        this.props.saveSearchHour('end', +fermeture_aprem)
                    }
    
                    for (let i = 0; i < tabHour.length; i++) {
                        if (tabHour[i] >= fermeture_matin && tabHour[i] < ouverture_aprem) {
                            delete tabHour[i]
                        }
                    }
                }
            }
        }
    }

    defineHoursAutomatic = (horaire, typeDate, selectedDate) => {
        let tabHoraire = horaire
        let tabHour = []
        let day = selectedDate.getDay() - 1
        
        if (tabHoraire[day] != null && tabHoraire[day].ouverture_matin) {
            if (tabHoraire[day] && tabHoraire[day].ouverture_matin.match(/ (.[0-9])/g)) {
                let ouverture_matin = tabHoraire[day].ouverture_matin.match(/ (.[0-9])/g)[0].match(/(..)$/g)[0]
                let fermeture_matin = tabHoraire[day].fermeture_matin.match(/ (.[0-9])/g)[0].match(/(..)$/g)[0]
                let ouverture_aprem = tabHoraire[day].ouverture_aprem.match(/ (.[0-9])/g)[0].match(/(..)$/g)[0]
                let fermeture_aprem = tabHoraire[day].fermeture_aprem.match(/ (.[0-9])/g)[0].match(/(..)$/g)[0]

                for (let i = 0; i < +fermeture_aprem; i++) {
                    tabHour.push(i < 10 ? '0' + i : i)
                }

                tabHour = tabHour.splice(+ouverture_matin + 1)
                this.setState({[typeDate + 'TabHour']: tabHour, [typeDate + 'SelectHour']: false})
				
                if (this.props.startDate && typeDate === 'start') {
                    this.props.saveSearchHour('start', +ouverture_matin + 1 < 10 ? '0' + (+ouverture_matin + 1) : +ouverture_matin + 1)
                    
                    this.setState({
                        startHour:ouverture_matin
                      }, () => {
                      });
                }
				
                if (this.props.endDate && typeDate === 'end') {
                    this.props.saveSearchHour('end', +fermeture_aprem - 1)
                }

                for (let i = 0; i < tabHour.length; i++) {
                    if (tabHour[i] >= fermeture_matin && tabHour[i] < ouverture_aprem) {
                        delete tabHour[i]
                    }
                }
            }
        }
    }

    handleClickShow(type) {
        if (this.state.startTabHour.length > 1 || this.state.endTabHour.length) {
            this.setState({showHourPicker: type === 'start' ? 1 : 2})
        }
    }
    
    handleClickSelectHour(hour, typeHour) {
        this.setState({showHourPicker: 0, [typeHour]: hour})
        let type = typeHour === 'startHour' ? 'start' : 'end'
        let unformatedType = type + 'DateUnformated'
        let unformated = this.props[unformatedType]
        let regex = /T([0-9][0-9])/g
        this.props.saveSearchHour(type, hour)
        let day = unformated.replace(regex, 'T' + hour)
        this.props.saveUnformatedDate(type + 'Date', day);
        this.setState({[type + 'SelectHour']: true})
    }  

    isFormValid() {
        try {
            return VehiclesOffersSearch.validateSearchForm(
                this.props.selectedAgency,
                this.props.startDate,
                this.props.endDate,
            )
        } catch (e) {
            console.error('ERROR Search@isFormValid', e)
            // TODO CUSTOM VALIDATION ERROR HANDLING
        }
    }

    render() {
        const options = {
            types: [ 'geocode'],
            componentRestrictions: {country: "fr"}
        }

        return (
            <Row id="SearchRow">
                <Col md="2" id="agences">
                    <PlacesAutocomplete
                        ref={this.placesAutoCompleteRref}
                        value={this.state.address}
                        onChange={this.handleChange}
                        onSelect={this.handleSelect}
                        searchOptions={options}
                    >
                        {({getInputProps, suggestions, getSuggestionItemProps, loading}) => (

                            <div>
                                <input
                                    {...getInputProps({
                                        placeholder: this.props.t('Ville de départ'),
                                        className: `location-search-input ${RegExp('Choisir').test(this.state.address) && 'red'}`,
                                        disabled: this.props.disabled === true
                                    })}
                                />
                                <div className="autocomplete-dropdown-container">
                                    {loading && <div>Loading...</div>}
                                    {suggestions.map(suggestion => {

                                        const className = suggestion.active
                                            ? 'suggestion-item--active'
                                            : 'suggestion-item';
                                        // inline style for demonstration purpose
                                        const style = suggestion.active
                                            ? {backgroundColor: '#fafafa', cursor: 'pointer'}
                                            : {backgroundColor: '#ffffff', cursor: 'pointer'};

                                        if (suggestion.active) {
                                            geocodeByAddress(suggestion.description)
                                                .then(results => {
                                                    getLatLng(results[0])
                                                })
                                                .catch(error => {
                                                    console.error('Error', error)
                                                });
                                        }
                                        return (
                                            <div
                                                {...getSuggestionItemProps(suggestion, {
                                                    className,
                                                    style,
                                                })}
                                            >
                                                <span>{suggestion.description}</span>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        )}
                    </PlacesAutocomplete>

                    <div className={this.state.showAgency ? "showAgency show" : "showAgency"}>
                        {this.state.agencies.length > 0 ? this.state.agencies.map((val, i) => {
                            return (
                                <div onClick={this.handleSelectAgency.bind(this, val)}
                                     key={i}>{val.Intitule}, {val.Ville}</div>
                            )
                        }) : <Trans i18nKey="aucune_agence">"aucune agence ne se situe près de cette ville (rayon : 50
                            km)"</Trans>}
                    </div>
                </Col>
                <Col md="3">
                    <DayPicker closeDays={this.state.closeDays.length > 0 ? this.state.closeDays : []} DateDepart={this.state.DateDepart}
                               time="startDate" placeholder={this.props.t("Départ")} />
                    <img onClick={this.showStartDate} alt="date de début" src={logo_planning}
                         style={{position: "absolute", right: "10%", top: "7px"}}/>
                </Col>
                <Col>
                    <Input value={this.props.startHour + ':00'}
                           onClick={this.handleClickShow.bind(this, 'start')} readOnly/>
                    <ul style={{display: this.state.showHourPicker === 1 ? "block" : "none"}}
                        className="selectHour">
                        {this.state.startTabHour.map((val, i) => {
                            return (
                                <li key={i}
                                    onClick={this.handleClickSelectHour.bind(this, val, 'startHour')}>{val}:00</li>
                            )
                        })}
                    </ul>
                </Col>
                <Col md="3">
                    <DayPicker closeDays={this.state.closeDays.length > 0 ? this.state.closeDays : []}  DateDepart={this.state.DateDepart}
                               time="endDate" placeholder={this.props.t("Retour")}/>
                    <img onClick={this.showEndDate} alt="date de fin" src={logo_planning}
                         style={{position: "absolute", right: "10%", top: "7px"}}/>
                </Col>
                <Col>
                    <Input value={this.props.endHour + ':00'} onClick={this.handleClickShow.bind(this, 'end')}
                           readOnly/>
                    <ul style={{display: this.state.showHourPicker === 2 ? "block" : "none"}}
                        className="selectHour">
                        {this.state.endTabHour.map((val, i) => {
                            return (
                                <li key={i}
                                    onClick={this.handleClickSelectHour.bind(this, val, 'endHour')}>{val}:00</li>
                            )
                        })}
                    </ul>
                </Col>
                <Col md="2">
                    <Link
                        to={this.isFormValid() ?
                            (
                                this.props.i18n.language === 'fr' ?
                                    '/etapereservation' :
                                    this.props.t("url_etapereservation")
                            )
                            : "/"}>
                        <Button color="primary" size="lg" block disabled={!this.isFormValid()}>
                            <Trans i18nKey="chercher">Chercher</Trans>
                        </Button>
                    </Link>
                </Col>

            </Row>
        );
    }
}

function mapStateToProps(state) {
    return {
        selectedAgency: state.reducer.selectedAgency,
        agencySchedule: state.reducer.horaireAgency,
        startDateUnformated: state.reducer.startDateUnformated,
        endDateUnformated: state.reducer.endDateUnformated,
        startDate: state.reducer.startDate,
        endDate: state.reducer.endDate,
        startHour: state.reducer.startHour,
        endHour: state.reducer.endHour,
        store: state.reducer
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        saveSelectedAgency,
        saveStartDate,
        saveEndDate,
        saveStartDateUnformated,
        saveEndDateUnformated,
        saveCityPosition,
        saveUnformatedDate,
        saveSearchHour
    }, dispatch)
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Search));