import React, {Component} from 'react';
import {Link} from 'react-router-dom'

//REDUX
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import {saveAllTypeVehicles} from '../redux/action'
import {FaTimes} from "react-icons/fa";

//BABEL
import {babelGetAllVehicles} from '../babel/babelVehiclesService'

//IMAGE
import A from '../assets/img/license_a.png'
import A2 from '../assets/img/license_a2.png'
import A1 from '../assets/img/license_a1.png'
import AM from '../assets/img/license_am.png'
import B from '../assets/img/license_b.png'
import {Helmet} from "react-helmet";
import {getUserIP} from "../services/getIpAdress";

//TRANSLATION
import {Trans, withTranslation} from 'react-i18next';
import Header from "../staticComponents/header";

class ebikeDetails extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showImg: false,
            displayVehicle: {},
        };
    }

    componentDidMount = async () => {
        const data = await babelGetAllVehicles('SCO,MOT', this.props.lang);
        this.setState({displayVehicle: [...data.SCO, ...data.MOT].filter(x => x.reference === this.props.nom)[0]});
        getUserIP((ip) => {
            this.pushTagGtm(ip)
        })
    }

    /*----tag GTM-----*/
    pushTagGtm(ip) {
        const internal = [
            '176.149.189.93',//IP Unitec Fibre
            '82.64.74.58',//IP Unitec Guest
            '82.229.26.224',//IP Eric
            '78.237.92.155',//IP CJA
            '172.19.0.1',
        ]

        let traffic = internal.indexOf(ip) === -1 ? 'externe' : 'interne'

        /* window.dataLayer = window.dataLayer || [];*/
        window.dataLayer.push({
            'event': 'virtualpageview',
            'virtualPath': window.location.pathname.toLowerCase(),
            'page_name': this.state.displayVehicle.type + '_' + this.state.displayVehicle.name.toLowerCase().replace(/ /g, '_'),
            'page_type': 'page_produit',
            'category': this.state.displayVehicle.type,
            'sub_category_lvl1': this.state.displayVehicle.engine_cylinder_capacity.split(" ")[0],
            'langage': 'fr',
            'country': 'FR',
            'product_name': this.state.displayVehicle.name.toLowerCase().replace(/ /g, '_'),
            'traffic': traffic,
            'agency': '',
        })
    }

    showImg() {
        this.setState({showImg: !this.state.showImg})
    }

    deleteLast(type){
        return type.substring(0,type.length-1)
    }
    // conditions permis
    condper(cat,nomVehicule){
        let cp = {
          'S1': "AM,A1,A2,A,B",
          'S2': "A1,A2,A,B (2 ans d'exp) + formation 7h",
          'S3': "A1,A2,A,B (2 ans d'exp) + formation 7h",
          'S4': "A2,A",
          'S5': "A2,A",
          'M1': "A1,A2,A,B (2 ans d'exp) + formation 7h",
          'M2': "A1,A2,A,B (2 ans d'exp) + formation 7h",
          'M3': "A2,A",
          'M4': "A",
          'M5': "A",
          'M3B': "A",
          'E':"AM,A1,A2,A,B (2 ans d'exp) + formation 7h",
          'VAE1': this.props.t("Sans permis"),
          'VAE2': this.props.t("Sans permis")
        }
        if(cat=="S4" && nomVehicule.includes("Tricity")){
          return "A1,A2,A,B (2 ans d'exp) + formation 7h*"
        }else{
          return cp[cat]
        }
      }

    render() {
        const linkStyle = {borderBottom: 0}
        let displayVehicle = this.state.displayVehicle
        const tabImg = {A, A2, A1, AM, B}
        let img = ''
        let tabPermis = ["AM", "B", "B*", "A1", "A2", "A"];
        let validePermis = false;
        let count = 0;
        if (displayVehicle.licence) {
            img = tabImg[displayVehicle.licence] ? tabImg[displayVehicle.licence] : tabImg.B
        }

        return (
            <div id="vehicleDetails">
                <Helmet>
                    <meta name="description" content={this.props.t("Yamaha Rent vous permet de louer un(e) ") +displayVehicle.type+
                    ' '+displayVehicle.name+ this.props.t(' pour un jour, un week-end, une semaine. Yamaha Rent, c’est la garantie d’utiliser des motos et scooters récents et entretenus. Choisissez la location courte durée Yamaha Rent pour essayer le/la ')
                    +displayVehicle.type + ' ' +displayVehicle.name}/>
                    <title>{this.props.t('Location ') +displayVehicle.type+ this.props.t(' Yamaha ') + displayVehicle.name} - Yamaha Rent​</title>
                </Helmet>
                <div id="bigImg" style={{display: this.state.showImg ? 'block' : 'none'}}>
                    <img alt="détails du véhicule" onClick={this.showImg.bind(this)} src={displayVehicle.image}/>
                    <p className="grey alignWithBtn" style={{fontSize: 10}}>
                        <Trans i18nKey="photo">Photo non contractuelle, la version et le coloris peuvent différer en
                            fonction des
                            disponibilités en agence.</Trans>
                    </p>
                </div>

                <Link to={"/e-bikes"}>
                    <div className="arrow">
                    </div>
                    <div style={{height: 50, lineHeight: '37px'}}> <Trans i18nKey="retour_vehicule">Retour à nos </Trans>{this.props.type} </div>
                </Link>

                <div id="detailsContainer" style={{display: 'block'}}>
                    <div id="details_header">
                        <div id="details_image" style={{height: 'auto', flexBasis: 0, flexGrow: 2}}>
                            <img alt={this.props.t("location ")+ this.props.type
                            + this.props.t(' yamaha ') + displayVehicle.name}
                                 onClick={this.showImg.bind(this)}
                                 src={displayVehicle.image}/>
                            <p className="grey alignWithBtn"
                               style={{fontSize: 10}}>
                                <Trans i18nKey="photo">Photo non contractuelle, la version et le coloris peuvent
                                    différer en fonction des
                                    disponibilités en agence.</Trans>
                            </p>
                        </div>
                        <div id="details_prix" className="hidePhone">
                            <b><Trans i18nKey="a_partir">à partir de </Trans></b>{displayVehicle.fake_price},00
                            € <Trans i18nKey="jour">/ jour
                        </Trans></div>
                        <div id="details_infos" style={{flexBasis: 0, flexGrow: 1}}>
                            <h1 className="title-vehicle-name">{this.props.t('Location ')+ this.props.type}</h1>
                            <h1 id="details_infos_nom">
                                {displayVehicle.name}
                            </h1>
                            {/* <div id="details_infos_cylindree">
                            {
                                        displayVehicle.Moteur == "Électrique" ?
                                        <Trans i18nKey="équivalent">Équivalent </Trans> : null}
                                {displayVehicle.engine_cylinder_capacity}
                            </div> */}
                            <p className="grey alignWithBtn" style={{display: 'flex'}}>
                                {/* <img alt="permis" src={img}
                                     style={{marginRight: 10, height: 35, width: 32, marginTop: 5}}/>
                                <span style={{fontSize: 13}}>
                                    <Trans i18nKey="permis">Permis </Trans> */}
                                    {/* {displayVehicle.licence}  */}
                                    {
                                    // tabPermis.map((val, i) => {
                                    //     count++
                                    //     if (val === displayVehicle.licence) {
                                    //     validePermis = true;
                                    //     }

                                    //     if (validePermis) {

                                    //         return count != tabPermis.length && count != tabPermis.length -1 ? val + "," : count == tabPermis.length -1 ? val + " ou " : val
                                    //     } else {
                                    //         return null;
                                    //     }
                                    // })}
                                    // this.condper(displayVehicle.category,displayVehicle.name)
                                    }
                                    {/* <Trans
                                    i18nKey="necessaire">nécessaire</Trans> */}
                            	{/* <br/>
                                {
                                displayVehicle.category === "M3"
                                || displayVehicle.category === "M1"
                                || displayVehicle.category === "M2"
                                || displayVehicle.category === "S1"
                                || displayVehicle.category === "S2"
                                || displayVehicle.category === "S3"
                                || displayVehicle.category === "S4"
                                || displayVehicle.category === "E"
                                ? "23 " 
                                : "25 "}{" "} */}
                {/* {displayVehicle.categorie === "M3" 
                ?  <Trans i18nKey="age_mini">ans requis et 3 ans de permis</Trans> 
                : <Trans i18nKey="age_mini_2">ans requis et 2 ans de permis</Trans>} */}
                {/* <Trans i18nKey="age_mini_2">ans requis et 2 ans de permis</Trans>
    
                        		</span> */}
                            </p>
                            <div className="showPhone"><b><Trans i18nKey="a_partir">à partir
                                de </Trans></b>{displayVehicle.fake_price},00 € <Trans i18nKey="jour">/
                                jour</Trans>
                            </div>
                        </div>

                        <div id="details_bouton_fermer">
                            <Link to={"/e-bikes"
                            } style={linkStyle}>
                                <button type="button"><FaTimes/></button>
                            </Link>
                        </div>
                    </div>

                    <div id="details_caracteristiques">
                        <h2>{this.props.t("Caractéristiques ")+ this.props.type + " Yamaha " +
                        displayVehicle.name}</h2>
                        <div id="caract_bullet">
                            <ul>
                                {/* <li><Trans
                                    i18nKey="cylindree">Cylindrée </Trans>: {
                                        displayVehicle.Moteur == "Électrique" ?
                                        <Trans i18nKey="équivalent">Équivalent </Trans> : null}
                                        {displayVehicle.engine_cylinder_capacity}
                                </li> */}
                                <li><Trans i18nKey="transmission">Transmission </Trans>: {displayVehicle.transmission}
                                </li>
                                {/* <li><Trans i18nKey="Prix pack zen (TTC)">Prix pack zen (TTC)</Trans>: {displayVehicle.Prix_pack_zen}</li> */}
                            </ul>
                            <ul>
                                {
                                    displayVehicle.max_power?.trim() != 'à 0 tr/min' && displayVehicle.max_power?.trim() != 'at 0 rpm' && 
                                    <li><Trans i18nKey="puissance_max">Puissance max. </Trans>: {displayVehicle.max_power?.replace('at 0 rpm','Nm').replace('à 0 tr/min','Nm').replace('(','/ ').replace(')','')}
                                    </li>
                                } 
                                <li><Trans i18nKey="Franchise dommage">Franchise dommage</Trans>: {displayVehicle.Franchise_dommage}</li>
                                <li><Trans i18nKey="Autonomie">Autonomie</Trans>: {displayVehicle.Autonomie} <Trans i18nKey="mode_assistance">selon le mode d'assistance choisi</Trans></li>
                            </ul>
                            <ul>
                                <li><Trans i18nKey="poids">Poids total </Trans>: {displayVehicle.total_weight} {displayVehicle.Moteur == "Électrique" ? <Trans i18nKey="sans_batterie">(sans batterie)</Trans> : null}</li>
                                <li><Trans i18nKey="Franchise vol">Franchise vol</Trans>: {displayVehicle.Franchise_vol}</li>
                                <li><Trans i18nKey="Places">Places</Trans>: {displayVehicle.nbr_places}</li>

                            </ul>
                        </div>
                    </div>
                    <div id="details_descriptif">
                        <h2><Trans i18nKey="descriptif">Descriptif</Trans></h2>
                        <pre>
                            <div dangerouslySetInnerHTML={{__html: displayVehicle.descriptive}}></div> 
                            
                            <p><Trans i18nKey="optez-pour">Optez pour la </Trans><Link
                                to={'/'}
                                className="internal-linking-home"
                            ><Trans i18nKey="internal-link-back-home">
                                location courte durée de scooters / motos</Trans></Link><Trans i18nKey="avec-yam"> avec Yamaha Rent</Trans></p>
						</pre>


                    </div>
                </div>

            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        typeVehicles: state.reducer.typeVehicles,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        saveAllTypeVehicles
    }, dispatch)
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ebikeDetails));