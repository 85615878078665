import React, {Component} from 'react';

//COMPONENTS
import Header from '../staticComponents/header'
import Footer from '../staticComponents/footer'
import {getAgenciesAroundMe, getYamahaAgencies} from "../babel/babelAgenciesService";
import {Container, Row, Col} from 'reactstrap';
import {Link} from 'react-router-dom';
//IMG
import arrowOpen from '../assets/img/arrow-open-white.png'
import arrowClose from '../assets/img/arrow-close-white.png'
//STYLE
import "../assets/css/style.css";
//TRANSLATION
import {Trans, withTranslation} from 'react-i18next';
import Search from "../modules/search";

import sanitize from "../services/sanitize";
import {getUserIP} from "../services/getIpAdress";
import Loader from "react-loader-spinner";
import Helmet from "react-helmet/es/Helmet";
import AgencyDescription from "../modules/AgencyDescription";

const mobileBreakpoint = 450

class AgencyDetails extends Component {
    constructor() {
        super();
        this.state = {
            details: undefined,
            enhanced: undefined,
            showAgency: false,
            loading: true,
            tabWeek: [],
            // backToDealerLocator: false,
            // backLink: '/',
            isMobile: window.innerWidth <= mobileBreakpoint,
            /*visibleHours:true,*/
            showAgencyHours: window.innerWidth > mobileBreakpoint
        }


        this.searchRef = React.createRef();
        this.toggleAgencyHours= this.toggleAgencyHours.bind(this);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resize)
    }

    componentDidMount() {

        window.addEventListener('resize', this.resize)

        getAgenciesAroundMe(46.5593766, 2.3993725, 9999, "description").then(response => {
            const agencies = response.data.forEach(agency => {
                if (sanitize(agency.city) + '-' + agency.zip + '-' + sanitize(agency.title) === this.props.match.params.slug) {
                    getYamahaAgencies().then(sch => {
                        sch.forEach((schA) => {
                            if (schA.AgenceCode === agency.agency_code) {
                                this.setState({
                                    details: agency,
                                    enhanced: schA
                                })

                                this.autoSearch(schA)
                            }
                        })
                    })
                }
            })

            this.setState({
                loading: false
            })
        })
        // this.setBackLink()
            this.toggleButtonHours();
    }

    getHoraireAgence = (horaire) => {
        let regexHoraire = / (.[0-9])/g

        // donnees globales agences horaires
        let tabHoraire = horaire

        let horaireAgence = []

        // jours de la semaine
        let tabWeek = [<Trans i18nKey="lundi">Lundi</Trans>, <Trans i18nKey="mardi">Mardi</Trans>,
            <Trans i18nKey="mercredi">Mercredi</Trans>, <Trans i18nKey="jeudi">Jeudi</Trans>,
            <Trans i18nKey="vendredi">Vendredi</Trans>, <Trans i18nKey="samedi">Samedi</Trans>,
            <Trans i18nKey="dimanche">Dimanche</Trans>]

        for (let i = 0; tabHoraire.length > i; i++) {
            let dayHoraire

            if (tabHoraire[i].ouverture_matin) {
                if (tabHoraire[i].ouverture_matin !== "None") {
                    let o_matin = tabHoraire[i].ouverture_matin.match(regexHoraire)[0].match(/(..)$/g)
                    let f_matin = tabHoraire[i].fermeture_matin.match(regexHoraire) ? tabHoraire[i].fermeture_matin.match(regexHoraire)[0].match(/(..)$/g) + ' h ' +
                        tabHoraire[i].fermeture_matin.match(/:([0-9][0-9])/g)[0].split(':')[1] + ' ; ' : ''
                    let o_aprem = tabHoraire[i].ouverture_aprem.match(regexHoraire) ? tabHoraire[i].ouverture_aprem.match(regexHoraire)[0].match(/(..)$/g) + ' h ' +
                        tabHoraire[i].ouverture_aprem.match(/:([0-9][0-9])/g)[0].split(':')[1] + ' - ' : ''
                    let f_aprem = tabHoraire[i].fermeture_aprem.match(regexHoraire) ? tabHoraire[i].fermeture_aprem.match(regexHoraire)[0].match(/(..)$/g) : ''
                    let aprem_string = o_aprem != '' ? (o_aprem +
                            f_aprem + ' h ' + (tabHoraire[i].fermeture_aprem.match(/:([0-9][0-9])/g) ? tabHoraire[i].fermeture_aprem.match(/:([0-9][0-9])/g)[0].split(':')[1] : '')) : 'Fermé';
    
                    // horaires formates type 08h30 - 12h00 ; 14h00 - 18h30

                    dayHoraire =
                        o_matin + ' h ' + tabHoraire[i].ouverture_matin.match(/:([0-9][0-9])/g)[0].split(':')[1] + ' - ' +
                        f_matin +
                        aprem_string

                } else {
                    dayHoraire = this.props.t("Fermé")
                }
            }

            horaireAgence.push(dayHoraire)
        }

        this.setState({tabWeek, horaireAgence})
    }

    autoSearch = (agency) => {
        if (agency !== undefined) {
            this.searchRef.handleSelectAgency(agency)
            this.getHoraireAgence(agency.HorairesOuverture)
        }
    }

    // setBackLink = () => {
    //     let lang = this.props.i18n.language || 'fr'
    //
    //     let link = lang === 'fr' ? '/' : '/' + lang + '#'
    //     if (this.props.location.state && this.props.location.state.fromDealerLocator) {
    //         link = '/#' + (lang === 'fr' ? 'nos-agences' : 'our-agencies')
    //         this.setState({
    //             backToDealerLocator: true,
    //             backLink: link
    //         })
    //     }
    //
    //     return link
    // }

    toggleButtonHours() {
            this.setState({
                isMobile: window.innerWidth <= mobileBreakpoint,
                showAgencyHours:this.state.showAgencyHours,
            })
    }

    resize = () => {
        this.toggleButtonHours();
        if(!this.state.isMobile) {
            this.setState({
                showAgencyHours: true
            })
        }
        this.forceUpdate()
    }

    // toggleBack = () => {
    //     // console.log(this.state.backToDealerLocator)
    //     if (!this.state.backToDealerLocator) {
    //         window.scrollTo(0, 0)
    //     }
    // }

    toggleAgencyHours(){
        const {showAgencyHours} = this.state;
        this.setState({
            showAgencyHours: !showAgencyHours,
        })
    }

    /*----tag GTM-----*/
    pushTagGtm(ip) {
        const getLocalStorageSetShopId = localStorage.getItem('localStorageSetShopId')
        const internal = [
            '176.149.189.93',//IP Unitec Fibre
            '82.64.74.58',//IP Unitec Guest
            '82.229.26.224',//IP Eric
            '78.237.92.155',//IP CJA
            '172.19.0.1',
        ]
        let traffic = internal.indexOf(ip) === -1 ? 'externe' : 'interne'

        /* window.dataLayer = window.dataLayer || [];*/
        window.dataLayer.push({
            'event': 'virtualpageview',
            'virtualPath': window.location.pathname,
            'page_name': 'nos_agences_' + this.state.details.city.toLowerCase().replace(/ /g, '_'),
            'page_type': 'agences',
            'category': '',
            'sub_category_lvl1': '',
            'langage': 'fr',
            'country': 'FR',
            'product_name': '',
            'traffic': traffic,
            'agency': getLocalStorageSetShopId,
        })
    }

    render() {
        window.gt('setAppId', localStorage.getItem('localStorageSetShopId'));
        const switchIconAgencyHours = this.state.showAgencyHours ?
          <img className="toggle-icon-hours" src={arrowClose} alt="arrow-close"/>
          :<img className="toggle-icon-hours" src={arrowOpen} alt="arrow-open"/>;

        const {showAgencyHours}=this.state;
        const loaderStyle = {
            position: 'fixed',
            width: '50px',
            height: 'auto',
            top: '200px',
            left: 0,
            right: 0,
            margin: 'auto',
            zIndex: 2
        }

        getUserIP((ip) => {
            this.pushTagGtm(ip)
        })

        let lang = this.props.i18n.language || 'fr'

        if (this.state.loading) {
            return (
                <div style={loaderStyle}><Loader
                    type="Oval"
                    color="#E71D1F"
                    height="80"
                    width="80"
                /></div>
            );
        }

        if (this.state.details === undefined) {
            return ''; // ça pétouille ! :O
            // return 'L\'agence demandée est indisponible';
        }
        
        const agencyTlf = this.state.details.phone;
        const switchHref = this.state.isMobile? "tel:"+ this.state.details.phone : null;
        const labelTlf = !this.state.isMobile? this.props.t("Tél.") : this.props.t("Appeler");

        return (
            <div className="container-agency-details">
                <Header path={this.props.match}/>
                <Helmet>
                <meta name="description" content={this.props.t('Votre concession Yamaha Rent ')+this.state.details.city + this.props.t(' vous propose la location de toute la gamme récente de scooters et motos.')}/>
                <meta name="title" content={this.props.t('Location moto et scooter ') + this.state.details.city + " - Yamaha Rent"}/>
                    <title >{this.props.t('Location moto et scooter ') + this.state.details.city + " - Yamaha Rent"}</title>
                </Helmet>
                <Container style={{height: 220, backgroundPosition: '50% 57%'}} id="searchBg"/>
                <Row className="our-agency">

                    <div className="container-body-agency">
                    <nav aria-label="breadcrumb" class="mt-4">
                    <ol class="breadcrumb">
                    <li class="breadcrumb-item"> <Link to='/'><Trans
                            i18nKey="Accueil">Accueil</Trans></Link></li>
                     <li class="breadcrumb-item"> <Link to='/agences-de-location'><Trans
                            i18nKey="Nos-agences">Nos
                            agences</Trans></Link></li>        
                        <li class="breadcrumb-item active" aria-current="page"><Trans
                        i18nKey="votre-agence-delocation">Concession Yamaha</Trans> {this.state.details.city + " - Yamaha Rent"}</li>
                    </ol>
                    </nav>
                        <h1 className="title-details-agency"><Trans
                        i18nKey="votre-agence-delocation">Concession Yamaha</Trans> : <Trans i18nKey="Location moto et scooter">Location moto et scooter</Trans> {this.state.details.city}</h1>
                        <p class="mb-0">
                        <Trans
                        i18nKey="votre-agence-concession">Votre concession</Trans> {this.props.t('Yamaha Rent ')+ "\"" + this.state.details.title + "\"" } <Trans
                        i18nKey="desc-agence-concession">vous propose la location de toute la gamme récente de scooters et motos.</Trans>
                        </p>
                        <Row className="row-details-agency">
                            <Col>
                                <div className="contact-information-agency">
                                    <h2 className="title-city-agency"
                                        style={{'marginTop': '30px'}}>{this.state.details.title}</h2>
                                    <p>{this.state.details.address} <br/>
                                        {this.state.details.zip} {this.state.details.city} </p>
                                    <p>
                                        <a href={switchHref} className="cta-agency-mobile"><Trans>{labelTlf} </Trans><span
                                          className="phone-our-agency" hidden={this.state.isMobile}>{agencyTlf}</span></a>
                                    </p>
                                </div>
                                <div>
                                    <button className="hours-of-agency" disabled={!this.state.isMobile} onClick={this.toggleAgencyHours}>
                                        <Trans>Horaires d'ouverture</Trans><label className="toggle-icon-hours" hidden={!this.state.isMobile}>{switchIconAgencyHours}</label>
                                    </button>
                                    {showAgencyHours && ( <div className="container-hours">

                                        {this.state.tabWeek.map((val, i) => {
                                            const format = 'string' === typeof this.state.horaireAgence[i] ? this.state.horaireAgence[i].toString().replace(' ; ', '<br/>').replace(/\s/g, '&nbsp;') : this.state.horaireAgence[i].props.children
                                            return (
                                                <div className="agency-day-hour" key={i} style={{marginTop: 0}}>
                                                    <div className="day-agency-hour">{val}</div>
                                                    <div className="agency-hours"
                                                         dangerouslySetInnerHTML={{__html: format}}/>
                                                </div>
                                            )
                                        })}
                                    </div>
                                    )}
                                </div>
                                <h2 className="agency-page-title-rent-scoot"><Trans i18nKey="reservez-votre-moto">Réservez
                                    votre moto ou scooter Yamaha à </Trans>{this.state.details.city}</h2>
                                <div id="bg-search-agency-details">
                                    {/*duplique search*/}
                                    <Search disabled={true} onAutoSearch={ref => (this.searchRef = ref)}
                                            ref={this.autoSearch()}
                                    />
                                </div>
                                <AgencyDescription
                                    details={this.state.details}
                                />
                            </Col>
                        </Row>
                        <Link to={lang === 'fr' ? 'agences-de-location' : 'rental-agencies'}
                              className="cta-back-home-agencydetails"
                        >
                            {/*<Link to={*/}
                            {/*    this.state.backLink*/}
                            {/*} className="cta-back-home-agencydetails" onClick={this.toggleBack}>*/}
                            <Trans>Retour Nos agences</Trans>
                        </Link>
                    </div>

                </Row>
                <Footer/>
            </div>
        );
    }
}

export default withTranslation()(AgencyDetails);
