// ENVIRONMENT SETTINGS
// export const ENVIRONMENT = "PROD"; 
export const ENVIRONMENT = "PREPROD";


/* -------------------------- url & params preprod -------------------------- */

// PREPROD Paybox
export const PAYBOX_URL_PP = "https://preprod-tpeweb.paybox.com/cgi/MYchoix_pagepaiement.cgi";
export const PAYBOX_SITE_PP = "1999888";
export const PAYBOX_RANG_PP = "32";
export const PAYBOX_IDENTIFIANT_PP = "107904482";
export const PAYBOX_KEY_PP = "0123456789ABCDEF0123456789ABCDEF0123456789ABCDEF0123456789ABCDEF0123456789ABCDEF0123456789ABCDEF0123456789ABCDEF0123456789ABCDEF";

// PREPROD Adyen 
// export const UrlAdyen_PP = "https://localhost:7091";
export const UrlAdyen_PP = "https://ucar-paiement.dsiconseil.net";
export const Adyen_environment_PP = "test";
export const Adyen_clientKey_PP = "test_AUPWJXO6IZD4HKRJN57K3XNOPM3CSU5L";

// PROD Adyen 
export const UrlAdyen = "https://wsucar.ucarnet.net/API_ADYEN";
export const Adyen_environment = "live";
export const Adyen_clientKey = "live_IWPYIIH2FZGTVKESNXSUNZ63XU3HMQMH";

//PREPROD PACK_CADEAU
export const SERVER_ADDRESS_PACK_CADEAU_PP = "https://yamaha.dsiconseil.net/WS/Utilities.ashx?Type=";
export const SERVER_ADDRESS_TRANSACTIONS_PP = "https://ppboymh.ucarent.com/v1/transactions";
export const SERVER_ASSETS_PP = "https://yamaha.dsiconseil.net/assets";
// export const SERVER_ADDRESS_TRANSACTIONS_PP = "http://localhost:8000/v1/transactions";

//PREPROD Babel Azure
export const SERVER_ADDRESS_80_PP = "https://ppbabel.ucarent.com/";
// export const SERVER_ADDRESS_80_PP = "http://localhost:9090/";
export const BABEL_V2_URL_PP = "https://ppboymh.ucarent.com";
// export const BABEL_V2_URL_PP = "http://localhost:8000";
export const BABEL_V2_URL_VERSION_PP = "v1";

// PREPROD Babel Ucar
// export const SERVER_ADDRESS_80 = "https://babelv1.ulabs.fr/";
// export const BABEL_V2_URL = "https://yamahav2back.ulabs.fr";
// export const BABEL_V2_URL_VERSION = "2.0";


/* ------------------------ fin url & params preprod ------------------------ */

//Local PACK_CADEAU
// export const SERVER_ADDRESS_PACK_CADEAU = "https://yamaha.dsiconseil.net/WS/Utilities.ashx?Type=";

/* ---------------------------- url & params prod --------------------------- */

// PROD Paybox
export const PAYBOX_URL = "https://tpeweb.paybox.com/cgi/MYchoix_pagepaiement.cgi";
export const PAYBOX_SITE = "2910189";
export const PAYBOX_RANG = "01";
export const PAYBOX_IDENTIFIANT = "433273414";
export const PAYBOX_KEY = "C8D9B16C7B328CABE06D2D4D57457EBAB748295CA3C20BEEAEADB208AA33CE779C8480F623DE44CE2FA7CB2DE54D3E90E5D3E4BEE1BBCDC019F56CA226C11DE2";

//PROD PACK_CADEAU
export const SERVER_ADDRESS_PACK_CADEAU = "https://rent.yamaha-motor.eu/WS/Utilities.ashx?Type=";
export const SERVER_ADDRESS_TRANSACTIONS = "https://boymhv2.ucarent.com/v1/transactions";
export const SERVER_ASSETS = "https://rent.yamaha-motor.eu/assets/";

// PROD Babel Azure
export const SERVER_ADDRESS_80 = "https://babel.ucarent.com/";
export const BABEL_V2_URL = "https://boymhv2.ucarent.com";
export const BABEL_V2_URL_VERSION = "v1";


/* -------------------------- fin url & params prod ------------------------- */

/* ------------------------------- url params ------------------------------- */

//AGENCE YAMAHA
export const GET_HORAIRE_AGENCY = "search/get_open_schedule_agency";

//TODO: get agency and horaire at once
export const GET_YAMAHA_AGENCIES =
  "search/NEW_get_agencies_infos?enseigne_code=14";

//DEALER LOCATOR
export const GET_AGENCIES_AROUND_ME = "/v1/agencies";

//NEW YAM VEHICLE
export const GET_ALL_PRICES_DATE = "search/get_all_prices_date_v3"; //OLD, DELETE
export const GET_ALL_PRICES_DATE_V2 = "/v1/agency/price";
export const GET_DETAIL_PRESATION = "search/get_detailed_prestations";
export const GET_MODELE = "v1/vehicles";
export const GET_VEHICLES_TYPE_INFO = "search/get_type_vehicule_info";
export const GET_ALL_VEHICLES = "v1/vehicles/properties";

/* ----------------------------- fin url params ----------------------------- */


/* ------------------------------- Paramétres ------------------------------- */
//GESTION REMISE
export const test_remise = 1;
export const remise = 15;
export const agence_remise = "YA01075FR";
export const cat_remise = ["S3", "S1", "S4", "M2", "M3", "M3B", "M5"];
export const date_fin_remise = "2021-07-14";

export const localStorageAgencyCode = "france";

/*export const REACT_APP_MAPBOX_ACCESS_TOKEN = "pk.eyJ1IjoidWNhcnVsYWJzIiwiYSI6ImNqbHo3aHRwYjFzaTUzcXF2YTQ0aWh2ZzkifQ.FQOHZapMCgEvme-ilwv9ew"*/

//MAIL
export const SEND_MAIL = "yam_send_mail";

//Agences sans horaires
export const agenciesWithoutSchedule = ["LE CASTELLET"]

//Agence sans recherche moto
export const agenciesWithoutSearch = ["LE CASTELLET"]

// paybox 3ds v2 default parameters
export const PBX_INFO = {
  PBX_ADDRESS: "10, rue Louis Pasteur",
  PBX_CITY: "Boulogne Billancourt",
  PBX_ZIP: "92774",
  PBX_COUNTRY: "250"
}

/* ----------------------------- fin paramétres ----------------------------- */