import React, { Component } from 'react'

// REDUX
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { changeSectionReservation, saveSelectedVehicle, saveAddedKm, storeToUpdate } from '../redux/action'
import i18n from "../i18n";
import {withRouter} from 'react-router-dom';
import {Trans, withTranslation} from "react-i18next";

class PaymentAccepted extends Component {
    constructor(props) {
        super(props);
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const code = urlParams.get('code');
        
        if(code == '00000'){
            let storeRedux = JSON.parse(localStorage.getItem('storeRedux'))
            storeRedux.sectionReservation = 6
            localStorage.setItem('storeRedux', JSON.stringify(storeRedux))

            setTimeout(() => {
                let url = '/etapereservation'
                switch(this.props.match.params.lang) {
                    case 'en':
                        url = '/en/stepreservation'
                        break;

                    case 'fr':
                    default:
                }

                document.location.href = url
            }, 1000)
        }
        else{
            this.props.history.push('/')
        }
    }

    render() {
        let trad = 'Vous allez être redirigé(e), merci de patienter...'
        switch(this.props.match.params.lang) {
            case 'en':
                trad = 'You are going to be redirected, please wait...'
                break;

            case 'fr':
            default:
        }

        return (
            <div>{trad}</div>
        )
    }
}

function mapStateToProps(state) {
    return {
        store: state.reducer,
        sectionReservation: state.reducer.sectionReservation,
        selectedAgency: state.reducer.selectedAgency,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        changeSectionReservation,
        saveSelectedVehicle,
        saveAddedKm,
        storeToUpdate
    }, dispatch)
}

const exportComponent = withRouter(props => <PaymentAccepted {...props}/>);
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(exportComponent));

