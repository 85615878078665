import React, { Component } from "react";
import { Link } from "react-router-dom";

// REDUX
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { saveAllTypeVehicles } from "../redux/action";

//BABEL
import { babelGetAllVehicles } from "../babel/babelVehiclesService";
import { Helmet } from "react-helmet";
import { getUserIP } from "../services/getIpAdress";

//TRAD
import { Trans, withTranslation } from "react-i18next";
import { localStorageAgencyCode } from "../configuration";

class EbikePresentation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tabVehicle: [],
    };
  }

  componentDidMount = () => {
    localStorage.setItem("localStorageSetShopId", localStorageAgencyCode);

    babelGetAllVehicles("SCO,MOT").then((data) => {
      data["MOT"].forEach((element) => {
        //console.log(element.name);
      });

      data["SCO"].forEach((element) => {
        //console.log(element.name);
      });

      this.setState({
        allVehicle: data,
        tabVehicle: data.SCO.sort(this.compare),
      });
    });
  };

  componentDidUpdate(
    prevProps: Readonly<P>,
    prevState: Readonly<S>,
    prevContext: any
  ): void {
    getUserIP((ip) => {
      this.pushTagGtm(ip);
    });
  }

  compare = (a, b) => {
    if (a.category < b.category) return -1;
    if (a.category > b.category) return 1;

    return 0;
  };

  /*----tag GTM-----*/
  pushTagGtm(ip) {
    const internal = [
      "176.149.189.93", //IP Unitec Fibre
      "82.64.74.58", //IP Unitec Guest
      "82.229.26.224", //IP Eric
      "78.237.92.155", //IP CJA
      "172.19.0.1",
    ];

    let traffic = internal.indexOf(ip) === -1 ? "externe" : "interne";

    window.dataLayer.push({
      event: "virtualpageview",
      virtualPath: window.location.pathname,
      page_name: "nos_" + this.props.type,
      page_type: "liste_produit",
      category: this.props.type,
      sub_category_lvl1: "",
      langage: "fr",
      country: "FR",
      product_name: "",
      traffic: traffic,
      agency: "",
    });
  }

  render() {
    /*---------Tag SEO-----------*/
    let metaDescription = "";
    let Description1 = "";
    let Description2 = "";
    let title = "";

    metaDescription = this.props.t(
        "Louez un scooter 2 ou 3 roues directement dans votre agence Yamaha Rent. Découvrez les gammes de scooters Yamaha TMax, XMax, NMax."
      );
      Description1 = (
        <Trans i18nKey="scooters_desc1">
           Louez un vélo électrique Yamaha pour une courte durée : une journée, un week-end, une semaine ou plus... via la réservation en ligne 100% sécurisée ou directement dans votre agence Yamaha Rent. Découvrez les E-Bikes Yamaha à la location et choisissez un modèle adapté à votre besoin : Le BOOSTER pour le fun, le CROSSCORE pour sa polyvalence, le WABASH ou le MORO pour la performance… Évadez-vous avec YAMAHA RENT et découvrez notre nouvelle gamme de vélos à assistance électrique.
        </Trans>
      );
      title = this.props.t(
        "Location scooters Yamaha – Location 2 et 3 roues – Yamaha Rent"
      );
    

    let typeVehicles = "SCO";
    let tabToShow = this.state.allVehicle
      ? this.state.allVehicle[typeVehicles].sort(this.compare)
      : [];
    let tabToShow_e = tabToShow.filter(
      (data) => ["VAE1","VAE2"].indexOf(data.category) !== -1
      );

    return (
      <div className="vehiclePresentationContainer">
        <Helmet>
          <meta name="description" content={metaDescription} />
          <meta name="title" content={title} />
          <title>{title}</title>
        </Helmet>
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              {" "}
              <Link to="/">
                <Trans i18nKey="Accueil">Accueil</Trans>
              </Link>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              <Trans i18nKey="location_ebike">Location de vélo électrique Yamaha </Trans>
            </li>
          </ol>
        </nav>
        <h1>
          {" "}
          <Trans i18nKey="location_ebike">Location de vélo électrique Yamaha</Trans>
        </h1>
        <p>{Description1}</p>
        {tabToShow_e.length > 0 ? (
          <h2 class="mb-1">
            <Trans i18nKey="location_ebike_desc">Location vélo électrique urban et sport</Trans>
          </h2>
        ) : (
          ""
        )}
        {tabToShow_e.length > 0 ? (
          <div id="PresentationContent_50">
            {tabToShow_e.map((val, i) => {
              return (
                <div key={i} className="vehiclePresentation">
                  <Link
                    to={{
                      pathname:"/e-bike" +
                        "/" +
                        val.reference,
                    }}
                  >
                    <div className="imagePresentation">
                      <img alt="véhicule" src={val.image} />
                      <p className="grey alignWithBtn" style={{ fontSize: 10 }}>
                        <Trans i18nKey="photo">
                          Photo non contractuelle, la version et le coloris
                          peuvent différer en fonction des disponibilités en
                          agence.
                        </Trans>
                      </p>
                    </div>
                    <div className="nomPresentation">{val.name}</div>
                    {/* <div className="cylindreePresentation">
                    {val.Moteur == "Électrique" ?
                 <Trans i18nKey="équivalent">Équivalent </Trans>
                 : null
                  }
                 {val.Moteur == "Électrique" ?
                 val.engine_cylinder_capacity
                  :
                  val.engine_cylinder_capacity
                } 
                    </div> */}

                    <div className="prixPresentation">
                                     <b>
                                       <Trans i18nKey="a_partir">à partir de</Trans>
                                     </b>{" "}
                                     {val.fake_price},00 €{" "}
                                     <Trans i18nKey="jour">/ jour</Trans>
                                   </div>

                    
                  </Link>
                </div>
              );
            })}
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    typeVehicles: state.reducer.typeVehicles,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      saveAllTypeVehicles,
    },
    dispatch
  );
}

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(EbikePresentation)
);
