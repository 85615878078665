import React, {Component} from 'react';
import Link from "react-router-dom/Link";

import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';

import geolib from 'geolib';

//STYLE
import {Col, Container, Row} from "reactstrap";
import "../assets/css/style.css";
import {Trans, withTranslation} from "react-i18next";
/*import 'https://maps.googleapis.com/maps/api/js?key=AIzaSyCcqFWWAqMDIRSYj-Yejr1OZYccO45xF8A&libraries=places';*/

//FUNCTION
import {getYamahaAgencies, getAgenciesAroundMe} from '../babel/babelAgenciesService';

//MAPBOX
import ReactMapboxGl, {Marker, Popup, ZoomControl} from 'react-mapbox-gl';
import sanitize from "../services/sanitize";

//IMG
import nextArrow from "../assets/img/next-arrow-white.gif"
import Header from "../staticComponents/header";
import Helmet from "react-helmet/es/Helmet";
import { localStorageAgencyCode } from '../configuration';

const Map = ReactMapboxGl({
    container: 'map',
    minZoom: 0,
    maxZoom: 90,
    accessToken:
        'pk.eyJ1IjoidWNhcnVsYWJzIiwiYSI6ImNrNHd4c2hrZjBvNW4zanAyODNuOGdnY2IifQ.uiNLQsj4UBQqh6UhQMMonw'
});

const mobileBreakpoint = 450

class SearchDealerLocator extends Component {
    constructor(props) {
        super(props);

        this.state = {
            address: '',
            showCity: false,
            allAgenciesList: [],
            listAgenciesAroundMe: [],
            showAllAgenciesBtn: false,
            center: [],
            coordinates: {
                latitude: 46.5593766,
                longitude: 2.3993725
            },
            showPopupId: undefined,
            activeAgency: undefined,
            isActiveAgency:false,
            zoom: [window.innerWidth < 768 ? 4 : 5],
            isMobile: window.innerWidth <= mobileBreakpoint,
        }

        this.autocomplete = null;
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resize)
    }

    componentDidMount() {
        localStorage.setItem('localStorageSetShopId', localStorageAgencyCode)
        window.addEventListener('resize', this.resize)

        try {
            if (1 == 2)
            {
                this.setState({address : "Nice"})
                this.geocode("Nice")
            }
            else
            {
                this.retrieveAllAgencies()
            }

            if(/agencies|agences/i.test(document.URL)) {
                document.getElementById('home-dealer-locator').scrollIntoView();
            }
        } catch (e) {
            console.log('error')
        }
    }

    resize = () => {
        this.forceUpdate()
    }

    showAllAgencies() {
        this.setState({
            showAllAgenciesBtn: false
        })

        this.retrieveAllAgencies()
    }

    retrieveAllAgencies = () => {
        getAgenciesAroundMe(46.5593766, 2.3993725, 9999).then(data => {
            const allAgenciesAroundMe = JSON.stringify(data)
            const parseAllAgenciesAroundMe = JSON.parse(allAgenciesAroundMe)
            /*localStorage.setItem('agenciesAroundMe', JSON.stringify(data))*/
            this.setState({listAgenciesAroundMe: parseAllAgenciesAroundMe.data, zoom: [window.innerWidth < 768 ? 4 : 5]})
        })
    }

    handleChange = address => {
        this.setState({
            address,
        });
    }

    handleSelect = (address, disableGeocode = false) => {
        this.setState({address: address})

        if(!disableGeocode) {
            this.geocode(address)
        }
    }

    handleSelectAndSearch = (address) => {
        this.setState({
            address
        })

        this.geocode(address)
    }

    geocode = (address) => {
        geocodeByAddress(address)
            .then(results => getLatLng(results[0]))
            .then(coordinates => {
                getAgenciesAroundMe(coordinates.lat, coordinates.lng, 200).then(response => {
                    this.setState({
                        listAgenciesAroundMe: response.data.sort((a, b) => (a.distance - b.distance)),
                        showAllAgenciesBtn: true
                    })
                })
            })
            .catch(error => console.error('Error', error));
    }

    dispatchHandleClickShowPopup(i){
        if (this.state.isActiveAgency === false || (this.state.activeAgency !== undefined && this.state.activeAgency !== i)){
            this.handleClickShowPopup(i);
        }
        else{
            this.closeButtonMapboxPopup(i)
        }
    }

    scrollSelectedAgency(i){
        const agencyCode = this.state.listAgenciesAroundMe[i].agency_code
        document.getElementById('select-agency-'+ agencyCode).scrollIntoView({block:'center', behavior:this.state.isMobile? "smooth":"auto",inline: "nearest"});
    }

    handleClickShowPopup(i) {
        this.handleSelect(this.state.listAgenciesAroundMe[i].city, true)

        this.setState({
            isActiveAgency:true,
            showPopupId: i,
            activeAgency: i,
            zoom: [window.innerWidth < 768 ? 9 : 15],
            coordinates: {
                latitude: this.state.listAgenciesAroundMe[i].latitude,
                longitude: this.state.listAgenciesAroundMe[i].longitude
            },
        })

        this.scrollSelectedAgency(i)
    }

    closeButtonMapboxPopup(i) {
        this.setState({
            address: '',
            showCity: false,
            allAgenciesList: [],
            listAgenciesAroundMe: [],
            showAllAgenciesBtn: false,
            center: [],
            coordinates: {
                latitude: 46.5593766,
                longitude: 2.3993725
            },
            showPopupId: undefined,
            activeAgency: undefined,
            isActiveAgency:false,
            zoom: [4]
        })

        this.retrieveAllAgencies()
    }

    handleClickActiveAgency(i) {
        this.handleClickShowPopup(i)
    }

    render() {
        const searchOptions = {
            types: ['geocode'],
            componentRestrictions: {country: "fr"}
        }

        let isMobile = window.innerWidth < 368

        return (
            <Container id="home-dealer-locator">
                {/*<Header path={this.props.match}/>*/}
                <Helmet>
                <meta name="description"
                          content={this.props.t("Trouvez l’agence de location de motos et scooters Yamaha proche de chez vous. Votre concession Yamaha propose les motos et scooters les plus récents.")}/>
                <meta name="title"
                          content={this.props.t("Concessions Yamaha - Agences Location Motos – Yamaha Rent")}/>
                    <title>{this.props.t("Concessions Yamaha - Agences Location Motos – Yamaha Rent")}​</title>
                </Helmet>
                <h1 className="dealer_locator_title" id={this.props.t("nos-agences")}><Trans i18nKey="retrouvez">Trouvez le concessionnaire Yamaha Rent proche de chez vous</Trans></h1>
                <h2 className="slogan-dealer-locator"><Trans i18nKey="slogan-dealerlocator">Trouvez l’agence de location de motos et scooters Yamaha la plus proche de chez vous. Votre concession Yamaha Rent vous propose toute la gamme récente de scooters et motos en location courte durée.</Trans></h2>
                <div className="input-search-container">
                    <PlacesAutocomplete
                        value={this.state.address}
                        onChange={this.handleChange}
                        onSelect={this.handleSelectAndSearch}
                        searchOptions={searchOptions}
                        id="find-agency"
                    >
                        {({getInputProps, suggestions, getSuggestionItemProps, loading}) => (
                            <div>
                                <input className="placeholder-input-dealerlocator" {...getInputProps({
                                    placeholder: this.props.t("Ville"),
                                    className: "citySearch",
                                })}
                                />
                                <div className="autocomplete-dropdown-container">
                                    {loading && <div>Loading...</div>}
                                    {suggestions.map(suggestion => {

                                        const className = suggestion.active
                                            ? 'suggestion-item--active'
                                            : 'suggestion-item';
                                        // inline style for demonstration purpose
                                        const style = suggestion.active
                                            ? {backgroundColor: '#fafafa', cursor: 'pointer'}
                                            : {backgroundColor: '#ffffff', cursor: 'pointer'};

                                        if (suggestion.active) {
                                            geocodeByAddress(suggestion.description)
                                                .then(results => {
                                                    getLatLng(results[0])
                                                })
                                                .catch(error => {
                                                    console.error('Error', error)
                                                });
                                        }

                                        return (
                                            <div
                                                {...getSuggestionItemProps(suggestion, {
                                                    className,
                                                    style,
                                                })}
                                            >
                                                <span>{suggestion.description}</span>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        )}
                    </PlacesAutocomplete>
                    <button
                        type="submit"
                        className="searchButton"
                    >
                    </button>
                </div>
                <Row id="container-dealer-locator">
                    <Col className="home-agency" md="6" xs="12">
                        <h3 className="title-our-agencies"><Trans i18nKey="les_agences_yamaha">Les agences de location
                            Yamaha Rent</Trans></h3> 

                        {this.state.showAllAgenciesBtn &&
                        <button className="cta-agency-website" id="allagencies"
                                onClick={this.showAllAgencies.bind(this)}>
                            <Trans>Toutes les agences</Trans>
                        </button>}

                        {this.state.listAgenciesAroundMe.length > 0 ? this.state.listAgenciesAroundMe.map((val, i) => {
                            return (
                                <div key={'agency-' + val.agency_code}
                                     className={'agency-area ' + (this.state.activeAgency !== undefined && this.state.activeAgency === i ? 'active' : '')}
                                     id={'select-agency-' + val.agency_code}
                                     onClick={() => this.dispatchHandleClickShowPopup(i)}>
                                    <div className="agencies-list">
                                        <h2 className="title-citys-agencies"
                                            style={{'marginTop': '30px'}}>{val.city}</h2>
                                        <strong className="agencies-list-name">{val.title}</strong><br/>
                                        <p>{val.address} <br/>
                                            {val.zip} {val.city}
                                            <span className="phone-number-home-responsive">{val.phone}</span>
                                        </p>
                                    </div>
                                    <div className="phone-and-cta-area">
                                        <p className="phone-number-home">{val.phone}</p>
                                        <div className="p-phone-number-home"><a href={"tel:" + val.phone}
                                                                                className="mobilefirst-phone-number-home"><Trans
                                            i18nKey="cta_appeler">Appeler</Trans></a>

                                            <div>
                                                <Link
                                                    to={{
                                                        pathname: "/" + this.props.t('nos-agences') + "/" + sanitize(val.city) + '-' + val.zip + '-' + sanitize(val.title),
                                                        state: {
                                                            fromDealerLocator: true
                                                        }
                                                    }}
                                                    className="cta-agency-website">
                                                    <img className="arrow-cta-site-agence" src={nextArrow}
                                                         alt="next-arrow"/><Trans i18nKey="site_agence"> Site
                                                    agence</Trans>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        }) : 'Aucune agence trouvée'}
                    </Col>

                    <Col className="mapContainer" md="6" xs="12">
                        <Map
                            container='map'
                            style="mapbox://styles/mapbox/streets-v11?optimize=true"
                            containerStyle={{
                                height: '100%',
                                width: '100%',
                            }}
                            center={[this.state.coordinates.longitude, this.state.coordinates.latitude]}
                            zoom={this.state.zoom}
                            renderChildrenInPortal={true}
                        >

                            <ZoomControl
                                position="top-left"
                                className="zoom-control-mapbox"
                            />

                            {this.state.listAgenciesAroundMe.length > 0 ? this.state.listAgenciesAroundMe.map((val, i) => {
                                return (
                                    <div key={'around-' + val.agency_code}>
                                        {val.agency_code == "YA02083FR" ?
                                        <button>
                                            <Marker
                                                coordinates={[val.longitude, val.latitude]}
                                                anchor="bottom"
                                                onClick={() => this.handleClickShowPopup(i)}
                                            >
                                                <div className="mapMarkerStyle mapMarkerStyleCustom"/>
                                            </Marker>
                                        </button>
                                        :
                                        <button>
                                            <Marker
                                                coordinates={[val.longitude, val.latitude]}
                                                anchor="bottom"
                                                onClick={() => this.handleClickShowPopup(i)}
                                            >
                                                <div className="mapMarkerStyle"/>
                                            </Marker>
                                        </button>
                                        }
                                        {this.state.showPopupId === i && <Popup
                                            coordinates={[val.longitude, val.latitude]}
                                            anchor={isMobile ? "top" : "bottom-right"}
                                        >
                                            <div className="popup-mapbox">
                                                <button className="mapboxgl-popup-close-button"
                                                        onClick={() => this.closeButtonMapboxPopup(i)}>x
                                                </button>
                                                <label className="title-mapbox-popup">{val.title}</label><br/>
                                                {val.address}<br/>
                                                {val.zip} {val.city}
                                                <br/>
                                                {val.agency_code === "YA02083FR" ?
                                                <p className='descAgence'>Voyagez au guidon de votre Yamaha Avec Trail Rando, découvrez des aventures hors du commun pour tous les niveaux</p>
                                                :
                                                ""
                                                }
                                            </div>
                                        </Popup>}
                                    </div>
                                )
                            }) : ""}

                        </Map>
                    </Col>
                </Row>
            </Container>
        )
    }
}

export default withTranslation()(SearchDealerLocator);