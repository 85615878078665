export function saveUnformatedDate(typeDate, date){
    return{
        type: 'SAVE_UNFORMATED_DATE',
        typeDate, 
        date,
    }
}

export function saveChangeMonth(newMonth){
    return{
        type: 'CHANGE_MONTH',
        newMonth,
    }
}

export function saveSearchDate(typeDate, date){
    return{
        type: 'SAVE_SEARCH_DATE',
        typeDate, 
        date,
    }
}

export function saveSearchHour(typeHour, hour){
    return{
        type: 'SAVE_SEARCH_HOUR',
        typeHour,
        hour,
    }
}

export function saveCityPosition(latLng){
    return{
        type: 'SAVE_CITY_POSITION',
        latLng,
    }
}

export function saveSelectedAgency(allInfo){
    return{
        type: 'SAVE_SELECTED_AGENCY',
        allInfo,
    }
}

export function saveStartDate(allInfo){
    return{
        type: 'SAVE_START_DATE',
        allInfo,
    }
}

export function saveEndDate(allInfo){
    return{
        type: 'SAVE_END_DATE',
        allInfo,
    }
}

export function saveStartDateUnformated(allInfo){
    return{
        type: 'SAVE_START_DATE_UNFORMATED',
        allInfo,
    }
}

export function saveEndDateUnformated(allInfo){
    return{
        type: 'SAVE_END_DATE_UNFORMATED',
        allInfo,
    }
}

export function saveActualiserState(allInfo){
    return{
        type: 'SAVE_ACTUALISER_STATE',
        allInfo,
    }
}

export function saveHorairesAgency(horaire){
    return{
        type: 'HORAIRE_AGENCY',
        horaire,
    }
}

export function manageMessageNoAvailableVehicle(msg){
    return{
        type: 'MANAGE_MESSAGE_NO_VEHICLE',
        msg,
    }
}

export function saveAllTypeVehicles(typeVehicles){
    return{
        type: 'SAVE_ALL_TYPE_VEHICLES',
        typeVehicles,
    }
}

export function saveAllVehiclesAvailable(vehiclesAvailable){
    return{
        type: 'SAVE_ALL_VEHICLES_AVAILABLE',
        vehiclesAvailable,
    }
}

export function saveFilteredTags(tags){
    return{
        type: 'SAVE_FILTERED_TAGS',
        tags
    }
}

export function saveDevisStatus(status){
    return{
        type: 'SAVE_DEVIS_STATUS',
        status
    }
}

export function updateListVehicle(){
    return{
        type: 'UPDATE_LIST_VEHICLE',
    }
}

export function saveSelectedVehicle(allInfo){
    return{
        type: 'SAVE_ALL_INFO_VEHICLE',
        allInfo,
    }
}

export function saveAddedKm(addedKm){
    return{
        type: 'SAVE_ADDED_KM',
        addedKm
    }
}

export function savePriceOption(option, price){
    return{
        type: 'SAVE_PRICE_OPTION',
        option,
        price,
    }
}

export function saveConducteurInfo(infoConducteur){
    return{
        type: 'SAVE_CONDUCTEUR_INFO',
        infoConducteur,
    }
}

export function changeSectionReservation(section){
    return{
        type: 'CHANGE_SECTION_RESERVATION',
        section,
    }
}

export function cleanStore(){
    return{
        type: 'CLEAN'
    }
}

export function storeToUpdate(newStore){
    return{
        type: 'CHANGE_WITH_LOCAL_STORAGE',
        newStore
    }
}

export function setGlobalPrice(globalPrice) {
    return {
        type: 'SET_GLOBAL_PRICE',
        globalPrice
    }
}
export function setPromoPrice(promoPrice) {
    return {
        type: 'SET_PROMO_PRICE',
        promoPrice
    }
}
export function setPromoCode(code_promo) {
    return {
        type: 'SET_PROMO_CODE',
        code_promo
    }
}

export function saveReference(reference){
    return{
        type: 'SAVE_REFERENCE',
        reference
    }
}