import React, { Component } from 'react';
import { CustomInput } from 'reactstrap';
// REDUX
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { savePriceOption } from '../redux/action'
//HELPER
import { getTotalDaysDifference } from '../helper/getTotalDaysDifference'
//DOC
import noticeAssurance from '../assets/doc/notice_assurance.pdf'

//TRANSLATE
import { Trans, withTranslation } from 'react-i18next';

class PackOption extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isShown: false,
            isChecked: true,
            max: this.props.option.max == 0 ? 0 : (this.props[this.props.option.type] / this.props.option.price / getTotalDaysDifference(this.props.startDate, this.props.endDate)),
        };
    }
    checkBox = (type) => {
        if (this.props[type] > 0) {
            this.props.setTotalPrice(- this.props.option.price * getTotalDaysDifference(this.props.startDate, this.props.endDate))
            this.props.savePriceOption(type, 0)
        }
        else {
            this.props.setTotalPrice(this.props.option.price * getTotalDaysDifference(this.props.startDate, this.props.endDate))
            this.props.savePriceOption(type, this.props.option.price)
        }
    }

    handleChange = (option, type) => {       
        let saveInRedux
        let calc = this.state.max == 0 ? 0 : (type === 'add' ? +1 : -1)

        this.setState({ max: this.state.max + calc })
        let price = this.props.option.price;
        this.props.setTotalPrice(calc * parseFloat(this.props.option.price) * getTotalDaysDifference(this.props.startDate, this.props.endDate))
        saveInRedux = this.props.option.price * getTotalDaysDifference(this.props.startDate, this.props.endDate) * (this.state.max + calc)

        this.props.savePriceOption(option, saveInRedux)

    }

    descPackZen = () => {
        return (
            <p className="pack-zen"><Trans i18nKey="consulter_notice">Vous pouvez consulter la Notice Assurance en cliquant</Trans>
                <a rel="noopener noreferrer" href={noticeAssurance} target="_blank" class="red"><Trans i18nKey="ici"> ici</Trans></a>
            </p>
        )
    }

    render() {
        const price = this.props.option.max === 1 ? this.props.option.price : this.props.option.price * this.state.max

        return (
            <div className="section">
                <div className="d-flex align-content-center flex-wrap">
                    <div className="align-self-center" >
                        {this.props.option.max === 1 ?
                            <CustomInput
                                type="checkbox"
                                label=""
                                id={this.props.option.type}
                                inline
                                checked={this.props[this.props.option.type] > 0 ? true : false}
                                onChange={this.checkBox.bind(this, this.props.option.type)}
                                disabled={this.props.disabled}
                            /> :
                            <div className="section addKm" style={{ border: 'none' }}>
                                <div className="center">
                                    <span className={this.state.max > 0 ? 'active' : ''}
                                        onClick={this.state.max > 0
                                            ? this.handleChange.bind(this, this.props.option.type, 'suppr')
                                            : undefined}>-</span>
                                    {this.state.max}
                                    <span className={this.state.max < 2 ? 'active' : ''}
                                        onClick={this.state.max < 2
                                            ? this.handleChange.bind(this, this.props.option.type, 'add')
                                            : undefined}>+</span>
                                </div>
                            </div>
                        }

                    </div>
                    <div>
                        <h2>
                            {this.props.selectedVehicle ? this.props.t(this.props.option.label) : null}
                        </h2>

                        <em>{this.props.selectedVehicle ? this.props.option.description : null}</em><br />
                        {this.props.selectedVehicle ? this.props.option.label === 'Pack zen' ? this.descPackZen() : null : null}
                    </div>
                </div>
                <div className="priceAssurance">{this.props.selectedVehicle
                    ? price
                    : null},00 € <Trans i18nKey="jour">/ jour</Trans>
                </div>
                <div className="red" style={{ display: this.props[this.props.option.type] === 0 || this.state.max === 0 ? 'block' : 'none', marginLeft: 40, position: 'absolute', fontSize: 13 }}>
                    {this.props.option.unchecked}</div>
            </div>

        );
    }
}

function mapStateToProps(state) {
    return {
        sectionReservation: state.reducer.sectionReservation,
        selectedVehicle: state.reducer.selectedVehicle,
        addedKm: state.reducer.addedKm,
        startDateUnformated: state.reducer.startDateUnformated,
        endDateUnformated: state.reducer.endDateUnformated,
        startDate: state.reducer.startDate,
        endDate: state.reducer.endDate,
        priceOption1: state.reducer.priceOption1,
        priceOption2: state.reducer.priceOption2,
        priceOption3: state.reducer.priceOption3
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        savePriceOption
    }, dispatch)
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(PackOption))
