export class VehiclesOffersSearch {
    static agencySchedule = []
    static closingDays = []

    static setAgencySchedule(agencySchedule) {
        this.agencySchedule = agencySchedule
    }

    static setAgencyClosingDays(closingDays) {
        this.closingDays = closingDays
    }

    static validateSearchForm(selectedAgency, startDate, endDate) {
        if (!startDate || !endDate || !selectedAgency) {
            return false
        }

        startDate = new Date(startDate)
        endDate = new Date(endDate)

        let isValid = selectedAgency
            && startDate
            && endDate
            && !this.isAgencyClosedThisDate(startDate)
            && !this.isAgencyClosedThisDate(endDate)
            && !this.isStartDateAvailable(startDate)
            && !this.isEndDateAvailable(endDate)

        return isValid
        /*if (!isValid) {
            throw 'FORMULAIRE NON VALIDE'
        }*/
    }

    static isStartDateAvailable(date) {
        let currentStartDate = new Date()

        if (date <= currentStartDate) {
            return true
        }
    }

    static isEndDateAvailable(date) {
        let currentEndDate = new Date()
        
        if (date <= currentEndDate) {
            return true
        }
    }

    static isAgencyClosedThisDate(date) {
        // CLOSE DAY
        // AGENCY SCHEDULE
        // TIME
        // START DATE UNFORMATTED
        try {
            let holidays = this.closingDays.map((closedDay)=> {return new Date(closedDay.annee, closedDay.mois -1, closedDay.jour, 12 )})
            
            for (let i =0; holidays.length > i ; i++) {
                if (holidays[i].getDate() === date.getDate() && holidays[i].getMonth() === date.getMonth() && holidays[i].getFullYear() === date.getFullYear()) return true
            }

            if (holidays.includes(date))
                return true

            let tabHoraire = this.agencySchedule
            let closeAgency = []

            for (let i = 0; tabHoraire.length > i; i++) {
                if (tabHoraire[i].ouverture_matin === "None") {
                    let day = i + 1

                    if(day === 7) 
                        day = 0
                    
                        closeAgency.push(day)
                }
            }

            if (closeAgency.includes(date.getDay())) 
                return true
        } catch (e) {
            console.log(e)
        }
    }

    static test() {
        /*let beforeLimit = this.props.time === 'startDate' ?  new Date() : new Date(Date.parse(this.props.startDateUnformated))
        if (date < beforeLimit){
            return true
        }
        let afterLimit = this.props.time === 'startDate' ? new Date().setFullYear(new Date().getFullYear()+1) :
            new Date(Date.parse(this.props.startDateUnformated)).setMonth(new Date(Date.parse(this.props.startDateUnformated)).getMonth()+1)
        if(date > afterLimit){
            return true
        }*/
    }
}