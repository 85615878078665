import React from "react";
import Slider from "react-slick";
import { Link } from 'react-router-dom'

// CSS
import '../assets/css/style.css'

//STYLE
import { Row, Col } from 'reactstrap';

// REDUX
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { } from '../redux/action'

//BABEL
import { babelGetAllVehicles } from '../babel/babelVehiclesService'

//TRANSLATE
import { Trans, withTranslation } from 'react-i18next';

class SliderVehicles extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
        tabVehicle : [],
    };
}

componentDidMount = () => {
  babelGetAllVehicles('SCO,MOT').then(data => {
    this.setState({ allVehicle: data, tabVehicle: data.SCO.sort(this.compare) })
  })
}

compare = (a, b) => {
  if (a.category < b.category)
    return -1;
  if (a.category > b.category)
    return 1;
  
  return 0;
}

render() {
    let typeVehicles = (this.props.type === 'scooter' || this.props.type === 'E-BIKE') ? 'SCO' : 'MOT'
    let tabToShow = this.state.allVehicle ? this.state.allVehicle[typeVehicles].sort(this.compare) : []
    tabToShow = this.props.type === 'E-BIKE' ? (tabToShow.filter(
      (data) => ["VAE1","VAE2"].indexOf(data.category) !== -1
      )) : (this.props.type === 'scooter' ? tabToShow.filter(
        (data) => ["VAE1","VAE2"].indexOf(data.category) === -1
        ) : tabToShow );

        console.log(tabToShow);

    let isTablet = window.innerWidth < 768
    
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: isTablet ? 1 : 3,
      slidesToScroll: isTablet ? 1 : 3,
    }

    let objPrice = {
      'VAE1': 39,
      'VAE2': 39,
      'E': 39,
      'S1': 39,
      'S2': 49,
      'S3': 59,
      'S4': 99,
      'S5': 139,
      'M1': 49,
      'M2': 59,
      'M3': 99,
      'M4': 119,
      'M5': 139,
      'M3B': 99
    }

    return (
      <Row>
        <Slider {...settings} ref={slider=>slider?.slickGoTo(0)}>
          {tabToShow.map((val, i) => {
            return (
              <Link key={i} to={this.props.type == 'E-BIKE' ? 'e-bike/'+ val.reference : "/vehicule/" + val.type + "s/" + val.reference}>
                <Col key={i} style={{ height: 'auto' }}>
                  {/*<div style={{ background: `url(${val.image}) no-repeat center/ contain`, height: 150, width: '100%' }}>*/}
                  <div>
                    <img src={val.image} style={{background:'no-repeat, center',backgroundSize:'contain', height: "inherit", width: '100%'}} alt={this.props.t('Location ') + val.type + this.props.t(' ')+ val.name}/>
                  </div>
                  <p className="grey alignWithBtn" style={{ fontSize: 10 }}>
                    <Trans i18nKey="photo">Photo non contractuelle, la version et le coloris peuvent différer en fonction des
                      disponibilités en agence.</Trans>
                </p>
                  <div className="nomVehicle">{val.name}</div>
                  {<div className="tarif"><b className="apartirde-slider"><Trans i18nKey="a_partir">à partir de</Trans></b> { (val.category == 'VAE1' || val.category == 'VAE2') ? val.fake_price : objPrice[val.category]} € <Trans i18nKey="jour">/ jour</Trans></div>}
                </Col>
              </Link>
            )

          })}
        </Slider>
      </Row>
    )
  }
}

function mapStateToProps(state) {
  return {
    typeVehicles: state.reducer.typeVehicles,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
  }, dispatch)
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(SliderVehicles));