import React, { Component } from 'react';
// REDUX
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { changeSectionReservation } from '../redux/action'
//STYLE
import { Container, Row, Col, Button } from 'reactstrap';
//COMPONENTS
import VehicleAvailable from './vehicleAvailable'
import SideInfoAgency from './reservation_sideInfoAgency'
import { Helmet } from "react-helmet";
import { getUserIP } from "../services/getIpAdress";

//TRANSLATE
import { Trans, withTranslation } from 'react-i18next';
import { IdTagGeolid } from "../services/analytics/geolid/idTagGeolid";
import { bookingTotalPriceSelector } from "../redux/selectors";

class OptionVehicle extends Component {

    componentDidMount() {
        localStorage.removeItem("selectedVehicle");
        localStorage.removeItem("SelectedAgency");
        this.setBrowserBackButtonClick(2)

        getUserIP((ip) => {
            this.pushTagGtm(ip)
        })
        
        this.pushTagGtmEbusiness()

        /*//reload setShopId
        const getLocalStorageSetShopId = localStorage.getItem('localStorageSetShopId')
        IdTagGeolid.setShopId(getLocalStorageSetShopId)*/
    }

    /*----tag GTM-----*/
    pushTagGtm(ip) {
        const internal = [
            '176.149.189.93',//IP Unitec Fibre
            '82.64.74.58',//IP Unitec Guest
            '82.229.26.224',//IP Eric
            '78.237.92.155',//IP CJA
            '172.19.0.1',
        ]

        let traffic = internal.indexOf(ip) === -1 ? 'externe' : 'interne'

        /*    window.dataLayer = window.dataLayer || [];*/
        window.dataLayer.push({
            'event': 'virtualpageview',
            'virtualPath': window.location.pathname + '/options',
            'page_name': 'options_du_vehicule',
            'page_type': 'tunnel_options',
            'category': this.getVehicleType(),
            'sub_category_lvl1': this.props.selectedVehicle.cylindree.toString(),
            'langage': 'fr',
            'country': 'FR',
            'product_name': this.props.selectedVehicle.nomVehicule.toLowerCase().replace(/ /g, '_'),
            'traffic': traffic,
            'agency': this.props.selectedAgency.AgenceCode,
        })
    }

    pushTagGtmEbusiness() {
        /*----tag GTM-----*/
        window.dataLayer.push({
            'event': 'checkout',
            'ecommerce': {
                'checkout': {
                    'actionField': { 'step': 1, 'option': '' },
                    'products': [{
                        'name': this.props.selectedVehicle.nomVehicule.toLowerCase().replace(/ /g, '_'),
                        'id': this.props.selectedVehicle.vehicle.ext_id,
                        'price': this.props.bookingTotalPrice.toString(),
                        'brand': this.props.selectedVehicle.vehicle.brand,
                        'category': this.getVehicleType(),
                        'variant': this.props.selectedVehicle.cylindree.toString(),
                        'quantity': 1,
                        'dimension10': this.props.selectedAgency.AgenceCode,
                    }]
                }
            }
        })
    }

    onBrowserBackButtonClick = (step) => {
        this.handleChangeSection(step)
        this.props.history.go(1)
    }

    setBrowserBackButtonClick = (step) => {
        this.props.history.listen((location, action) => {
            if (action === 'POP') {
                this.onBrowserBackButtonClick(step)
            }
        })
        //window.addEventListener("popstate", this.onBrowserBackButtonClick.bind(this, step))
    }

    handleChangeSection(position) {
        window.scroll(0, 0)
        if (position && typeof position === "number") {
            this.props.changeSectionReservation(position)
            if (position > 3) {
                /*this.pushTagGtmEbusiness()*/
            }
        } else {
            if (this.props.sectionReservation === 3) {
                this.props.changeSectionReservation(4)
            }
        }
    }

    getVehicleType() {
        const typeCode = this.props.selectedVehicle.type

        switch (typeCode) {
            case 'sco':
                return 'Scooter'
            case 'mot':
                return 'Moto'
        }
    }

    render() {
        let isTablet = window.innerWidth < 768
        const description = this.props.t("Louez votre ") + this.getVehicleType() + " " + this.props.selectedVehicle.nomVehicule
            + this.props.t(", pour 1 jour, 1 week-end, 1 semaine. Yamaha Rent, c’est la garantie d’utiliser des véhicules récents et entretenus, pour partir l’esprit libre.")

        const title = this.props.t("Yamaha Rent – Location ") + this.getVehicleType() + " " + this.props.selectedVehicle.nomVehicule + this.props.t(" courte durée")

        return (
            <div>
                <Helmet>
                    <meta name="description" content={description} />
                    <title>{title}</title>
                </Helmet>
                <Container id="allVehicles">
                    <Row>
                        <SideInfoAgency isTablet={isTablet ? 'none' : 'block'} />
                        <Col md="8" id="etaperesbtns">
                            <h1><Trans i18nKey="options">Options du véhicule</Trans></h1>
                            <VehicleAvailable />
                            <div className="relativestep">
                                <Button className="roundBtn mt-0" onClick={this.handleChangeSection.bind(this)}
                                    color="primary"
                                    size="lg" block><Trans i18nKey="continuer">Etape suivante</Trans> ⟶</Button>
                                <Button className="roundBtn mt-0 mr-3" onClick={this.handleChangeSection.bind(this, 2)}
                                    color="primary"
                                    size="lg" block>⟵ <Trans i18nKey="etape_precedente">Etape précédente</Trans></Button>
                            </div>
                        </Col>
                        <SideInfoAgency isTablet={isTablet ? 'block' : 'none'} />

                    </Row>
                </Container>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        bookingTotalPrice: bookingTotalPriceSelector(state),
        sectionReservation: state.reducer.sectionReservation,
        selectedVehicle: state.reducer.selectedVehicle,
        selectedAgency: state.reducer.selectedAgency,
        addedKm: state.reducer.addedKm,
        priceOption1: state.reducer.priceOption1,
        priceOption2: state.reducer.priceOption2,
        priceOption3: state.reducer.priceOption3,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        changeSectionReservation
    }, dispatch)
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(OptionVehicle));
