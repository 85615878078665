import React, { Component } from "react";
import { Row, Col, Button } from "reactstrap";

// REDUX
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  changeSectionReservation,
  saveSelectedVehicle,
  saveAddedKm,
  savePriceOption,
  setGlobalPrice,
  saveActualiserState,
} from "../redux/action";

//COMPONENT
import PackOption from "./packOption";

//IMAGE
import A from "../assets/img/license_a.png";
import A2 from "../assets/img/license_a2.png";
import A1 from "../assets/img/license_a1.png";
import AM from "../assets/img/license_am.png";
import B from "../assets/img/license_b.png";
import no from "../assets/img/no_license.png";

//TRANSLATE
import { Trans, withTranslation } from "react-i18next";
import { bookingTotalPriceSelector } from "../redux/selectors";

class VehicleAvailable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      totalKm:
        this.props.sectionReservation === 2
          ? 200
          : +this.props.selectedVehicle.kmInclu + +this.props.addedKm,
      totalPrice:
        this.props.sectionReservation === 2
          ? +this.props.tarif
          : this.props.bookingTotalPrice?.toFixed(2),
      old_price: this.props.old_tarif?.toFixed(2),
      showOption: false,
      showPopup: false,
      priceOption1: "true",
      priceOption2: "true",
      priceOption3: "true",
      isShown: false,
      selectedAgency: this.props.selectedAgency,
      AgenciesWithNoOptions : ['YA02069FR', 'YA01075FR']
    };

    this.props.setGlobalPrice(this.state.totalPrice);
  }

  /*componentDidMount(): void {
        //reload setShopId
        const getLocalStorageSetShopId = localStorage.getItem('localStorageSetShopId')
        IdTagGeolid.setShopId(getLocalStorageSetShopId)
    }*/

  componentDidUpdate() {
    if (this.props.tarif && this.props.tarif !== this.state.totalPrice) {
      this.setState({ totalPrice: this.props.tarif });
    }
  }

  manageOptions(vehicle) {
    var date1 = new Date(this.props.startDate);
    var date2 = new Date(this.props.endDate);
    var timeDiff = Math.abs(date2.getTime() - date1.getTime());
    var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
    let tabOption = ["priceOption1", "priceOption2", "priceOption3"];
    let objOption = {};

    for (let i = 0; i < tabOption.length; i++) {
      if (tabOption[i] === "priceOption1") {
        let ttc_price = ['VAE1', 'VAE2'].indexOf(this.props.allInfoVehicle.vehicle.category_code) != -1 ? 0 : vehicle[i].Tarifs.TTC;
        objOption = { ...objOption, [tabOption[i]]: ttc_price };
      } else {
        objOption = {
          ...objOption,
          [tabOption[i]]: this.state.AgenciesWithNoOptions.indexOf(this.state.selectedAgency.AgenceCode) != -1 ? 0 : (vehicle[i].Tarifs.TTC * diffDays),
        };
      }
    }

    return objOption;
  }

  isFormValid() {
    return !this.props.actualiserState;
  }

  setTotalPrice = (total) => {
    this.setState({
      totalPrice:
        parseFloat(this.state.totalPrice).toFixed(2) + parseFloat(total),
    });
    this.props.setGlobalPrice(parseFloat(this.state.totalPrice).toFixed(2));
  };

  closePopup() {
    this.setState({ isShown: false });
  }

  handleChangeKm(type) {
    let calculKm = this.state.totalKm;
    let calculPrice = this.state.totalPrice;

    //GET TARIF KM
    let whichVarToUse =
      this.props.sectionReservation === 2
        ? "allInfoVehicle"
        : "selectedVehicle";
    let priceKm = this.props[whichVarToUse].prixKm * 100;

    if (this.state.totalKm > this.props.selectedVehicle.kmInclu) {
      if (type === "add") {
        calculKm = this.state.totalKm + 100;
        calculPrice =
          parseFloat(this.state.totalPrice) + parseFloat(priceKm.toFixed(2));
      } else {
        calculKm = this.state.totalKm - 100;
        calculPrice =
          parseFloat(this.state.totalPrice) - parseFloat(priceKm.toFixed(2));
      }
    } else if (
      this.state.totalKm === this.props.selectedVehicle.kmInclu &&
      type === "add"
    ) {
      calculKm = this.state.totalKm + 100;
      calculPrice =
        parseFloat(this.state.totalPrice) + parseFloat(priceKm.toFixed(2));
    }

    this.setState({
      totalKm: calculKm,
      totalPrice: parseFloat(calculPrice).toFixed(2),
    });

    this.props.saveAddedKm(calculKm - this.props.selectedVehicle.kmInclu);
  }

  handleChangeSection() {
    if (this.props.sectionReservation === 2) {
      this.props.changeSectionReservation(3);
      var allInfoVehicle = JSON.parse(localStorage.getItem("allInfoVehicle"));

      if (allInfoVehicle != null) {
        this.props.saveSelectedVehicle(allInfoVehicle);
        var priceOption = this.manageOptions(allInfoVehicle.prestation);
      } else {
        this.props.saveSelectedVehicle(this.props.allInfoVehicle);
        var priceOption = this.manageOptions(
          this.props.allInfoVehicle.prestation
        );
      }

      for (let i = 1; i <= 3; i++) {
        let option = "priceOption" + i;
        this.props.savePriceOption(option, priceOption[option]);
      }
    } else if (this.props.sectionReservation === 3) {
      this.props.changeSectionReservation(4);
    }
  }

  handleShowOption() {
    this.setState({ showPopup: !this.state.showPopup });
  }
  // conditions permis
  condper(cat,nomVehicule){
    let cp = {
      'S1': "AM,A1,A2,A,B",
      'S2': "A1,A2,A,B (2 ans d'exp) + formation 7h",
      'S3': "A1,A2,A,B (2 ans d'exp) + formation 7h",
      'S4': "A2,A",
      'S5': "A2,A",
      'M1': "A1,A2,A,B (2 ans d'exp) + formation 7h",
      'M2': "A1,A2,A,B (2 ans d'exp) + formation 7h",
      'M3': "A2,A",
      'M4': "A",
      'M5': "A",
      'M3B': "A",
      'E':"AM,A1,A2,A,B (2 ans d'exp) + formation 7h",
      'VAE1': this.props.t("Sans permis"),
      'VAE2': this.props.t("Sans permis")
    }
    if(cat=="S4" && nomVehicule.includes("Tricity")){
      return "A1,A2,A,B (2 ans d'exp) + formation 7h*"
    }else{
      return cp[cat]
    }
  }
  render() {
    var allInfoVehicle = JSON.parse(localStorage.getItem("allInfoVehicle"));

    if (allInfoVehicle != null) {
      var vehicle = allInfoVehicle;
      // localStorage.removeItem("allInfoVehicle")
    } else {
      var vehicle = this.props.allInfoVehicle
        ? this.props.allInfoVehicle
        : this.props.selectedVehicle;
    }

    let tabOption = [];

    if (['VAE1', 'VAE2'].indexOf(vehicle.vehicle.category_code) != -1) {
      tabOption = [
        {
          type: "priceOption2",
          price: vehicle.prestation[1].Tarifs.TTC,
          label: vehicle.prestation[1].Label,
          description: vehicle.prestation[1].Description,
          unchecked: this.props.t(
            "Attention, le port d’une paire de gants homologués est obligatoire"
          ),
          max: 2,
        },
        {
          type: "priceOption3",
          price: vehicle.prestation[2].Tarifs.TTC,
          label: vehicle.prestation[2].Label,
          description: vehicle.prestation[2].Description,
          unchecked: this.props.t(
            "Attention, le port d’un casque homologué est obligatoire"
          ),
          max: 2,
        },
      ];
    } else if (this.state.AgenciesWithNoOptions.indexOf(this.state.selectedAgency.AgenceCode) != -1) {
      tabOption = [
        {
          type: "priceOption1",
          price: vehicle.prestation[0].Tarifs.TTC,
          label: vehicle.prestation[0].Label,
          description: vehicle.prestation[0].Description,
          unchecked: this.props.t(
            "En cas de sinistre vous devrez acquitter intégralement le montant de la franchise"
          ),
          max: 1,
        },
        {
          type: "priceOption2",
          price: vehicle.prestation[1].Tarifs.TTC,
          label: vehicle.prestation[1].Label,
          description: vehicle.prestation[1].Description,
          max: 0,
        },
        {
          type: "priceOption3",
          price: vehicle.prestation[2].Tarifs.TTC,
          label: vehicle.prestation[2].Label,
          description: vehicle.prestation[2].Description,
          max: 0,
        },
      ];      
    }else {
      tabOption = [
        {
          type: "priceOption1",
          price: vehicle.prestation[0].Tarifs.TTC,
          label: vehicle.prestation[0].Label,
          description: vehicle.prestation[0].Description,
          unchecked: this.props.t(
            "En cas de sinistre vous devrez acquitter intégralement le montant de la franchise"
          ),
          max: 1,
        },
        {
          type: "priceOption2",
          price: vehicle.prestation[1].Tarifs.TTC,
          label: vehicle.prestation[1].Label,
          description: vehicle.prestation[1].Description,
          unchecked: this.props.t(
            "Attention, le port d’une paire de gants homologués est obligatoire"
          ),
          max: 2,
        },
        {
          type: "priceOption3",
          price: vehicle.prestation[2].Tarifs.TTC,
          label: vehicle.prestation[2].Label,
          description: vehicle.prestation[2].Description,
          unchecked: this.props.t(
            "Attention, le port d’un casque homologué est obligatoire"
          ),
          max: 2,
        },
      ];
    }

    const tabImg = { A, A2, A1, AM, B,no };
    let img = tabImg[vehicle.permis] ? tabImg[vehicle.permis] : ((vehicle.categorie === "VAE1" || vehicle.categorie === "VAE2") ? tabImg.no : tabImg.B);
    let tabPermis = ["AM", "B", "B*", "A1", "A2", "A"];
    let validePermis = false;
    let count = 0;
    return (
      <Row className="vehicleAvailable">
        <Col md="3">
          <img alt="véhicule" src={vehicle.img} />
        </Col>
        <Col md="4">
          <h2 className="nom">
            {this.props.nom
              ? this.props.nom
              : this.props.selectedVehicle.nomVehicule}
          </h2>
          <p className="grey alignWithBtn" style={{ display: "flex" }}>
            <img
              alt="véhicule"
              src={img}
              style={{ marginRight: 10, height: 35, width: 32, marginTop: 5 }}
            />
            <span style={{ fontSize: 13,display:'flex',alignItems:'center' }}>
              { (vehicle.categorie != "VAE1" && vehicle.categorie != "VAE2") && <Trans i18nKey="permis">Permis </Trans> } 
              {/* {tabPermis.map((val, i) => {
                count++
                        if (val === vehicle.permis) {
                           validePermis = true;
                        }
                        if (validePermis) {
                          return count != tabPermis.length && count != tabPermis.length -1 ? val + "," : count == tabPermis.length -1 ? val + " ou " : val
                        } else {
                          return null;
                        }
                      })}
              ) <Trans i18nKey="necessaire">nécessaire</Trans> */}
              {
                this.condper(vehicle.categorie,this.props.nom
                  ? this.props.nom
                  : this.props.selectedVehicle.nomVehicule)
              }
              <br />
              {vehicle.categorie === "M3"
              || vehicle.categorie === "M1"
              || vehicle.categorie === "M2"
              || vehicle.categorie === "S1"
              || vehicle.categorie === "S2"
              || vehicle.categorie === "S3"
              || vehicle.categorie === "S4"
              || vehicle.categorie === "E"
                ? "23 " 
                : (vehicle.categorie === "VAE1" || vehicle.categorie === "VAE2") ? "" : "25 "}{" "}
                {/* {vehicle.categorie === "M3" 
                ?  <Trans i18nKey="age_mini">ans requis et 3 ans de permis</Trans> 
                : <Trans i18nKey="age_mini_2">ans requis et 2 ans de permis</Trans>} */}
                { (vehicle.categorie != "VAE1" && vehicle.categorie != "VAE2") && <Trans i18nKey="age_mini_2">ans requis et 2 ans de permis</Trans> }
            </span>
          </p>
        </Col>
        <Col md="5" className="lastCol">
          <div id="totalPrice">
            {parseFloat(this.state.totalPrice)
              .toFixed(2)
              .toString()
              .replace(".", ",")}{" "}
            €
            <span
              style={
                (this.state.totalPrice != null &&
                  this.props.allInfoVehicle == null) ||
                this.state.totalPrice == this.props.allInfoVehicle?.old_tarif
                  ? { display: "none" }
                  : null
              }
              className="old_price"
            >
              {this.props.allInfoVehicle?.old_tarif
                .toString()
                .replace(".", ",")}
              €
            </span>
            <p
              className="grey"
              style={{
                fontSize: 14,
                display: this.props.sectionReservation === 3 ? "block" : "none",
              }}
            >
              <Trans i18nKey="prix_total">Prix total</Trans>
              <br />
              {this.state.totalKm} km
            </p>
          </div>
          <Row
            style={
              this.props.sectionReservation === 3 ? { display: "none" } : null
            }
          >
            <Col>
              <Button onClick={this.handleShowOption.bind(this)}>
                <Trans i18nKey="details">Détails</Trans>
              </Button>
            </Col>
            <Col>
              <Button
                block
                disabled={!this.isFormValid()}
                onClick={this.handleChangeSection.bind(this)}
                color="primary"
                size="lg"
                block
              >
                <Trans i18nKey="continuer">Continuer</Trans>
              </Button>
            </Col>
          </Row>
        </Col>
        <p className="grey alignWithBtn" style={{ fontSize: 10 }}>
          <Trans i18nKey="photo">
            Photo non contractuelle, la version et le coloris peuvent différer
            en fonction des disponibilités en agence.
          </Trans>
        </p>

        <div
          className="modale"
          style={
            this.state.showPopup ? { display: "block" } : { display: "none" }
          }
        >
          <h2 style={{ marginLeft: 10 }}>
            <Trans i18nKey="caracteristiques">Caractéristiques</Trans>
          </h2>
          <Row id="caract_bullet" style={{ padding: 10, paddingBottom: 0 }}>
            <Col>
              <li>
                <Trans i18nKey="cylindree">Cylindrée</Trans>:{" "}
                {this.props.allInfoVehicle &&
                  this.props.allInfoVehicle.cylindree + " cm3"}
              </li>
              <li>
                <Trans i18nKey="transmission">Transmission</Trans>:{" "}
                {this.props.allInfoVehicle &&
                  this.props.allInfoVehicle.transmission}
              </li>
              <li>
                <Trans i18nKey="consommation">Consommation</Trans>:{" "}
                {this.props.allInfoVehicle &&
                  this.props.allInfoVehicle.consommation}
              </li>
            </Col>
            <Col>
              <li>
                <Trans i18nKey="puissance_max">Puissance max.</Trans>:{" "}
                {this.props.allInfoVehicle &&
                  this.props.allInfoVehicle.puissanceMax}
              </li>
              <li>
                <Trans i18nKey="ht_selle">Hauteur de selle</Trans>:{" "}
                {this.props.allInfoVehicle &&
                  this.props.allInfoVehicle.hauteurSelle}
              </li>
              <li>
                <Trans i18nKey="garde_sol">Garde au sol</Trans>:{" "}
                {this.props.allInfoVehicle &&
                  this.props.allInfoVehicle.gardeSol}
              </li>
              <li>
                <Trans i18nKey="poids">Poids total</Trans>:{" "}
                {this.props.allInfoVehicle && this.props.allInfoVehicle.poids}
              </li>
            </Col>
          </Row>
          <div id="detailsContainer">
            <div id="details_descriptif">
              <h2>
                <Trans i18nKey="descriptif">Descriptif</Trans>
              </h2>
              <p>
                {this.props.allInfoVehicle
                  ? this.props.allInfoVehicle.description
                  : null}
              </p>
            </div>
          </div>
        </div>

        <Col xs="12">
          <div
            className={
              this.props.sectionReservation === 3 ? "option show" : "option"
            }
          >
            <div className="section addKm">
              <h2>
                <Trans i18nKey="forfait_km_base">
                  Forfait kilométrique de base :{" "}
                </Trans>
                {this.props.selectedVehicle &&
                  this.props.selectedVehicle.kmInclu}{" "}
                km
              </h2>
              <div style={{ display: "flex" }}>
                <p style={{ flex: "1 1 auto" }}>
                  <Trans i18nKey="km_supp">Kilomètres supplémentaires</Trans> (
                  {this.props.selectedVehicle &&
                    Math.round(this.props.selectedVehicle.prixKm * 100)}{" "}
                  € / 100 km)
                </p>
                <div className="center">
                  {/* <span
                    className={this.props.selectedVehicle ? this.state.totalKm > this.props.selectedVehicle.kmInclu ? 'active' : null : 0}
                    onClick={this.handleChangeKm.bind(this, 'suppr')}>-</span> */}
                  {this.state.totalKm -
                    (this.props.selectedVehicle
                      ? this.props.selectedVehicle.kmInclu
                      : 0)}{" "}
                  km
                  {/* <span className="" style={{ cursor: 'not-allowed', }}>+</span> */}
                  {/* <span className="active" disabled='disabled' onClick={this.handleChangeKm.bind(this, 'add')}>+</span> */}
                </div>
              </div>
            </div>
            {tabOption.map((val, i) => {
              return (
                <PackOption
                  key={i}
                  option={val}
                  setTotalPrice={this.setTotalPrice}
                  disabled={['VAE1', 'VAE2'].indexOf(vehicle.vehicle.category_code) > -1}
                />
              );
            })}
          </div>
        </Col>
      </Row>
    );
  }
}

function mapStateToProps(state) {
  return {
    bookingTotalPrice: bookingTotalPriceSelector(state),
    selectedAgency: state.reducer.selectedAgency,
    sectionReservation: state.reducer.sectionReservation,
    selectedVehicle: state.reducer.selectedVehicle,
    addedKm: state.reducer.addedKm,
    startDateUnformated: state.reducer.startDateUnformated,
    endDateUnformated: state.reducer.endDateUnformated,
    startDate: state.reducer.startDate,
    endDate: state.reducer.endDate,
    priceOption1: state.reducer.priceOption1,
    priceOption2: state.reducer.priceOption2,
    priceOption3: state.reducer.priceOption3,
    actualiserState: state.reducer.actualiserState,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      changeSectionReservation,
      saveSelectedVehicle,
      saveAddedKm,
      savePriceOption,
      setGlobalPrice,
      saveActualiserState,
    },
    dispatch
  );
}

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(VehicleAvailable)
);
