import {ENVIRONMENT,SERVER_ADDRESS_80,SERVER_ADDRESS_80_PP, GET_HORAIRE_AGENCY, GET_YAMAHA_AGENCIES, GET_AGENCIES_AROUND_ME} from "../configuration";
import axios from "axios";
import i18n from "../i18n";

export const babelGetHoraires = async (AgenceCode) => {

    let params = `?agence_id=''&agence_code=${AgenceCode}`
    
    return axios({
        method: 'get',
        url: (ENVIRONMENT == "PROD" ? SERVER_ADDRESS_80 : SERVER_ADDRESS_80_PP) + GET_HORAIRE_AGENCY + params,
        headers: {
            'Content-Type': 'application/json',
        }
        
        }).then(data => {
            return data.data.response
        
        }).catch(error => {
            return error
        })
}

export const getYamahaAgencies = async () => {
    return axios({
        method: 'get',
        url: (ENVIRONMENT == "PROD" ? SERVER_ADDRESS_80 : SERVER_ADDRESS_80_PP) + GET_YAMAHA_AGENCIES,
        headers: {
            'Content-Type': 'application/json',
        }
        
        }).then(data => {
            return data.data.response
        
        }).catch(error => {
            console.log(error.message)
            return error
        })
}

export const getAgenciesAroundMe = async (latitude:float = 46.5593766, longitude:float = 2.3993725, radius = 9999, include=null) => {
    let params = `?latitude=${latitude}&longitude=${longitude}&radius=${radius}&enseigne=yamaha&order_by=zip`
    if(include !== null) params += "&include="+include
    return axios({
        method: 'get',
        url: (ENVIRONMENT == "PROD" ? SERVER_ADDRESS_80 : SERVER_ADDRESS_80_PP) + GET_AGENCIES_AROUND_ME + params,
        headers: {
            'content-type': 'application/json',
            'Accept-Language':i18n.language === undefined ? 'fr' : i18n.language,
        }
    }).then(data => {

        return data.data.response

    }).catch(error => {
        console.log(error.message)
        return error
    })
}

export const getAgency = async (agencyCode, include=null) =>{
    let params = `?agencies_codes=${agencyCode}`
    if(include !== null) params += "&include="+include
    return axios({
        method:'get',
        url: (ENVIRONMENT == "PROD" ? SERVER_ADDRESS_80 : SERVER_ADDRESS_80_PP) + GET_AGENCIES_AROUND_ME + params,
        headers: {
            'content-type': 'application/json',
        }
    }).then(data => {

        return data.data.response[0]

    }).catch(error => {
        console.log(error.message)
        return error
    })
}
