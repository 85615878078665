import React, { Component } from 'react';
//COMPONENTS
import Header from '../staticComponents/header'
import Footer from '../staticComponents/footer'
import { Container, Row, Col } from 'reactstrap';
//TRANSLATION
import { Trans, withTranslation } from 'react-i18next';

class Cookies extends Component {
    constructor(props) {
        super(props)
        this.state = {
            g_tags: true,
            g_analytics: true,
        };
    }

    handleChangeConsent = (type, bool) => {
        this.setState({[type] : bool})
    }

    consentCookies = (consent) => {
        if(consent){
            if(this.state.g_analytics){
                let d = new Date();
                d.setTime(d.getTime() + (1*24*60*60*1000));
                let expires = "expires="+ d.toUTCString();
                document.cookie = "CookieConsent=" + true + ";" + expires ;
            } else{
                document.cookie = "CookieConsent=; expires=Thu, 01 Jan 1970 00:00:00 UTC;"
            }
            this.props.history.push('/')
        } else {
            this.props.history.push('/')
        }
    }

    render() {
        return (
            <div>
                <Header path={this.props.match} />
                <Container>
                    <Row style={{width: '100%', margin: 0}}>
                        <Col md="2"></Col>
                        <Col md="8">
                            <h1 style={{marginTop: 40, marginBottom: 20}}><Trans i18nKey="a_propos_cookies">A propos des cookies</Trans></h1>
                            <Trans i18nKey="cookies_p1">Lorsque vous naviguez sur notre site internet, des informations sont
                            susceptibles d'être enregistrées, ou lues, dans votre terminal, sous 
                            réserve de vos choix. Ces informations nous permettent de vous proposer 
                                une navigation optimisée et un contenu personnalisé.</Trans>
                            <br/><br/>
                            <Trans i18nKey="cookies_pub_perso">Acceptez-vous le dépôt et la lecture de cookies afin que nous puissions
                                analyser vos centres d'intérêts pour vous proposer des publicités personnalisées ?</Trans><br/>
                            <div 
                                onClick={()=>this.handleChangeConsent('g_tags', true)}
                                className={this.state.g_tags ? "red" : ""} 
                                style={{display: 'inline-block', marginRight: 15, fontWeight: 'bold', cursor: 'pointer'}}><Trans i18nKey="oui">Oui</Trans></div>
                            <div 
                                onClick={()=>this.handleChangeConsent('g_tags', false)}
                                className={!this.state.g_tags ? "red" : ""} 
                                style={{display: 'inline-block', marginRight: 15, fontWeight: 'bold', cursor: 'pointer'}}><Trans i18nKey="non">Non</Trans></div>
                            <br/><br/>
                            <Trans i18nKey="cookies_mesure_audience">Acceptez-vous le dépôt et la lecture de cookies afin d'analyser votre navigation
                                et nous permettre de mesurer l'audience de notre site internet :</Trans><br/>
                            <div 
                                onClick={()=>this.handleChangeConsent('g_analytics', true)}
                                className={this.state.g_analytics ? "red" : ""} 
                                style={{display: 'inline-block', marginRight: 15, fontWeight: 'bold', cursor: 'pointer'}}><Trans i18nKey="oui">Oui</Trans></div>
                            <div 
                                onClick={()=>this.handleChangeConsent('g_analytics', false)}
                                className={!this.state.g_analytics ? "red" : ""} 
                                style={{display: 'inline-block', marginRight: 15, fontWeight: 'bold', cursor: 'pointer'}}><Trans i18nKey="non">Non</Trans></div>

                            <div style={{marginTop: 50}}>
                                <button 
                                    onClick={() => this.consentCookies(true)}
                                    class="roundBtn mt-0 mr-3 btn btn-primary btn-lg btn-block"
                                ><Trans i18nKey="valider">Valider</Trans></button>
                                <button 
                                    onClick={() => this.consentCookies(false)}
                                    class="roundBtn mt-0 mr-3 btn btn-primary btn-lg btn-block" 
                                    style={{background: '#e7e7e7', border: 'none', color: '#555', marginBottom: 30}}
                                ><Trans i18nKey="retour">Retour</Trans></button>
                            </div>
                        </Col>
                        <Col md="2"></Col>
                    </Row>
                </Container>
                <Footer />
            </div>
        );
    }
}

export default withTranslation()(Cookies);
