import React, {Component} from 'react';

export class IdTagGeolid {
    static gt
    /*static trackingCode = 'GT.....'*/

    static appId;

    static initService = (gt) => {
        this.gt = gt || function () {
            if(gt) {
                (gt.q = gt.q || []).push(arguments)
            }
        }
    }

    static getGt() {
        return this.gt
    }

    static initShop(trackingCode, agencyCode) {
        this.gt('init', trackingCode, agencyCode);
    }

    static setShopId(agencyCode){
        this.gt('loadAppId', agencyCode)
    }

    static trackPageView() {
        this.gt('trackPageView');
    }

    /*static idTagGeolidWithAgencies = [
        {agencyCode: 'YA01078FR', id: 134178, name: 'Conflans Motos'},
        {agencyCode: 'YA01075FR', id: 214255, name: 'Yam Paris 15'},
        {agencyCode: 'YA03006FR', id: 210775, name: 'Onda'},
        {agencyCode: 'YA02006FR', id: 216088, name: 'Castellan'},
        {agencyCode: 'YA01006FR', id: 214861, name: 'Jet 7 Bike'},
        {agencyCode: 'YA01013FR', id: 26506, name: 'Motos Gouirand'},
        {agencyCode: 'YA01031FR', id: 114614, name: 'Yam 31'},
        {agencyCode: 'YA02033FR', id: 216104, name: '100% scoot'},
        {agencyCode: 'YA01038FR', id: 215000, name: 'Team Menduni'},
        {agencyCode: 'YA01047FR', id: 214852, name: 'Box-R'},
        {agencyCode: 'YA01057FR', id: 216068, name: 'JMS-Co'},
        {agencyCode: 'YA01060FR', id: 214978, name: 'Yam Compiègne'},
        {agencyCode: 'YA01064FR', id: 216085, name: 'Etcheverlepo'},
        {agencyCode: 'YA02064FR', id: 27849, name: 'Raff Motos'},
        {agencyCode: 'YA01066FR', id: 214808, name: 'Yam 66'},
        {agencyCode: 'YA01067FR', id: 28148, name: 'Motos Sohn'},
        {agencyCode: 'YA01073FR', id: 214840, name: 'Profil motos Chambéry'},
        {agencyCode: 'YA02074FR', id: 215066, name: 'Profil motos Thonon'},
        {agencyCode: 'YA01077FR', id: 213848, name: 'Gauvin Motos 2'}
    ]*/
}
