import 'react-app-polyfill/ie11';
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import 'core-js/stable'
import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import App from './App';
import * as Sentry from '@sentry/browser';
//TRANSLATION
import './i18n';
/*import { I18nextProvider } from "react-i18next";*/
//ROUTER
import { BrowserRouter } from 'react-router-dom'
//REDUX
import { Provider } from 'react-redux'
import { createStore } from "redux";
import allReducers from './redux/reducer_index';
import {unregister} from './registerServiceWorker';

let store = createStore(allReducers)

Sentry.init({dsn: "https://eb7fcec8afbf46989b8586529a2a2cdd@sentry.io/1829205"});

ReactDOM.hydrate((
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
  ), document.getElementById('root'))

unregister();
