import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {Trans, withTranslation} from 'react-i18next';
import ChangeLanguage from "../translations/change-langage"
import searchDealerLocator from "../modules/searchDealerLocator"
//STYLE CSS
import '../assets/css/styleBtnChangeLanguage.css';
import i18n from '../i18n';

class Header extends Component {

    constructor(props) {
        super(props)
        this.state = {showNav: false,};
    }

    handleClick() {
        this.setState({showNav: !this.state.showNav})

    }

    render() {
        let lang = this.props.i18n.language || 'fr'
        let url = this.props.path ? this.props.path.url : ''

        const paths = {
            scooters: lang === 'fr' ? '/vehicules/scooters' : this.props.t('url_scooters'),
            motos: lang === 'fr' ? '/vehicules/motos' : this.props.t('url_motos'),
            agences: lang === 'fr' ? '/agences-de-location' : this.props.t('url_agencies'),
            Ebike: lang === 'fr' ? '/e-bikes' : this.props.t('url_ebikes'),
        }

        return (
            <header>
                <Link id="logo" to={
                    lang === 'fr' ? '/' : '/' + lang
                }/>
                <nav>
                    <div id="burger" onClick={this.handleClick.bind(this)}>☰</div>
                    <ul className={this.state.showNav ? 'show' : ''}>
                        <li className={url === '/' ? 'active' : ''}><Link to={lang === 'fr' ? '/' : '/' + lang}><Trans
                            i18nKey="Accueil">Accueil</Trans></Link></li>

                        <li className={url === '/agences-de-location' ? 'active': ''}><Link
                            to={paths.agences}><Trans
                            i18nKey="Nos-agences">Agences</Trans></Link></li>
                        {/*<li><a className="anchor-dealerlocator" href={lang === 'fr' ? '/nos-agences': this.props.t('/nos-agences')}><Trans i18nKey="Nos-agences">Nos agences</Trans></a></li>*/}

                        <li className={url === '/vehicules/scooters' ? 'active' : ''}><Link
                            to={paths.scooters}><Trans i18nKey="scooters">Scooters</Trans></Link></li>
                        <li className={url === '/vehicules/motos' ? 'active' : ''}><Link
                            to={paths.motos}><Trans
                            i18nKey="motos">Motos</Trans></Link></li>
                        <li className={url === '/e-bikes' ? 'active' : ''}><Link
                            to={paths.Ebike}><Trans
                            i18nKey="Ebike">E-bike</Trans></Link></li>
                        <li>
                            <a href='/pack-cadeau.aspx' ><Trans i18nKey="CARTE CADEAU">CARTE CADEAU</Trans></a>
                        </li>
                        <li><ChangeLanguage/></li>
                    </ul>
                </nav>
            </header>
        );
    }
}

const exportComponent = withRouter(props => <Header {...props}/>);
export default withTranslation()(exportComponent);
