import React, { Component } from "react";
import axios from "axios";
//DAY PICKER
import { formatDate } from "react-day-picker/moment";
// REDUX
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
//STYLE
import { Container, Row, Col, Button } from "reactstrap";
//COMPONENTS
import SideInfoAgency from "./reservation_sideInfoAgency";
import { changeSectionReservation } from "../redux/action";
import {
  ENVIRONMENT,
  BABEL_V2_URL,
  BABEL_V2_URL_PP,
  BABEL_V2_URL_VERSION,
  BABEL_V2_URL_VERSION_PP,
  PAYBOX_IDENTIFIANT,
  PAYBOX_IDENTIFIANT_PP,
  PAYBOX_KEY,
  PAYBOX_KEY_PP,
  PAYBOX_RANG,
  PAYBOX_RANG_PP,
  PAYBOX_SITE,
  PAYBOX_SITE_PP,
  PAYBOX_URL,
  PAYBOX_URL_PP,
  SERVER_ADDRESS_PACK_CADEAU,
  SERVER_ADDRESS_PACK_CADEAU_PP,
  SERVER_ADDRESS_TRANSACTIONS,
  SERVER_ADDRESS_TRANSACTIONS_PP,
  PBX_INFO,
  SERVER_ADDRESS_80_PP,
  SERVER_ADDRESS_80,
  UrlAdyen,
  UrlAdyen_PP,
  Adyen_environment_PP,
  Adyen_environment,
  Adyen_clientKey_PP,
  Adyen_clientKey
} from "../configuration";
import { getUserIP } from "../services/getIpAdress";
import i18n from "../i18n";

import { Trans, withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { bookingTotalPriceSelector } from "../redux/selectors";
import arrowClose from '../assets/img/close-icon-red.png'
import AdyenCheckout from '@adyen/adyen-web';
import '@adyen/adyen-web/dist/adyen.css';

const paybox = require("../paybox/paybox");

class Paiement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showCrypto: false,
      cardIsNotValid: false,
      cardError: this.props.t(
        "Merci de vérifier le numéro de votre carte de crédit"
      ),
      isLoading: false,
      sent: false,
      globalPrice: this.props.bookingTotalPrice,
      isCallCenter: false,
      isPayement: false,
      send_sms_ok: false,
      send_sms_not_ok: false,
      erreurPayement: false,
    };

    this.paymentForm = React.createRef();
    this.dropinContainerRef = React.createRef(); // Add this line

    this.handlePayment = this.handlePayment.bind(this);
    this.ValidateResaWithPromo = this.ValidateResaWithPromo.bind(this);
    this.goToOptions();
  }

  componentDidMount() {
    if (this.props.globalPrice > 0 && (this.state.isCallCenter == false || (this.state.isPayement == true))) {
      this.getSessionAdyen();
    }

    this.setBrowserBackButtonClick(4);
    getUserIP((ip) => {
      this.pushTagGtm(ip);
    });
    this.pushTagGtmEbusiness();
  }

  async getSessionAdyen() {
    const bookingReference = localStorage.getItem("bookingReference");
    var bookingTotalPrice = this.props.bookingTotalPrice;
    bookingTotalPrice = parseFloat(this.props.bookingPromoPrice) > 0 ? parseFloat(this.props.globalPrice) : bookingTotalPrice;

    if (bookingTotalPrice < 0) {
      bookingTotalPrice = -bookingTotalPrice;
    }

    var transactionId = new Date().valueOf();

    const urlOk =
      document.location.protocol +
      "//" +
      document.location.hostname +
      (document.location.port ? ":" + document.location.port : "") +
      (this.props.i18n.language === "fr"
        ? "/paiement-accepte"
        : this.props.t("url_paiementaccepte"));

    // const url = `${(ENVIRONMENT == "PROD" ? UrlAdyen : UrlAdyen_PP)}/adyens/sessions?idBrand=4&price=${bookingTotalPrice}&urlPay=${urlOk}&IdRes=${bookingReference}&shopperEmail=${this.props.infoConducteur.email}&shopperFirstName=${this.props.infoConducteur.nom}&shopperLastName=${this.props.infoConducteur.prenom}&shopperReference=YAMAHA_${bookingReference}`;
    const url = `${(ENVIRONMENT == "PROD" ? SERVER_ADDRESS_PACK_CADEAU : SERVER_ADDRESS_PACK_CADEAU_PP)}adyen&price=${bookingTotalPrice}&urlPay=${urlOk}&IdRes=${bookingReference}&shopperEmail=${this.props.infoConducteur.email}&shopperFirstName=${this.props.infoConducteur.nom}&shopperLastName=${this.props.infoConducteur.prenom}&shopperReference=YAMAHA_${bookingReference}`;

    return await axios({
      method: "GET",
      url: url,
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        let data = JSON.parse(response.data);

        if (response.data && data.data) {
          this.adyen(data.data.id, data.data.sessionData);
        }
      })
      .catch((error) => {

      });
  }

  async adyen(sessionId, sessionData) {
    const dropinElement = document.createElement('div');
    dropinElement.id = 'dropin-container';

    // Append the container to the DOM
    this.dropinContainerRef.current.appendChild(dropinElement);

    var translations = {
      "fr-FR": {
        "paymentMethods.moreMethodsButton": this.props.i18n.language === 'fr' ? 'Plus de méthodes de paiement' : 'More payment methods',
        "payButton": this.props.i18n.language === 'fr' ? 'Régler' : 'Pay',
        "payButton.redirecting": this.props.i18n.language === 'fr' ? 'Redirection...' : 'Redirecting...',
        "storeDetails": this.props.i18n.language === 'fr' ? 'Économiser pour mon prochain paiement' : 'Save for my next payment',
        "payment.redirecting": this.props.i18n.language === 'fr' ? 'Vous serez redirigé…' : 'You will be redirected...',
        "payment.processing": this.props.i18n.language === 'fr' ? 'Votre paiement est en cours de traitement' : 'Your payment is being processed',
        "creditCard.holderName": this.props.i18n.language === 'fr' ? 'Nom sur la carte' : 'Cardholder\'s Name',
        "creditCard.holderName.placeholder": this.props.i18n.language === 'fr' ? 'Nom Complet' : 'Full Name',
        "creditCard.holderName.invalid": this.props.i18n.language === 'fr' ? 'Nom de titulaire de carte invalide' : 'Invalid cardholder name',
        "creditCard.numberField.title": this.props.i18n.language === 'fr' ? 'Numéro de carte' : 'Card Number',
        "creditCard.numberField.placeholder": this.props.i18n.language === 'fr' ? '1234 5678 9012 3456' : 'Enter card number',
        "creditCard.numberField.invalid": this.props.i18n.language === 'fr' ? 'Numéro de carte invalide' : 'Invalid card number',
        "creditCard.expiryDateField.title": this.props.i18n.language === 'fr' ? 'Date d\'expiration' : 'Expiry Date',
        "creditCard.expiryDateField.placeholder": this.props.i18n.language === 'fr' ? 'MM/YY' : 'MM/YY',
        "creditCard.expiryDateField.invalid": this.props.i18n.language === 'fr' ? 'Date d\'expiration invalide' : 'Invalid expiry date',
        "creditCard.expiryDateField.month": this.props.i18n.language === 'fr' ? 'Mois' : 'Month',
        "creditCard.expiryDateField.month.placeholder": this.props.i18n.language === 'fr' ? 'MM' : 'MM',
        "creditCard.expiryDateField.year.placeholder": this.props.i18n.language === 'fr' ? 'YY' : 'YY',
        "creditCard.expiryDateField.year": this.props.i18n.language === 'fr' ? 'Année' : 'Year',
        "creditCard.cvcField.title": this.props.i18n.language === 'fr' ? 'CRYPTOGRAMME' : 'CVC',
        "creditCard.cvcField.placeholder": this.props.i18n.language === 'fr' ? '123' : '123',
        "creditCard.storeDetailsButton": this.props.i18n.language === 'fr' ? 'Souviens-toi pour la prochaine fois' : 'Remember for next time',
        "creditCard.oneClickVerification.invalidInput.title": this.props.i18n.language === 'fr' ? 'Format CRYPTOGRAMME invalide' : 'Invalid CVC format',
        "creditCard.cvcField.placeholder.4digits": this.props.i18n.language === 'fr' ? '4 chiffres' : '4 digits',
        "creditCard.cvcField.placeholder.3digits": this.props.i18n.language === 'fr' ? '3 chiffres' : '3 digits',
        "installments": this.props.i18n.language === 'fr' ? 'Nombre d\'installations' : 'Number of Installments',
        "installmentOption": this.props.i18n.language === 'fr' ? '%{fois}x %{valeurpartielle}' : '%{times}x %{partialvalue}',
        "sepaDirectDebit.ibanField.invalid": this.props.i18n.language === 'fr' ? 'Numéro de compte invalide' : 'Invalid account number',
        "sepaDirectDebit.nameField.placeholder": this.props.i18n.language === 'fr' ? 'J. Smith' : 'J. Smith',
        "error.title": this.props.i18n.language === 'fr' ? 'Erreur' : 'Error',
        "error.subtitle.redirect": this.props.i18n.language === 'fr' ? 'Échec de la redirection' : 'Redirection failure',
        "error.subtitle.payment": this.props.i18n.language === 'fr' ? 'Paiement échoué' : 'Payment failed',
        "error.subtitle.refused": this.props.i18n.language === 'fr' ? 'Le paiement a été refusé !' : 'Payment was refused!',
        "error.message.unknown": this.props.i18n.language === 'fr' ? 'Le paiement a été refusé !' : 'Payment was refused!',
        "creditCard.success": this.props.i18n.language === 'fr' ? 'Le Paiement a été réussi' : 'Payment was successful',
        "loading": this.props.i18n.language === 'fr' ? 'Chargement…' : 'Loading...',
        "continue": this.props.i18n.language === 'fr' ? 'Continuer' : 'Continue',
        "continueTo": this.props.i18n.language === 'fr' ? 'Continuer à' : 'Continue to',
        "wechatpay.timetopay": this.props.i18n.language === 'fr' ? 'Tu dois payer %@' : 'You must pay %@',
        "afterPay.agreement": this.props.i18n.language === 'fr' ? 'Je suis d\'accord avec le %@ d\'AfterPay' : 'I agree with AfterPay\'s %@',
        "paymentConditions": this.props.i18n.language === 'fr' ? 'conditions de paiement' : 'payment conditions',
        "voucher.readInstructions": this.props.i18n.language === 'fr' ? 'Read instructions' : 'Read instructions',
        "voucher.introduction": this.props.i18n.language === 'fr' ? 'Merci pour votre achat, veuillez utiliser le coupon suivant pour effectuer votre paiement.' : 'Thank you for your purchase, please use the following coupon to make your payment.',
        "voucher.expirationDate": this.props.i18n.language === 'fr' ? 'Date d\'expiration' : 'Expiration Date',
        "voucher.alternativeReference": this.props.i18n.language === 'fr' ? 'Référence alternative' : 'Alternative Reference',
        "voucher.paymentReferenceLabel": this.props.i18n.language === 'fr' ? 'Référence de paiement' : 'Payment Reference',
        "voucher.surcharge": this.props.i18n.language === 'fr' ? 'Incl. %@ surcharge' : 'Incl. %@ surcharge',
        "voucher.introduction.doku": this.props.i18n.language === 'fr' ? 'Merci pour votre achat, veuillez utiliser le coupon suivant pour effectuer votre paiement.' : 'Thank you for your purchase, please use the following coupon to make your payment.',
        "voucher.shopperName": this.props.i18n.language === 'fr' ? 'Shopper Name' : 'Shopper Name',
        "voucher.merchantName": this.props.i18n.language === 'fr' ? 'Merchant' : 'Merchant',
        "voucher.introduction.econtext": this.props.i18n.language === 'fr' ? 'Merci pour votre achat, veuillez utiliser le coupon suivant pour effectuer votre paiement.' : 'Thank you for your purchase, please use the following coupon to make your payment.',
        "error.va.gen.01": this.props.i18n.language === 'fr' ? 'Champ incomplet' : 'Incomplete field',
        "error.va.gen.02": this.props.i18n.language === 'fr' ? 'Champ non valide' : 'Invalid field',
        "error.va.sf-cc-num.01": this.props.i18n.language === 'fr' ? 'Numéro de carte invalide' : 'Invalid card number',
        "error.va.sf-cc-num.02": this.props.i18n.language === 'fr' ? 'Le numéro de carte saisi ne correspond pas à la marque de la carte' : 'The entered card number does not match the card brand',
        "error.va.sf-cc-num.03": this.props.i18n.language === 'fr' ? 'Carte non prise en charge saisie' : 'Unsupported card entered',
        "error.va.sf-cc-dat.01": this.props.i18n.language === 'fr' ? 'Carte trop ancienne' : 'Card too old',
        "error.va.sf-cc-dat.02": this.props.i18n.language === 'fr' ? 'Date trop éloignée dans le futur' : 'Date too far in the future',
        "form.instruction": this.props.i18n.language === 'fr' ? 'Tous les champs sont obligatoires, sauf indication contraire.' : 'All fields are required unless otherwise noted.'
      }
    };


    var configuration = {
      environment: ENVIRONMENT == "PROD" ? Adyen_environment : Adyen_environment_PP,
      clientKey: ENVIRONMENT == "PROD" ? Adyen_clientKey : Adyen_clientKey_PP,
      local: "fr-FR",
      translations: translations,
      analytics: {
        enabled: true // Set to false to not send analytics data to Adyen.
      },
      session: {
        id: sessionId, // Unique identifier for the payment session.
        sessionData: sessionData
      },
      onPaymentCompleted: async (result, component) => {
        var status = "";

        if (result.resultCode == "Authorised")
          status = "1";
        else {
          status = "0";
        }

        const statusElement = document.getElementById("status");
        if (statusElement) {
          statusElement.value = status;
        }
        const errorElement = document.querySelector('.adyen-checkout__status--error');
        if (errorElement) {
          errorElement.style.display = 'none';
        }
        const bookingReference = localStorage.getItem("bookingReference");
        var resp = "{result:'" + JSON.stringify(result) + "'," + "resId:'" + bookingReference + "'}";

        if (status == "1") {
          await this.ValidateResa();
        } else {
          this.setState({ erreurPayement: true });
        }
      },
      onError: (error, component) => {
        console.error(error.name, error.message, error.stack, component);
      },
      // Any payment method specific configuration. Find the configuration specific to each payment method:  https://docs.adyen.com/payment-methods
      // For example, this is 3D Secure configuration for cards:
      paymentMethodsConfiguration: {
        ideal: {
          showImage: true,
        },
        card: {
          hasHolderName: false,
          holderNameRequired: false,
          billingAddressRequired: false
        }
      }
    };

    // Create an instance of AdyenCheckout using the configuration object.
    var checkout;
    try {
      checkout = await AdyenCheckout(configuration);
    } catch (error) {
      console.error('Error initializing Adyen Checkout:', error);
      return;
    }
    // Create an instance of Drop-in and mount it to the container you created.
    if (checkout && checkout.create) {
      if (this.dropinContainerRef.current) {
        var dropinComponent = checkout.create('dropin').mount(dropinElement);
      } else {
        console.error('Container element not found.');
      }
    }
  }

  async goToOptions() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    if (queryString.length != 0) {
      var agent = urlParams.get('agent');
      var origine = urlParams.get('origine');
      var bookingReference = urlParams.get('bookingReference');

      if (agent != null && origine != null) {
        this.state.isCallCenter = true;
      }

      if (bookingReference != null) {
        this.state.isPayement = true;
      }
    }
  }

  /*----tag GTM-----*/
  pushTagGtm(ip) {
    const internal = [
      "176.149.189.93", //IP Unitec Fibre
      "82.64.74.58", //IP Unitec Guest
      "82.229.26.224", //IP Eric
      "78.237.92.155", //IP CJA
      "172.19.0.1",
    ];

    let traffic = internal.indexOf(ip) === -1 ? "externe" : "interne";

    /*  window.dataLayer = window.dataLayer || [];*/
    window.dataLayer.push({
      event: "virtualpageview",
      virtualPath: window.location.pathname + "/paiement",
      page_name: "informations_paiement",
      page_type: "tunnel_paiement",
      category: this.getVehicleType(),
      sub_category_lvl1: this.props.selectedVehicle.cylindree.toString(),
      langage: "fr",
      country: "FR",
      product_name: this.props.selectedVehicle.nomVehicule
        .toLowerCase()
        .replace(/ /g, "_"),
      traffic: traffic,
      agency: this.props.selectedAgency.AgenceCode,
    });
  }

  pushTagGtmEbusiness() {
    /*----tag GTM-----*/
    window.dataLayer.push({
      event: "checkout",
      ecommerce: {
        checkout: {
          actionField: { step: 3, option: "" },
          products: [
            {
              name: this.props.selectedVehicle.nomVehicule
                .toLowerCase()
                .replace(/ /g, "_"),
              id: this.props.selectedVehicle.vehicle.ext_id,
              price: this.props.bookingTotalPrice.toString(),
              brand: this.props.selectedVehicle.vehicle.brand,
              category: this.getVehicleType(),
              variant: this.props.selectedVehicle.cylindree.toString(),
              quantity: 1,
              dimension10: this.props.selectedAgency.AgenceCode,
            },
          ],
        },
      },
    });
  }

  onBrowserBackButtonClick = (step) => {
    this.props.history.go(1);
    this.handleChangeSection(step);
  };

  setBrowserBackButtonClick = (step) => {
    this.props.history.listen((location, action) => {
      if (action === "POP") {
        this.onBrowserBackButtonClick(step);
      }
    });
    //window.addEventListener("popstate", this.onBrowserBackButtonClick.bind(this, step))
  };

  handleChange(type, event) {
    this.setState({ [type]: event.target.value });
  }

  handlePayment(e) {
    const bookingReference = localStorage.getItem("bookingReference");
    e.preventDefault();
    var bookingTotalPrice = this.props.bookingTotalPrice;
    bookingTotalPrice = parseFloat(this.props.bookingPromoPrice) > 0 ? parseFloat(this.props.globalPrice) : bookingTotalPrice;

    if (bookingTotalPrice < 0) {
      bookingTotalPrice = -bookingTotalPrice;
    }

    var transactionId = new Date().valueOf();
    /**
         * <add key="PBX_SITE" value="2910189"/>

         <add key="PBX_RANG" value="01"/>

         <add key="PBX_IDENTIFIANT" value="433273414"/>

         <add key="PBX_URL" value="https://ppps1.paybox.com/PPPS.php"/>

         <add key="PBX_VERSION" value="00104"/>

         <add key="PBX_DEVISE" value="978"/>

         <add key="PBX_CLE" value="J4yMspd4"/>
         */

    const urlOk =
      document.location.protocol +
      "//" +
      document.location.hostname +
      (document.location.port ? ":" + document.location.port : "") +
      (this.props.i18n.language === "fr"
        ? "/paiement-accepte"
        : this.props.t("url_paiementaccepte"));
    const SHOPPINGCART = '<shoppingcart><total><totalQuantity>1</totalQuantity></total></shoppingcart>';
    const BILLING = '<Billing><Address><FirstName>' + this.props.infoConducteur.nom + '</FirstName><LastName>' + this.props.infoConducteur.prenom + '</LastName><Address1>' + ((this.props.infoConducteur.adresse == "" || this.props.infoConducteur.adresse == null) ? PBX_INFO.PBX_ADDRESS : this.props.infoConducteur.adresse) + '</Address1><ZipCode>' + ((this.props.infoConducteur.cp == "" || this.props.infoConducteur.cp == null) ? PBX_INFO.PBX_ZIP : this.props.infoConducteur.cp) + '</ZipCode><City>' + ((this.props.infoConducteur.ville == "" || this.props.infoConducteur.ville == null) ? PBX_INFO.PBX_CITY : this.props.infoConducteur.ville) + '</City><CountryCode>' + PBX_INFO.PBX_COUNTRY + '</CountryCode></Address></Billing>';
    const pbx = {
      SITE: ENVIRONMENT == "PROD" ? PAYBOX_SITE : PAYBOX_SITE_PP,
      RANG: ENVIRONMENT == "PROD" ? PAYBOX_RANG : PAYBOX_RANG_PP,
      IDENTIFIANT: ENVIRONMENT == "PROD" ? PAYBOX_IDENTIFIANT : PAYBOX_IDENTIFIANT_PP,
      TOTAL: Math.round(parseFloat(bookingTotalPrice) * 100), // 10
      DEVISE: "978", // €
      CMD: bookingReference,
      PORTEUR: this.props.infoConducteur.email,
      RETOUR:
        "amount:M;reference:R;authorization_number:A;code:E;call_number:T;transaction_number:S;card_type:C",
      REPONDRE_A: (ENVIRONMENT == "PROD" ? BABEL_V2_URL : BABEL_V2_URL_PP) + "/" + (ENVIRONMENT == "PROD" ? BABEL_V2_URL_VERSION : BABEL_V2_URL_VERSION_PP) + "/transactions",
      ANNULE: document.URL.split("?")[0],
      EFFECTUE: urlOk,
      REFUSE: document.URL.split("?")[0],
      SHOPPINGCART: SHOPPINGCART,
      BILLING: BILLING,
    };
    paybox.createTransaction(
      {
        offer: "system",
        isTest: false, // Optional
        key: ENVIRONMENT == "PROD" ? PAYBOX_KEY : PAYBOX_KEY_PP, // Your private key in HEX format PREPROD
        PBX_: pbx,
      },
      (error, transaction) => {
        if (error === null) {
          document.getElementById("form-sale").innerHTML = transaction.body;
          setTimeout(() => {
            this.paymentForm.current.submit();
          }, 100);
        } else {
          console.log(error);
        }
      }
    );
  }

  async ValidateResa() {
    const bookingReference = localStorage.getItem("bookingReference");
    const bookingTotalPrice = this.props.bookingTotalPrice;

    const formData = new FormData();
    formData.append('amount', bookingTotalPrice * 100)
    formData.append('reference', bookingReference.toString())
    formData.append('authorization_number', "XXXXXX")
    formData.append('code', "00000")
    formData.append('call_number', "XXXXXX")
    formData.append('transaction_number', "XXXXXX")
    formData.append('card_type', "Maestro");

    return await axios({
      method: "POST",
      url: ENVIRONMENT == "PROD" ? SERVER_ADDRESS_TRANSACTIONS : SERVER_ADDRESS_TRANSACTIONS_PP,
      headers: {
        'content-type': 'multipart/form-data'
      },
      data: formData
    })
      .then((response) => {
        if (response.data != null && response.status == 201) {
          document.location.href = (this.props.i18n.language === "fr"
            ? "/paiement-accepte"
            : this.props.t("url_paiementaccepte")) + "?amount=0&reference=" + bookingReference + "&authorization_number=XXXXXX&code=00000&call_number=62869928&transaction_number=00000000&card_type=Maestro";
        }
      })
      .catch((error) => {

      });
  }

  async ValidateResaWithPromo(e) {
    const bookingReference = localStorage.getItem("bookingReference");
    e.preventDefault();
    const globalPrice = this.props.globalPrice;

    const formData = new FormData();
    formData.append('amount', "0")
    formData.append('reference', bookingReference.toString())
    formData.append('authorization_number', "XXXXXX")
    formData.append('code', "00000")
    formData.append('call_number', "XXXXXX")
    formData.append('transaction_number', "XXXXXX")
    formData.append('card_type', "Maestro")

    return await axios({
      method: "POST",
      url: ENVIRONMENT == "PROD" ? SERVER_ADDRESS_TRANSACTIONS : SERVER_ADDRESS_TRANSACTIONS_PP,
      headers: {
        'content-type': 'multipart/form-data'
      },
      data: formData
    })
      .then((response) => {
        if (response.data != null && response.status == 201) {
          return axios({
            method: "POST",
            url: (ENVIRONMENT == "PROD" ? SERVER_ADDRESS_PACK_CADEAU : SERVER_ADDRESS_PACK_CADEAU_PP) + "pack_cadeau_confirmation&amountT=" + this.props.bookingTotalPrice + "&code_promo=" + this.props.code_promo + "&reference=" + bookingReference,
            headers: {
              "Accept-Language": i18n.language === undefined ? "fr" : i18n.language,
              "Content-Type": "application/json",
            },
          })
            .then((response) => {
              console.log(response);
              if (response.data != null && response.data != undefined && response.data != "") {
                document.location.href = "/paiement-accepte?amount=0&reference=" + bookingReference + "&authorization_number=XXXXXX&code=00000&call_number=62869928&transaction_number=00000000&card_type=Maestro";
              }
            })
            .catch((error) => {
              console.log(response);

            });
        }
      })
      .catch((error) => {

      });
    // document.getElementById("form-sale-2").innerHTML = transaction_body;

  }

  handleChangeSection(position) {
    if (position && typeof position === "number") {
      this.props.changeSectionReservation(position);
    } else {
      this.props.changeSectionReservation(6);
    }
  }
  SendPaymentLink() {
    var currnetUrl = window.location.href;
    const bookingReference = localStorage.getItem("bookingReference");
    currnetUrl = currnetUrl.replace("email=", "email=" + encodeURIComponent(this.props.infoConducteur.email));
    currnetUrl = currnetUrl.replace("civilite=", "civilite=" + encodeURIComponent(this.props.infoConducteur.civilite));
    currnetUrl = currnetUrl.replace("firstName=", "firstName=" + encodeURIComponent(this.props.infoConducteur.prenom));
    currnetUrl = currnetUrl.replace("lastName=", "lastName=" + encodeURIComponent(this.props.infoConducteur.nom));
    currnetUrl = currnetUrl.replace("tel=", "tel=" + this.props.infoConducteur.tel);
    currnetUrl = currnetUrl.replace("age=", "age=" + this.props.infoConducteur.age);
    currnetUrl = currnetUrl.replace("permis=", "permis=" + this.props.infoConducteur.permis);
    currnetUrl = currnetUrl.replace("adresse=", "adresse=" + encodeURIComponent(this.props.infoConducteur.adresse));
    currnetUrl = currnetUrl.replace("cp=", "cp=" + encodeURIComponent(this.props.infoConducteur.cp));
    currnetUrl = currnetUrl.replace("ville=", "ville=" + encodeURIComponent(this.props.infoConducteur.ville));
    currnetUrl = currnetUrl + "&bookingReference=" + bookingReference;

    const key = 42;
    var priceOption1 = this.encryptNumberToString(this.props.priceOption1, key);
    var priceOption2 = this.encryptNumberToString(this.props.priceOption2, key);
    var priceOption3 = this.encryptNumberToString(this.props.priceOption3, key);
    currnetUrl = currnetUrl + "&option1=" + encodeURIComponent(priceOption1);
    currnetUrl = currnetUrl + "&option2=" + encodeURIComponent(priceOption2);
    currnetUrl = currnetUrl + "&option3=" + encodeURIComponent(priceOption3);

    var message = (this.props.i18n.language === "fr" ? "Yamaha, Bonjour ! Votre réservation est presque terminée. Pour la finaliser, vous avez 10 minutes pour procéder à son paiement grâce au lien suivant : " : "Yamaha, Hello! Your reservation is almost complete. To finalize it, you have 10 minutes to make payment using the following link : ") + currnetUrl.toString();
    var requestBody = "{\"oadc\": \"YAMAHA\", \"mobile\": \"" + this.props.infoConducteur.tel + "\", \"message\": \"" + message + "\"}";
    return axios({
      method: "POST",
      url: (ENVIRONMENT == "PROD" ? SERVER_ADDRESS_80 : SERVER_ADDRESS_80_PP) + "phone/SendSMSCallcenter",
      headers: {
        "Content-Type": "application/json",
      },
      data: requestBody,
    })
      .then((response) => {
        if (response.data.response == "1") {
          this.setState({ send_sms_ok: true });
        } else {
          this.setState({ send_sms_not_ok: true });
        }
      })
      .catch((error) => {
        this.setState({ send_sms_not_ok: true });
      });
  }

  encryptNumberToString(number, key) {
    const strNumber = number.toString();
    let encrypted = '';
    for (let i = 0; i < strNumber.length; i++) {
      encrypted += String.fromCharCode(strNumber.charCodeAt(i) ^ key);
    }
    return encrypted;
  }

  showCrypto() {
    this.setState({ showCrypto: !this.state.showCrypto });
  }

  getVehicleType() {
    const typeCode = this.props.selectedVehicle.type;

    switch (typeCode) {
      case "sco":
        return "Scooter";
      case "mot":
        return "Moto";
    }
  }

  close_sms() {
    this.setState({ send_sms_ok: false });
    this.setState({ send_sms_not_ok: false });
  }

  render() {
    let isTablet = window.innerWidth < 768;
    return (
      <Container id="allVehicles">
        <Row>
          <SideInfoAgency isTablet={isTablet ? "none" : "block"} />

          <Col md="8">
            <h1>
              <Trans i18nKey="paiement">Paiement</Trans>
            </h1>

            {this.state.isCallCenter && this.state.send_sms_ok && (
              <div id="send_sms_ok">
                <img class="close-sms" onClick={this.close_sms.bind(this)} src={arrowClose} alt="arrow-close" />
                <h3>{this.props.t("Félicitation")}</h3>
                <p>{this.props.t("Le lien de paiement a bien été envoyé par sms au client")}</p>
              </div>
            )}

            {this.state.isCallCenter && this.state.send_sms_not_ok && (
              <div id="send_sms_not_ok">
                <img class="close-sms" onClick={this.close_sms.bind(this)} src={arrowClose} alt="arrow-close" />
                <h3>{this.props.t("Dommage")}</h3>
                <p>{this.props.t("Le lien de paiement n'a pas été envoyé par sms au client")}</p>
              </div>
            )}

            {((!this.state.isCallCenter) || (this.state.isPayement && this.state.isCallCenter)) &&
              <div id="sectionBlue">
                <p>
                  <Trans i18nKey="attention_la_carte">
                    Attention, la carte qui servira à la prise de caution en
                    agence devra être la même que celle utilisée dans cette étape
                    de paiement.
                  </Trans>
                </p>
              </div>}

            {this.props.globalPrice == 0 ?
              <form
                method="POST"
                onSubmit={this.ValidateResaWithPromo}
              >

                <div id="form-sale-2"></div>
                <div className="relativestep" id="etaperesbtns">
                  <Button
                    className="roundBtn mt-0"
                    color="primary"
                    size="lg"
                    block
                  >
                    <Trans i18nKey="Valider">Valider</Trans> ⟶
                  </Button>
                  <Button
                    className="roundBtn mt-0 mr-3"
                    onClick={this.handleChangeSection.bind(this, 4)}
                    color="primary"
                    size="lg"
                    block
                  >
                    ⟵ <Trans i18nKey="etape_precedente">Etape précédente</Trans>
                  </Button>
                </div>
              </form>
              :
              <div>
                <div id="dropin">
                  <div ref={this.dropinContainerRef}>
                  </div>
                </div>


                {
                  this.state.erreurPayement &&
                  <div className="alert alert-danger mt-4" role="alert">
                    {this.props.t("Le paiement a été refusé")}
                  </div>
                }

                <div className="relativestep" id="etaperesbtns">
                  {(!this.state.isPayement && this.state.isCallCenter) && <Button
                    className="roundBtn mt-4 ml-2"
                    color="primary"
                    size="lg"
                    block
                    style={{ width: "fit-content" }}
                    onClick={this.SendPaymentLink.bind(this)}
                  >
                    <Trans i18nKey="Envoyer-un-lien-de-paiement">Envoyer un lien de paiement</Trans> ⟶
                  </Button>}

                  {!this.state.isPayement && <Button
                    className="roundBtn mt-4"
                    onClick={this.handleChangeSection.bind(this, 4)}
                    color="primary"
                    size="lg"
                    block
                  >
                    ⟵ <Trans i18nKey="etape_precedente">Etape précédente</Trans>
                  </Button>}
                </div>
              </div>
            }

          </Col>
          <Col md="3" className="imgCB"></Col>
          <SideInfoAgency isTablet={isTablet ? "block" : "none"} />
        </Row>
      </Container>
    );
  }
}

function mapStateToProps(state) {
  return {
    bookingTotalPrice: bookingTotalPriceSelector(state),
    code_promo: state.reducer.code_promo,
    globalPrice: state.reducer.globalPrice,
    bookingPromoPrice: state.reducer.promoPrice,
    startDateUnformated: state.reducer.startDateUnformated,
    endDateUnformated: state.reducer.endDateUnformated,
    selectedAgency: state.reducer.selectedAgency,
    startDate: state.reducer.startDate,
    endDate: state.reducer.endDate,
    cityPosition: state.reducer.cityPosition,
    allVehiclesAvailable: state.reducer.allVehiclesAvailable,
    selectedVehicle: state.reducer.selectedVehicle,
    addedKm: state.reducer.addedKm,
    infoConducteur: state.reducer.infoConducteur,
    priceOption1: state.reducer.priceOption1,
    priceOption2: state.reducer.priceOption2,
    priceOption3: state.reducer.priceOption3,
    reference: state.reducer.reference,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      changeSectionReservation,
    },
    dispatch
  );
}

const exportComponent = withRouter((props) => <Paiement {...props} />);
export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(exportComponent)
);

// <Row>
//     <Col><Input placeholder="Code Promo" /></Col>
// </Row>
