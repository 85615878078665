
const initialState = {
    startDateUnformated: '',
    endDateUnformated: '',
    startDate: undefined,
    endDate: undefined,
    startHour : '10',
    endHour: '17',
    changeMonth: undefined,

    cityPosition: undefined,
    selectedAgency: undefined,
    horaireAgency : [],
    noVehicleMessage: 'Recherche en cours...',

    typeVehicles: [],

    allVehiclesAvailable: [],
    savedFilter: ['A', 'A2*', 'A2', 'A1', 'B*', 'B'],
    vehicleListHasBeenUpdated: 0,

    selectedVehicle: undefined,
    addedKm: 0,
    priceOption1: 0,
    priceOption2: 0,
    priceOption3: 0,

    infoConducteur: {},

    sectionReservation: 2,

    globalPrice: 0,
    promoPrice: 0,
    code_promo: "",

    reference: null
}

export default function (state = initialState, action) {
    switch (action.type) {

        case 'CHANGE_SECTION_RESERVATION':
            return { ...state, sectionReservation: action.section }

        case 'SAVE_UNFORMATED_DATE':
            let key = action.typeDate + 'Unformated'
            return { ...state, [key]: action.date }

        case 'CHANGE_MONTH':
            return { ...state, changeMonth: action.newMonth }

        case 'SAVE_SEARCH_DATE':
            return { ...state, [action.typeDate]: action.date }

        case 'SAVE_SEARCH_HOUR':
            let keySave = action.typeHour + 'Date'
            let keyHour = action.typeHour + 'Hour'
            let getDay = /.+?T/g.exec(state[keySave])
            let finalDate = getDay[0] + action.hour + ':00:00'
            return { ...state, [keySave]: finalDate, [keyHour]: action.hour }

        case 'SAVE_CITY_POSITION':
            return { ...state, cityPosition: action.latLng }

        case 'SAVE_SELECTED_AGENCY':
            return { ...state, selectedAgency: action.allInfo }

        case 'SAVE_START_DATE':
            return { ...state, startDate: action.allInfo }
        
        case 'SAVE_END_DATE':
            return { ...state, endDate: action.allInfo }

        case 'SAVE_START_DATE_UNFORMATED':
            return { ...state, startDateUnformated: action.allInfo }
        
        case 'SAVE_END_DATE_UNFORMATED':
            return { ...state, endDateUnformated: action.allInfo }

        case 'SAVE_ACTUALISER_STATE':
            return { ...state, actualiserState: action.allInfo }
    
            case 'HORAIRE_AGENCY':
            return{ ...state, horaireAgency: action.horaire}

        case 'MANAGE_MESSAGE_NO_VEHICLE':
            return { ...state, noVehicleMessage: action.msg }

        case 'SAVE_ALL_TYPE_VEHICLES':
            return { ...state, typeVehicles: action.typeVehicles }

        case 'SAVE_ALL_VEHICLES_AVAILABLE':
            if (action.vehiclesAvailable) {
                return { ...state, allVehiclesAvailable: action.vehiclesAvailable }
            } else {
                return { ...state }
            }

        case 'SAVE_FILTERED_TAGS':
            return{...state, savedFilter: action.tags}
        
        case 'SAVE_DEVIS_STATUS':
        return{...state, devisStatus: action.status}

        case 'UPDATE_LIST_VEHICLE':
            return { ...state, vehicleListHasBeenUpdated: state.vehicleListHasBeenUpdated + 1 }

        case 'SAVE_ALL_INFO_VEHICLE':
            return { ...state, selectedVehicle: action.allInfo }

        case 'SAVE_ADDED_KM':
            return { ...state, addedKm: action.addedKm }

        case 'SAVE_PRICE_OPTION':
            return { ...state, [action.option]: action.price }

        case 'SAVE_CONDUCTEUR_INFO':
            return { ...state, infoConducteur: action.infoConducteur }


        case 'CLEAN':
            return { ...initialState }

        case 'SET_GLOBAL_PRICE':
            return {
                ...state, globalPrice: action.globalPrice
            }
            case 'SET_PROMO_PRICE':
                return {
                    ...state, promoPrice: action.promoPrice
                }
            case 'SET_PROMO_CODE':
                return {
                    ...state, code_promo: action.code_promo
                }
    
        case 'CHANGE_WITH_LOCAL_STORAGE':
            return {
                ...state,
                promoPrice: action.newStore.promoPrice,
                globalPrice: action.newStore.globalPrice,
                code_promo: action.newStore.code_promo,
                startDateUnformated: action.newStore.startDateUnformated,
                endDateUnformated: action.newStore.endDateUnformated,
                startDate: action.newStore.startDate,
                endDate: action.newStore.endDate,
                //changeMonth: action.newStore.changeMonth,

                cityPosition: action.newStore.cityPosition,
                selectedAgency: action.newStore.selectedAgency,
                noVehicleMessage: action.newStore.noVehicleMessage,

                allVehiclesAvailable: action.newStore.allVehiclesAvailable,
                vehicleListHasBeenUpdated: action.newStore.vehicleListHasBeenUpdated,

                selectedVehicle: action.newStore.selectedVehicle,
                addedKm: action.newStore.addedKm,
                priceOption1: action.newStore.priceOption1,
                priceOption2: action.newStore.priceOption2,
                priceOption3: action.newStore.priceOption3,

                infoConducteur: action.newStore.infoConducteur,

                sectionReservation: action.newStore.sectionReservation,
            }
        
        case 'SAVE_REFERENCE':
            return {...state, reference: action.reference}

        default:
            return state

    }
}