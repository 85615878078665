import React, { Component } from 'react';
//COMPONENTS
import Header from '../staticComponents/header'
import Footer from '../staticComponents/footer'
import VehicleDetails from '../modules/vehicleDetails'

class vehicleDetails extends Component {

  render() {
    return (
      <div>
        <Header />
         <VehicleDetails
            type = {this.props.match.params.type}
            nom = {this.props.match.params.nom}
            lang = {this.props.match.params.lang}/>
        <Footer />
      </div>
    );
  }
}

export default vehicleDetails;
