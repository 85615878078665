import React from "react";
import {Trans} from "react-i18next";

export default {
    /*--------------cookies--------------*/
    'a_propos_cookies':'About cookies',
    'cookies_p1':'When you browse our website, some informations may be registered, or read , on your terminal, ' +
        'depending on your choices. These informations allox us to propose optimized browsing and custom content',
    'cookies_pub_perso':'Do you accept cookies deposit and reading in order to analyze your interests and propose custom ads?',
    'cookies_mesure_audience':'Do you accept cookies deposit and reading in order to analyze your browsing andmeasure our website audience?',
    'oui':'Yes',
    'non':'No',
    'valider':'Validate',
    /*---------Home Page----------*/
    'Location':'',
    ' ':' rental ', //laisser 2 espaces sinon traduit les espaces vides
    'Accueil': 'Home',
    'scooters': 'Scooters​',
    'motos': 'Motos',
    'Scooters Yamaha à louer':'Yamaha scooters for rent',
    'Motos Yamaha à louer':'Yamaha motorcycles for rent',
    'accessoires': 'Accessories and Security',
    'exigez': 'Motorcycles and scooters Yamaha rental…',
    'exigezh2': 'Mobility with free spirit',
    'textNoel1': 'Offer your loved ones a Yamaha',
    'textNoel2': 'Rent gift card for a short-term rental',
    'baseLine': 'The Holiday Kit includes a bath towel, a cap and a pair of sunglasses.',
    'baseLineh2': 'All Holiday Kits are offered free of charge for any rental of min. 3 days or more',
    'baseLineh3': 'Offer valid until August 31, 2020',
    'Ville de départ': 'Departure City',// not a variable
    'Choisir une agence autour de ': 'Choose an agency around ',// not a variable
    'Départ': 'Departure',// not a variable
    'Retour': 'Return',
    'cookies': 'By carrying on browsing, you accept cookies use to propose to you contents and targeted ads suited to ' +
        'your centers of interest and get visit statistics to enhance our website. Clik here to know more and personalize cookies.',
    'Autoriser les cookies': 'Allow cookies',
    'cliquez ici':'click here',

    'chercher': 'Search',
    'photo':
        'Non-contractual photo, version and colour may differ depending on availability in the agency.',
    'a_partir': 'from ',
    'jour': '/ day',
    'aucune_agence':'no agency is located near this city (radius: 50 km)',

    'Ce site n’est pas optimisé pour être utilisé avec Internet Explorer. Nous vous invitons à accéder à notre site depuis le navigateur Edge ou tout autre navigateur (Chrome, Safari, Firefox). Merci de votre compréhension.':
        'This website is not optimized for Internet Explorer, please use Edge or any other web browser ' +
        '(Chrome, Safari, Firefox). Thanks for your understanding',
    'title-find-agency':'Find a Yamaha Rent scooter and motorcycle rental agency',
    'text-search-agency':'Yamaha Rent is the rental agreement for scooters and motorcycles offered by Yamaha Motor and its network of authorized agencies. Yamaha Rent it is the guarantee to able to use recent and maintained vehicles, for your peace of mind.',
    'cta-rechercher-une-agence':'Find a rental agency',
    /*-------no variables--------*/
    'Tous nos scooters en location': 'All scooters for rent',
    'Toutes nos motos en location': 'All motorcycles for rent',
    'Tous nos vélos électriques en location': 'All electric bikes for rent',
    /*------------end------------*/

    'retrouvez': 'Find the Yamaha Rent dealer near you',

    'titre_offre_you': 'The service offer "YOU"',
    'texte_you': 'Yamaha Offers You brings together a full range of services related to purchasing and owning a Yamaha. ' +
        'These offers allow us to guarantee each customer a high level of service so that you always have an enjoyable ' +
        'experience whenever you purchase a Yamaha product. "You" services make the purchase of every Yamaha product ' +
        'more accessible - and Yamaha owners can enjoy and benefit from for the peace of mind that comes with every "YOU" ' +
        'product.  Take a closer look at the range of "You" services and you\'ll discover that these services go far ' +
        'beyond simply buying a Yamaha, it\'s the beginning of a long and lasting relationship.',

    'location_par_yamaha': 'Scooters and motorcycles rental by Yamaha',
    'location_paragraphe_1': 'Yamaha Rent is a short-term sccoters and motorcycles rental agreement that is part of the "YOU" ',
    'location_paragraphe_1bis':' services offered by our brand, which offers our customers a first-class product and service experience. Whether you\'re looking for a two / three wheeler bike fo rent to use as a get away with friends for the weekend or simply looking for a fast, safe and convenient means of transport for urban travel, Yamaha Rent is the ideal solution that meets all your requirements for scooters and motorcycles short term rental.',

    'location_paragraphe_2': ' Through a network of authorized Yamaha Rent agencies, it is the guarantee to able to use recent and maintained vehicles, for your peace of mind.​',

    'location_paragraphe_3': 'Yamaha Rent agencies',

    'location_paragraphe_4': ' are at your disposal to advise you and offer you the scooter or motorcycle and accessories​',

    'location_paragraphe_5':' necessary to ensure a smooth running trip.',

    'retrouvez_accessoires': 'Find the list of motorcycle accessories and accessories for the driver at your Yamaha Rent agency.',
    /*---------responsive------------*/
    'cta_appeler':'Call',
    'Appeler':'Call',

    /*--------------footer no variables-----------------*/

    'Conditions de permis': 'Conditions of license',
    'Mentions légales': 'Legal Notice',
    'Politique de protection des données': 'Data Protection Policy',
    'Conditions Générales': 'General conditions',
    'Notice d\'assurance': 'Insurance note',

    /*--------Accessoires et sécurité---------*/
    'Location accessoires motos et scooters – Yamaha Rent':'Scooter and motorcycle accessories rental – Yamaha Rent',
    'Pour garder intact votre plaisir de piloter une moto ou un scooter Yamaha, portez toujours un casque, des gants et les accessoires de sécurité adéquats. Dans votre agence Yamaha rent vous pourrez louer ces accessoires moto et scooter.':
        'Always ensure when riding that you wear a helmet, gloves and proper security accessories. In your Yama Rent agency, you can rent these accesories for motorcycle ans scooter.',
    'accessoires_titre': 'Motorcycle ans scooter accessories rental​',
    'pour_garder_intact': 'Always ensure when riding that you wear a helmet, eye protection, gloves and proper clothing. ' +
        'Yamaha encourages you to ride with caution and respect other road users, as well as the environment and adhere ' +
        'to road safety regulations. Road safety concerns everyone and is everyones responsibility.  Riding responsibly ' +
        'and safely will preserve your riding experience.',

    'besoins_specifiques': 'Specific requirements ?',

    'n_hesitez_pas': 'Don\'t hesitate to contact your nearest ',
    'agence-yamaha':'Yamaha Rental agency',
    'afin-de-connaitre':' for more information about the equipment and accessories available for rent.',

    /*--------pages nos scooters/nos motos---------*/
    'location_de': 'Yamaha\'s ',
    'location_Yamaha': ' rental',
    'location_ebike': 'Yamaha electric bike rental',
    'location_ebike_desc': 'Urban and sport electric bike',
    /*----------vehicule details------------*/
    'Location ':'',
    'just-for-uk':' rental',
    ' Yamaha ':' rental Yamaha ',
    'location ':'Yamaha ',
    ' yamaha ':' rental ',
    'Yamaha Rent vous permet de louer un(e) ': 'Yamaha Rent offers you to rent ',
    ' pour un jour, un week-end, une semaine. Yamaha Rent, c’est la garantie d’utiliser des motos et scooters récents et entretenus. Choisissez la location courte durée Yamaha Rent pour essayer le/la ':
    ' for a day, a weekend or a week. Yamaha Rent it is the guarantee to able to use recent and maintained vehicles. Choose short term rental to try the ',
    'retour_vehicule': 'Back to our ',
    'permis': '',
    'necessaire': '- licence required',
    'age_mini': 'years required and 3 years license',
    'age_mini_2': 'years required and 2 years license',
    'Caractéristiques ': '',
    'for-uk-only':' characteristics',

    'cylindree': 'Displacement',
    'transmission': 'Transmission',
    'conso': 'Consumption',
    'puissance_max': 'Max power',
    'ht_selle': 'Seat height',
    'garde_sol': 'Ground clearance',
    'poids': 'Total weight',
    'capacite_reservoir': 'Tank capacity',
    'descriptif': 'Descriptive',
    'optez-pour':'Choose the ',
    'internal-link-back-home':'short-term rental of scooters / motorcycles',
    'avec-yam':' with Yamaha Rent',

    /*-------------tunnel------------------*/
    /*------reservation listavailableVehicles--------*/
    'ferme':'closed',
    'Recherche en cours...':'Currently researching ...',
    'recherche_en_cours':'currently researching ...',
    'Aucun véhicule ne correspond à ces critères de recherche':'No vehicle found with these criteria',//not a vairaible
'En cas de sinistre vous devrez acquitter intégralement le montant de la franchise':'In teh event of a claim, ' +
    'you will have to pay full franchise amount',//not a variable
    'Attention, le port d’une paire de gants homologués est obligatoire':'Warning, ' +
        'wearing approved gloves is mandatory',//not a variable
    'Attention, le port d’un casque homologué est obligatoire':'Warning, wearing approved ' +
        'helmet is mandatory',//not a variable
    'Nos services sont momentanément indisponibles. Veuillez renouveler votre réservation ultérieurement. Merci.':'Our ' +
        'services are temporarly unavailable. Please try later',//not a variable


    /*------step reservations----------*/
    'options': 'Vehicle options',
    'conducteur': 'Driver\'s information',
    'paiement': 'Payment',
    'recap': 'Summary',

    /*--------choix du véhicule------------*/
    'Aucun véhicule disponible': 'No vehicles available',//not a variable

    'Prix': 'Price',//not a variable
    'Cylindrée': 'Displacement',//not a variable
    'croissant': 'Increasing',//not a variable
    'décroissant': 'Decreasing',//not a variable
    ' résultat(s)': ' result(s)',//not a variable
    'choix_vehicule': 'Vehicle choice',
    'dates_reservation': 'Booking dates',
    'actualiser': 'Refresh',
    'horaires_agence': 'Opening hours',
    'lundi': 'Monday',
    'mardi': 'Tuesday',
    'mercredi': 'Wednesday',
    'jeudi': 'Thursday',
    'vendredi': 'Friday',
    'samedi': 'Saturday',
    'dimanche': 'Sunday',
    'agences_proximite': 'Local agencies',
    'en_cas_de_permis': 'If you have a foreign driving license, please contact your Yamaha Rent agency ' +
        'to check your renting',
    'permis_A': 'Driving license A',
    'permis_A2': 'Driving license A2',
    'permis_A1': 'Driving license A1',
    'permis_B1': 'Driving license B*',
    'permis_B': 'Driving license B',
    'permis_AM': 'Driving license AM',
    '2_ans_permis': '* 2 years driving license and 7 hours training',
    'continuer': 'Next',
    'appliquer': 'Apply',
    'details': 'Details',
    'solde_promo': 'Your balance is insufficient.',
    'promo_incorrect': 'This code does not correspond to any active gift card. Was it entered correctly?',
    'Code cadeau': 'Gift code',

    /*------------options vehicules-------------*/
    'forfait_km_base': 'Basic mileage package: ',
    'consulter_notice': 'To consult insurance notice ',
    'ici': 'click here',
    'prix_total': 'Total price',
    'km_supp': 'Extra kilometers',
    'etape_precedente': 'Previous',
    'Louez votre ': 'Rent your ', //not a variable
    ', pour 1 jour, 1 week-end, 1 semaine. Yamaha Rent, c’est la garantie d’utiliser des véhicules récents et entretenus, pour partir l’esprit libre.':
        ' for 1 day, one weekend, 1 month. Yamaha Rent it is the guarantee to be able to use recent ' +
        'and maintained vehicles, for your peace of mind.',//not a variable
    'Yamaha Rent – Location ':'Yamaha Rent- Short term ' ,//not a variable
    ' courte durée':' rental',


    /*---------reservation conducteur-----------*/
    'infos_manquantes': 'Missing information in the form',
    'champ_obligatoire': 'Field required',
    'accept_RGPD': 'Please accept the General Protection Data Regulation',
    'accept_CGL': 'Please accept the General Renting Conditions',
    'format d\'adresse email invalide': 'invalid mail address format',
    'format de numéro de téléphone invalide': 'Invalid phone number format',
    'champs_marques': 'Fields with a * are required',
    'Civilité*': 'Title*', // not a variable
    'Mme': 'Mrs',// not a variable
    'M': 'Mr',// not a variable
    'Nom*': 'Last name',// not a variable
    'Prénom*': 'First name',// not a variable
    'Adresse': 'Address',// not a variable
    'Code Postal': 'Zip code',// not a variable
    'Ville': 'City',// not a variable
    'Email*': 'Mail*',
    'Téléphone*': 'Phone*',
    'plus de 23 ans et 3 ans de permis': 'over 23 years and 3 years driving license',
    '23 ans et 2 ans de permis (possible permis B + 7h formation)' : '23 years old and 2 years of license (possible license B + 7h training)',
    '23 ans et 2 ans de permis':'23 years old and 2 years license',
    '25 ans et 2 ans de permis':'25 years old and 2 years license',
    'plus de 25 ans et 3 ans de permis': 'over 25 years and 3 years driving license',
    'B (2 ans de permis et 7h de formation)': 'B (2 years driving license and 7 hours training)',
    'Permis*': 'Driving license*',
    'j_accepte': 'I accept ',
    'conditions': 'the general terms and conditions of booking, payment and renting',
    'je_reconnais': 'I acknowledge that the information given on this form concerning me is mandatory and collected by ' +
        'YAMAHA MOTOR EUROPE in connection with a motorcycle rental and the commercial relationship that may result. ' +
        'In accordance with the European regulation n ° 2016/679, the General Regulation on the Protection of Personal ' +
        'Data of 14 April 2016, you have the right of access,rectification, portability, opposition erasing personal ' +
        'data about you at any time either: By filling in the online form dedicated to the expression of Rights at ' +
        'the following address: ',
    'form_exercice': 'Exercise of rights form',
    'contact_DPO': ', either by contacting the DPO at the following address: ',
    'plus_infos': 'For more information, please consult the ',
    'politique': ' Protection Policy for Data',
    'ma_reservation': 'My reservation',
    'total_commande': 'Total order',
    'location': 'Rental',
    'forfait': '(package)',
    'supplementaires': 'additional',
    'pack_zen': 'Zen pack',
    'pack_gants': ' Gloves pack',
    'pack_casque': ' Helmet pack',
    'attention_la_carte': 'Warning, for the security deposit in agency, you will have to use the same card as used for the payment',
    'payer': 'Pay',

    /*---------recap commande---------*/
    'recap_commande': 'Order summary',
    'reservation_confirmee': 'Your booking is confirmed',
    'votre_paiement': 'Your payment of ',
    'validee': ' has been confirmed',
    'email_confirm': 'A summary mail has been sent to you at the adress: ',
    'doc_a_fournir': 'Documents to  provide to take the vehicle',
    'piece_id': 'ID',
    'permis_formation': 'Driving license (or training certicate for renting a 125',
    'justif_dom': 'Under 3 months proof of adress',
    'cb': 'Bank card',
    'depart': 'Departure',
    'retour': 'Return',
    'jusqu_a': 'Up to',
    'rachat_franchise': 'Partial surrender of franchise',
    'forfait_km': 'Kilometric package ',
    'dont': ' (within ',
    'kms_offerts': ' included',
    'un_depot_de_garantie': 'A ',
    'vous_sera_demande':'security deposit will be charged in agency',
    'Pack zen': 'Zen pack',// not a variable
    'Pack Gants': 'Gloves pack',// not a variable
    'Pack Casque': 'Helmet pack',// not a variable

    /*------------Vos droits--------------*/
    'formulaire_droit': 'Rights exercise form',
    'champs_obligatoire': '* required fields',
    'Nom *': 'Last name *',//not a variable
    'Prénom *': 'First name *',//not a variable
    'Email *': 'Mail *',//not a variable
    'Adresse *': 'Adress *',//not a variable
    'Code Postal *': 'Zip code *',//not a variable
    'Ville *': 'City *',//not a variable
    'Pays *': 'Country *',//not a variable
    'Le champ est obligatoire': 'The field is required',//not a variable
    'Le code postal saisi est invalide': 'The zip code entered is invalid',//not a variable
    'La ville saisie est invalide': 'The entered city is invalid',//not a variable
    'L\'email saisi est invalide': 'The entered mail is invalid',//not a variable
    'valoir_droit': 'You wish to assert the right',
    'plusieurs_choix': '(serveral choices)',
    'donnees_personnelles': 'Access to your personal data',
    'rectification_donnees': 'Correction of your personal data',
    'suppression_donnees': 'Deleting your personal data',
    'portabilite_donnees': 'Portability of your personal data',
    'limitation_donnees': 'Limitation of processing of your personal data',
    'vous_etes': 'You are:',
    'candidat': 'Candidate',
    'ancien_salarie': 'Former employee',
    'client': 'Customer',
    'fournisseur': 'Provider',
    'autre': 'Other',
    'activite_entite': 'Activity / Entity concerned',
    'Activité concernée': 'Activity concerned',//not a variable
    'Informations Complémentaires': 'Further information',//not a variable
    'traiter_demande': 'In order to process your request, please provide us with a copy of your map identity or passport. *',
    'Valider': 'Validate',
    'consulter_information': 'For more information, please consult the',
    'protection_donnees': ' Data Protection Policy',
    "Choisir un fichier":"Choose a file",//not a variable

    /*---------------SEO NO VARIABLES-----------------*/
    'Yamaha Rent est l’offre de location de motos et scooters proposée par Yamaha Motor et son réseau d agences de location agréées. Yamaha Rent, c’est la garantie de louer des motos et scooters récents et entretenus, pour partir l’esprit libre !':
        'Yamaha Rent is the rental agreement for scooters and motorcycles offered by Yamaha Motor and its network of authorized agencies. Yamaha Rent it is the guarantee to able to use recent and maintained vehicles, for your peace of mind.',
    'Location Moto et scooter Yamaha - Yamaha Rent Officiel': 'Yamaha scooters and motorcycles rental- Yamaha Rent',
    'Location scooters Yamaha – Location courte durée – Yamaha Rent': 'Yamaha\'s scooters rental - Short-term rental offer - Yamaha rent',
    'Louez un scooter Yamaha pour 1 jour, 1 week-end, 1 semaine, 1 mois, directement dans votre agence Yamaha Rent. Location de scooters 2 ou 3 roues et réservation en ligne 100% sécurisée. Découvrez les gammes de scooters Yamaha TMax, XMax, NMax. Yamaha Rent, c’est la garantie d’utiliser des scooters récents et entretenus, pour partir l’esprit libre.':
        'Rent a Yamaha scooter' +
        ' for 1 day, one weekend, 1 month, directly in your agency Yamaha Rent. Two / three wheeler sccoter rental and 100% secure ' +
        'online booking. Discover the range of scooters TMax, XMax. Yamaha Rent it is the guarantee to be able to use recent and ' +
        'maintained vehicles, for your peace of mind.',
    'Location motos Yamaha – Location courte durée – Yamaha Rent': 'Yamaha\'s motos rental - Short-term rental offer - Yamaha rent',
    'Louez une moto Yamaha pour 1 jour, 1 week-end, 1 semaine, 1 mois, directement dans votre agence Yamaha Rent. Location de motos et réservation en ligne 100% sécurisée. Yamaha Rent, c’est la garantie d’utiliser des scooters récents et entretenus, pour partir l’esprit libre.':
        'Rent a Yamaha moto' +
        ' for 1 day, one weekend, 1 month, directly in your agency Yamaha Rent. Two / three wheeler sccoter rental and 100% secure ' +
        'online booking. Discover the range of scooters TMax, XMax. Yamaha Rent it is the guarantee to be able to use recent and ' +
        'maintained vehicles, for your peace of mind.',

    'Votre agence de location de motos et scooter Yamaha Rent ':'Your scooter and motorcycle rental agency Yamaha Rent ',
    ' vous propose toute la gamme récente de scooters et motos Yamaha en location courte durée. ​Yamaha Rent à ':' offers you the whole recent range of Yamaha scooters and motorcycles for short rental. Yamaha Rent in ',
    ' est la solution de location de moto ou scooter idéale qui répond à tous vos besoins pour vous déplacer dans ':' is the perfect solution for scooter or motorcycle rental and meets all your requirements for urban travel in ',
    ' ou partir à l’aventure.':' or fot a smooth running trip.',

    /*---------------Routing-----------------*/
    'url_etapereservation': '/en/stepreservation',
    'url_scooters': '/en/vehicles/scooters',
    'url_motos': '/en/vehicles/motos',
    'url_solutions': '/en/solutions',
    'url_agencies':'/en/rental-agencies',
    'url_ebikes':'/en/e-bikes',
    'url_paiementaccepte': '/en/payment-accepted',
    'url_cookieProtectionDonnees':'/en/data-protection-policy',
    'url_vosDroits':'/en/your-rights',

    /*------------reservation recap-------------*/
    'Merci de vérifier le numéro de votre carte de crédit':'Please verify your credit card number',//not a variable
    'Vous allez être redirigé(e), merci de patienter...':'You will be redirected, please wait ...',

    /*----------search dealer locator------------*/
    'Location moto et scooter ':'Scooter and motorcycle rental ',
    'Agences de location moto et scooter – Yamaha Rent':'Scooters and motorcycles rental agencies – Yamaha Rent',
    'Trouvez l’agence de location de moto et scooter Yamaha la plus proche de chez vous. Votre agence de location de motos et scooter agréée Yamaha Rent vous propose toute la gamme récente de scooters et motos Yamaha en location courte durée.':
    'Find the nearest Yamaha motorcycle and scooter rental agency near you. Your scooter and motorcycle rental agency approved Yamaha Rent offers you the whole recent range of Yamaha scooters and motorcycles for short rental',
    'scooters_meta_desc':'Rent a 2 or 3 wheel scooter directly from your Yamaha Rent agency. Discover the ranges of Yamaha TMax, XMax, NMax scooters.',
    'scooters_desc1':'Rent a Yamaha electic bike for a short period of time: a day, a weekend, a week or more... via our 100% secure online reservation or directly at your Yamaha Rent agency. Discover Yamaha electic bikes for hire and choose a model to suit your needs: the BOOSTER for fun, the CROSSCORE for versatility, the WABASH or MORO for performance... Get away from it all with YAMAHA RENT and discover our new range of electrically assisted bikes.',
    'scooters_desc2':'Discover our range of short-term rental Yamaha scooters, TMAX, XMAX 300 or even NMAX 125 and book the one that meets your needs. Yamaha Rent is the guarantee to use recent and well-maintained scooters, to set off with a free spirit.',
    'motos_meta_desc':'Rent a 2 or 3 wheel motorcycle directly from your Yamaha Rent agency. Discover the ranges of Yamaha motorcycles and book for a short time.',
    'motos_desc1':'Rent a Yamaha motorcycle for a short period: a day, a weekend, a week or more... via 100% secure online booking or directly at your Yamaha Rent agency.',
    'motos_desc2':'Discover Yamaha motorcycles and choose a vehicle according to your needs: Yamaha MT-07, Tracer 9 GT ... Yamaha Rent is the guarantee of using recent and well-maintained motorcycles, to set off with a free spirit.',
    'votre-agence-delocation':'Yamaha Dealership',
    'title-details-agency':'Find the Yamaha Rent dealer near you',
    'votre-agence-concession':'Your Dealership',
    'desc-agence-concession':'Find the nearest Yamaha scooter and motorcycle rental agency to you. Your Yamaha Rent dealership offers you the entire recent range of scooters and motorcycles for short-term rentals. Yamaha Rent is the motorcycle and scooter rental offer offered by Yamaha Motor and its network of authorized rental agencies throughout France.',
    'slogan-dealerlocator':'Find the nearest Yamaha scooter and motorcycle rental agency to you. Your Yamaha Rent dealership offers you all the recent range of scooters and motorcycles for short rental.',
    'nos_agences':'Agencies',
    'site_agence':' Agency website',
    'Horaires d\'ouverture': 'Opening hours',
    'Aucune photo pour cette agence.': 'No photos for this agency.',
    'Votre agence': 'Your agency',
    'Aucun descriptif pour cette agence.': 'No description for this agency.',
    'Retour Nos agences': 'Back Our agencies',
    'agences-de-location': 'rental-agencies',
    'Nos-agences': 'Agencies',
    '/#nos-agences':'/en/#our-agencies',
    'Tél': 'Tel',
    'Tél.':'Tel.',
    'fermé':'closed',
    "les_agences_yamaha": "Yamaha Rent rental agencies",
    "Toutes les agences": "All agencies",
    "reservez-votre-moto":"Book your Yamaha motorcycle or scooter in ",
    'Location scooter moto Yamaha ':'Scooter motorcycle rental Yamaha ',
    'fermé':'closed',
    'Recevoir-mon-devis':'Receive my quote',
    'équivalent':'Equivalent',
    'sans_batterie':'(without battery)',
    'électriques':'electric',
    'Envoyer-un-lien-de-paiement':'Send payment link',
    'Dommage':'Damage',
    'Le lien de paiement n\'a pas été envoyé par sms au client':'The payment link was not sent by SMS to the customer',
    'Félicitation':'Congratulations',
    'Le lien de paiement a bien été envoyé par sms au client':'The payment link has been sent by SMS to the customer',
    'Vous avez dépassé le délai de paiement. Veuillez rappeler notre call center.':'You have missed the payment deadline. Please call our call center again.',
    'Votre réservation est déjà payée. Veuillez rappeler notre call center.':'Your reservation is already paid for. Please call our call center again.',
    'Le paiement a été refusé.':'Payment has been refused',
    'Autonomie':'Autonomy',
    'Franchise dommage':'Damage excess',
    'Franchise vol':'Flight allowance',
    'Places':'Seats',
    'Prix pack zen (TTC)':'Zen pack price (including tax)',
    'e-bikes':'electric bike',
    'mode_assistance':'depending on the assistance mode chosen',
    'Vélos électriques Yamaha à louer':'Yamaha electric bikes for rent',
    'CARTE CADEAU':'GIFT CARD',
    'Sans permis':'Without license',
}
