import React, { Component } from "react";

import { geocodeByAddress, getLatLng } from "react-places-autocomplete";

import geolib from "geolib";

import axios from "axios";
import { ENVIRONMENT ,BABEL_V2_URL, BABEL_V2_URL_VERSION,BABEL_V2_URL_PP, BABEL_V2_URL_VERSION_PP } from "../configuration";
import i18n from "../i18n";
import { stringToBoolean } from "../helper/stringToBoolean";
import { BookingCartCreationFailedException } from "../services/bookingCart/exceptions/BookingCartCreationFailedException";

//DAY PICKER
import { formatDate } from "react-day-picker/moment";

// REDUX
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  saveSearchHour,
  saveUnformatedDate,
  saveAllVehiclesAvailable,
  saveSelectedAgency,
  updateListVehicle,
  manageMessageNoAvailableVehicle,
  saveHorairesAgency,
  saveActualiserState,
  setGlobalPrice,
  saveDevisStatus
} from "../redux/action";

//STYLE
import { Row, Col, Button, Input } from "reactstrap";

//MODULES
import DayPicker from "../modules/dayPicker";

//HELPER
import { getVehiclesFromAgency } from "../helper/getVehiclesFromAgency";
import { getTotalDaysDifference } from "../helper/getTotalDaysDifference";
import { IdTagGeolid } from "../services/analytics/geolid/idTagGeolid";

//TRANSLATION
import { Trans, withTranslation } from "react-i18next";
import { choosenLanguage } from "../translations/change-langage";
import { bookingTotalPriceSelector } from "../redux/selectors";
import arrowClose from '../assets/img/close-icon.png'

const _ = require("lodash");
const mobileBreakpoint = 450;

class SideInfoAgency extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showHourPicker: 0,
      startHour: "08",
      endHour: "18",
      agencyArround: [],
      horaire: [],
      tabWeek: [],
      horaireAgence: [],
      tabHour: { startTabHour: [], endTabHour: [] },
      choosenLanguage: this.props.i18n.language,
      isMobile: window.innerWidth <= mobileBreakpoint,
      buttonEnabled: true,
      actualiserState: false,
      isLoading: false,
      Devis_ok: false,
      isFormCompleted_state: false,
    };
  }

  componentDidMount() {
    const queryString = window.location.search;
    if (queryString.length == 0) {
      this.getHoraireBabel(this.props.selectedAgency.HorairesOuverture);
    } else {
      var selectedAgency = JSON.parse(localStorage.getItem("selectedAgency"));
      this.getHoraireBabel(selectedAgency.HorairesOuverture);
    }
    let address =
      this.props.selectedAgency.Addresse +
      "," +
      this.props.selectedAgency.Ville;
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        let agencyArround = [];
        let listCodeAgencies = "";
        let listAgencies = JSON.parse(localStorage.getItem("listAllAgencies"));

        for (let i = 0; i < listAgencies.length; i++) {
          let isAgencyArround;

          if (
            listAgencies[i].GpsLatitude &&
            listAgencies[i].GpsLatitude !== "vide"
          ) {
            isAgencyArround = geolib.isPointInCircle(
              { latitude: latLng.lat, longitude: latLng.lng },
              {
                latitude: listAgencies[i].GpsLatitude,
                longitude: listAgencies[i].GpsLongitude,
              },
              100000
            );
          }

          if (isAgencyArround) {
            let distance = geolib.getDistance(
              { latitude: latLng.lat, longitude: latLng.lng },
              {
                latitude: listAgencies[i].GpsLatitude,
                longitude: listAgencies[i].GpsLongitude,
              }
            );

            let formatAgencieDistance = { ...listAgencies[i], distance };
            agencyArround.push(formatAgencieDistance);

            if (listAgencies[i].typeService === "Agence") {
              listCodeAgencies =
                listCodeAgencies === ""
                  ? listAgencies[i].agencyCode
                  : listCodeAgencies + ";" + listAgencies[i].agencyCode;
            }
          }
        }

        this.setState({ agencyArround });
      });
  }

  componentDidUpdate(prev) {
    if (prev.selectedAgency !== this.props.selectedAgency) {
      this.reloadVehicles(this.props.selectedAgency, true);
    }
  }

  getHoraireBabel = (horaire) => {
    this.props.saveHorairesAgency(horaire);
    this.getHoraireAgence(horaire);
    let objHoraire = this.defineHours(horaire);
    this.setState({
      tabHour: {
        startTabHour: objHoraire.startTabHour,
        endTabHour: objHoraire.endTabHour,
      },
    });
  };

  getHoraireAgence = (horaire) => {
    let regexHoraire = / (.[0-9])/g;
    let tabHoraire = horaire;
    let horaireAgence = [];

    let tabWeek = [
      <Trans i18nKey="lundi">Lundi</Trans>,
      <Trans i18nKey="mardi">Mardi</Trans>,
      <Trans i18nKey="mercredi">Mercredi</Trans>,
      <Trans i18nKey="jeudi">Jeudi</Trans>,
      <Trans i18nKey="vendredi">Vendredi</Trans>,
      <Trans i18nKey="samedi">Samedi</Trans>,
      <Trans i18nKey="dimanche">Dimanche</Trans>,
    ];

    for (let i = 0; tabHoraire.length > i; i++) {
      let dayHoraire;

      if (tabHoraire[i].ouverture_matin) {
        if (tabHoraire[i].ouverture_matin !== "None") {
          let o_matin = tabHoraire[i].ouverture_matin
            .match(regexHoraire)[0]
            .match(/(..)$/g);

          let f_matin = tabHoraire[i].fermeture_matin.match(regexHoraire)
            ? tabHoraire[i].fermeture_matin
              .match(regexHoraire)[0]
              .match(/(..)$/g) +
            " h " +
            tabHoraire[i].fermeture_matin
              .match(/:([0-9][0-9])/g)[0]
              .split(":")[1] +
            " ; "
            : "";

          let o_aprem = tabHoraire[i].ouverture_aprem.match(regexHoraire)
            ? tabHoraire[i].ouverture_aprem
              .match(regexHoraire)[0]
              .match(/(..)$/g) +
            " h " +
            tabHoraire[i].ouverture_aprem
              .match(/:([0-9][0-9])/g)[0]
              .split(":")[1] +
            " - "
            : "";

          let f_aprem = tabHoraire[i].fermeture_aprem.match(regexHoraire)
            ? tabHoraire[i].fermeture_aprem
              .match(regexHoraire)[0]
              .match(/(..)$/g)
            : "";
          let aprem_string =
            o_aprem != ""
              ? o_aprem +
              f_aprem +
              " h " +
              (tabHoraire[i].fermeture_aprem.match(/:([0-9][0-9])/g)
                ? tabHoraire[i].fermeture_aprem
                  .match(/:([0-9][0-9])/g)[0]
                  .split(":")[1]
                : "")
              : "Fermé";

          dayHoraire =
            o_matin +
            " h " +
            tabHoraire[i].ouverture_matin
              .match(/:([0-9][0-9])/g)[0]
              .split(":")[1] +
            " - " +
            f_matin +
            aprem_string;
        } else {
          dayHoraire = <Trans i18nKey="ferme">fermé</Trans>;
        }
      }

      horaireAgence.push(dayHoraire);
    }

    this.setState({ tabWeek, horaireAgence });
  };

  defineHours = (horaire) => {
    let tabHoraire = horaire;
    let tabHour = [];

    let startDay =
      new Date(this.props.startDateUnformated).getDay() === 0
        ? 7
        : new Date(this.props.startDateUnformated).getDay() - 1;
    let endDay =
      new Date(this.props.endDateUnformated).getDay() === 0
        ? 7
        : new Date(this.props.endDateUnformated).getDay() - 1;
    var selectedAgency = JSON.parse(localStorage.getItem("selectedAgency"));
    if (selectedAgency) {
      startDay = 1;
      endDay = 1;
    }
    if (
      tabHoraire[startDay].ouverture_matin &&
      tabHoraire[endDay].ouverture_matin
    ) {
      if (tabHoraire[startDay].ouverture_matin.match(/ (.[0-9])/g)) {
        let start_ouverture_matin = tabHoraire[startDay].ouverture_matin
          .match(/ (.[0-9])/g)[0]
          .match(/(..)$/g)[0];
        let start_fermeture_aprem = tabHoraire[startDay].fermeture_aprem
          .match(/ (.[0-9])/g)[0]
          .match(/(..)$/g)[0];
        let end_fermeture_matin = tabHoraire[startDay].fermeture_matin
          .match(/ (.[0-9])/g)[0]
          .match(/(..)$/g)[0];
        let start_ouverture_aprem = tabHoraire[startDay].ouverture_aprem
          .match(/ (.[0-9])/g)[0]
          .match(/(..)$/g)[0];
        let end_ouverture_matin = tabHoraire[endDay].ouverture_matin
          .match(/ (.[0-9])/g)[0]
          .match(/(..)$/g)[0];
        let end_fermeture_aprem = tabHoraire[endDay].fermeture_aprem
          .match(/ (.[0-9])/g)[0]
          .match(/(..)$/g)[0];

        for (let i = 0; i <= +start_fermeture_aprem; i++) {
          tabHour.push(i < 10 ? "0" + i : i);
        }

        for (let i = 0; i < tabHour.length; i++) {
          if (
            tabHour[i] >= end_fermeture_matin &&
            tabHour[i] < start_ouverture_aprem
          ) {
            delete tabHour[i];
          }
        }

        let startTabHour = tabHour.splice(+start_ouverture_matin);
        tabHour = [];

        for (let i = 0; i <= end_fermeture_aprem; i++) {
          tabHour.push(i < 10 ? "0" + i : i);
        }

        for (let i = 0; i < tabHour.length; i++) {
          if (
            tabHour[i] >= end_fermeture_matin &&
            tabHour[i] < start_ouverture_aprem
          ) {
            delete tabHour[i];
          }
        }

        let endTabHour = tabHour.splice(+end_ouverture_matin);

        return {
          startTabHour,
          endTabHour,
          start_ouverture_matin,
          end_fermeture_aprem,
        };
      }
    }
  };

  handleClickShow(type) {
    if (
      this.state.tabHour.startTabHour.length > 1 &&
      this.state.tabHour.endTabHour.length > 1
    ) {
      this.setState({ showHourPicker: type === "start" ? 1 : 2 });
    }
  }

  handleClickSelectHour(hour, typeHour) {
    this.setState({ showHourPicker: 0, [typeHour]: hour });
    let type = typeHour === "startHour" ? "start" : "end";
    let unformatedType = type + "DateUnformated";
    let unformated = this.props[unformatedType];
    let regex = /T([0-9][0-9])/g;
    this.props.saveSearchHour(type, hour);

    let day = unformated.replace(regex, "T" + hour);
    this.props.saveUnformatedDate(type + "Date", day);
  }

  async reloadVehicles(newAgency, changeHour) {
    this.props.saveAllVehiclesAvailable([]);
    this.props.manageMessageNoAvailableVehicle(
      <div id="loader">{this.props.t("Recherche en cours...")}</div>
    );

    if (newAgency) {
      this.props.saveSelectedAgency(newAgency);
      this.pushTagGeolid(newAgency);

      let objHoraire = this.defineHours(newAgency.HorairesOuverture);
      this.setState({
        tabHour: {
          startTabHour: objHoraire.startTabHour,
          endTabHour: objHoraire.endTabHour,
        },
      });

      if (changeHour) {
        this.props.saveSearchHour(
          "start",
          +objHoraire.start_ouverture_matin + 1
        );
        this.props.saveSearchHour("end", +objHoraire.end_fermeture_aprem - 1);
      }
    }

    const allAvailableVehicles = await getVehiclesFromAgency(
      this.props.startDate,
      this.props.endDate,
      this.props.selectedAgency.AgenceCode
    );

    this.setState({
      listAvailableCategorie: allAvailableVehicles,
      listFilteredVehicles: allAvailableVehicles,
    });
    this.props.saveAllVehiclesAvailable(allAvailableVehicles);

    if (allAvailableVehicles.length === 0) {
      this.props.manageMessageNoAvailableVehicle(
        this.props.t("Aucun véhicule disponible")
      );
    } else {
      this.props.manageMessageNoAvailableVehicle(
        <div id="loader">{this.props.t("Recherche en cours...")}</div>
      );
    }

    //J'active les boutons dans la liste des véhicules
    this.props.saveActualiserState(false);

    this.setState({ filterType: "prix", filterOrder: "asc" });
  }

  capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  getTotalKmSuppPrice() {
    return Math.round(
      this.props.selectedVehicle
        ? this.props.selectedVehicle.prixKm * this.props.addedKm
        : null
    );
  }

  /*Tag Geolid*/

  pushTagGeolid(agency) {
    const localStorageAgencyCode = agency.AgenceCode;
    localStorage.setItem("localStorageSetShopId", localStorageAgencyCode);
    IdTagGeolid.setShopId(localStorageAgencyCode);
  }

  get placeHolderDateStartTranslated() {
    if (this.state.choosenLanguage === "fr") {
      return `${formatDate(this.props.startDateUnformated, "LL", "fr")}`;
    } else {
      return `${formatDate(this.props.startDateUnformated, "LL", "en")}`;
    }
  }

  get placeHolderDateEndTranslated() {
    if (this.state.choosenLanguage === "fr") {
      return `${formatDate(this.props.endDateUnformated, "LL", "fr")}`;
    } else {
      return `${formatDate(this.props.endDateUnformated, "LL", "en")}`;
    }
  }

  buildCreateBookingCartRequest() {
    let usolPrestations = this.props.usolServices.filter((service) => {
      return (
        service.code === "assZen" ||
        service.code === "location" ||
        service.code === "assRachat" ||
        service.code === "prix_franchise_dom"
      );
    });

    let services = usolPrestations.map((prestation) => {
      let service = { ...prestation };
      let multiplicator = 1;
      if (
        service.code !== "location" &&
        service.code !== "prix_franchise_dom"
      ) {
        multiplicator = this.props.selectedVehicle.totalDays;
      }
      if (service.code === "assZen" && this.props.priceOption1 > 0) {
        service.subscription = true;
      }

      service.subscription = stringToBoolean(service.subscription);
      service.price_with_VAT = service.price_with_VAT;
      service.price_with_VAT =
        Math.round(service.price_with_VAT * 100) * multiplicator;
      service.price_without_VAT =
        Math.round(service.price_without_VAT * 100) * multiplicator;
        
      return service;
    });

    var bookingTotalPrice = this.props.bookingTotalPrice;
    bookingTotalPrice = parseFloat(this.props.bookingPromoPrice) > 0 ? parseFloat(this.props.globalPrice) : bookingTotalPrice;
    if(bookingTotalPrice < 0){
      bookingTotalPrice = -bookingTotalPrice;
    }

    return {
      is_devis:true,
      periods: [
        {
          start: this.props.startDate.replace("T", " "),
          end: this.props.endDate.replace("T", " "),
        },
      ],
      duration: this.props.selectedVehicle.totalDays,
      price_with_VAT: bookingTotalPrice * 100,
      included_distance: this.props.selectedVehicle.kmInclu,
      chosen_distance: this.props.addedKm + this.props.selectedVehicle.kmInclu,
      additional_distance_price_with_VAT:
        this.props.addedKm * +this.props.selectedVehicle.prixKm * 100,
      user: {
        email: this.props.infoConducteur.email,
        gender: this.props.infoConducteur.civilite,
        first_name: this.props.infoConducteur.prenom,
        last_name: this.props.infoConducteur.nom,
        phone_number: this.props.infoConducteur.tel,
        age_range: this.props.infoConducteur.age,
        driving_license_category: this.props.infoConducteur.permis,
        address: this.props.infoConducteur.adresse,
        zip: this.props.infoConducteur.cp,
        city: this.props.infoConducteur.ville,
      },
      vehicle: this.props.selectedVehicle.vehicle,
      agency: {
        code: this.props.selectedAgency.AgenceCode,
        label: this.props.selectedAgency.Intitule,
        email: this.props.selectedAgency.Email,
        phone_number: this.props.selectedAgency.Telephone,
        address: this.props.selectedAgency.Addresse,
        zip: this.props.selectedAgency.CodePostal,
        city: this.props.selectedAgency.Ville,
      },
      tariff_title: this.props.selectedVehicle.tariff_title,
      services,
      accessories: [
        {
          key: "gloves",
          quantity:
            this.props.priceOption2 /
            this.props.selectedVehicle.prestation[1].Tarifs.TTC,
          price_with_VAT: this.props.priceOption2 * 100,
        },
        {
          key: "helmet",
          quantity:
            this.props.priceOption3 /
            this.props.selectedVehicle.prestation[2].Tarifs.TTC,
          price_with_VAT: this.props.priceOption3 * 100,
        },
      ],
    };
  }

  Devis_Close() {
    this.setState({Devis_ok: false});
  }

  async sendDevis() {
    let regexMail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let regexTel = /^\+?([0-9]{2})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$/;
    let checkEmail = regexMail.test(this.props.infoConducteur.email);
    let checkTel = regexTel.test(this.props.infoConducteur.tel);
    let isFormCompleted =
      this.props.infoConducteur.civilite &&
      this.props.infoConducteur.nom &&
      this.props.infoConducteur.prenom &&
      checkEmail &&
      checkTel &&
      this.props.infoConducteur.permis &&
      this.props.infoConducteur.age &&
      this.props.infoConducteur.rgpd &&
      this.props.infoConducteur.check;
      
      this.setState({isFormCompleted_state:isFormCompleted});
      this.props.saveDevisStatus(isFormCompleted);

      this.setState({isLoading: true});
      setTimeout(() => {
      if(this.state.isFormCompleted_state){
      const requestBody = this.buildCreateBookingCartRequest();

        return axios({
          method: "POST",
          url: (ENVIRONMENT == "PROD" ? BABEL_V2_URL : BABEL_V2_URL_PP) + "/" + (ENVIRONMENT == "PROD" ? BABEL_V2_URL_VERSION : BABEL_V2_URL_VERSION_PP) + "/bookings",
          headers: {
            "Accept-Language": i18n.language === undefined ? "fr" : i18n.language,
            "Content-Type": "application/json",
          },
          data: requestBody,
        })
        .then((response) => {
          this.setState({Devis_ok: true});
          this.setState({isLoading: false});
          return response;
        })
        .catch((error) => {
          throw new BookingCartCreationFailedException(
            "Devis creation failed",
            error
          );
        });
      }else{
        this.setState({isLoading: false});
        this.props.saveDevisStatus(false);
      }
    }, 500);
  }
  render() {
    if(parseFloat(this.props.bookingPromoPrice) != 0){
      var price_final = parseFloat(this.props.bookingTotalPrice) - parseFloat(this.props.bookingPromoPrice);
      if(price_final < 0){
        price_final = 0;
      }
      this.props.setGlobalPrice(parseFloat(price_final).toFixed(2));
    }

    const switchHref = this.state.isMobile
      ? "tel:" + this.props.selectedAgency.Telephone
      : null;
    const labelTlf = !this.state.isMobile
      ? this.props.selectedAgency.Telephone
      : this.props.t("Appeler");

    return (
      
      <Col md="4" style={{ display: this.props.isTablet }}>
        <div
          style={
            this.props.sectionReservation !== 2 ? { display: "none" } : null
          }
          className="section"
          id="changeDate"
        >
          <h2 className="red">
            <Trans i18nKey="dates_reservation">Dates de réservation</Trans>
          </h2>
          <Row>
            <Col xs="8">
              <DayPicker
                time="startDate"
                closeDays={
                  this.props.selectedAgency !== undefined
                    ? this.props.selectedAgency.JoursFermetures
                    : []
                }
                placeholder={this.placeHolderDateStartTranslated}
              />
            </Col>
            <Col xs="4">
              <Input
                value={this.props.startHour + ":00"}
                onClick={this.handleClickShow.bind(this, "start")}
                readOnly
              />
              <ul
                className={
                  this.state.showHourPicker === 1
                    ? "selectHour show"
                    : "selectHour"
                }
              >
                {this.state.tabHour.startTabHour.length > 1 &&
                  this.state.tabHour.startTabHour.map((val, i) => {
                    return (
                      <li
                        key={i}
                        onClick={this.handleClickSelectHour.bind(
                          this,
                          val,
                          "startHour"
                        )}
                      >
                        {val}:00
                      </li>
                    );
                  })}
              </ul>
            </Col>
          </Row>
          <Row>
            <Col xs="8">
              <DayPicker
                time="endDate"
                closeDays={
                  this.props.selectedAgency !== undefined
                    ? this.props.selectedAgency.JoursFermetures
                    : []
                }
                placeholder={this.placeHolderDateEndTranslated}
              />
            </Col>
            <Col xs="4">
              <Input
                value={this.props.endHour + ":00"}
                onClick={this.handleClickShow.bind(this, "end")}
                readOnly
              />
              <ul
                className={
                  this.state.showHourPicker === 2
                    ? "selectHour show"
                    : "selectHour"
                }
              >
                {this.state.tabHour.endTabHour.map((val, i) => {
                  return (
                    <li
                      key={i}
                      onClick={this.handleClickSelectHour.bind(
                        this,
                        val,
                        "endHour"
                      )}
                    >
                      {val}:00
                    </li>
                  );
                })}
              </ul>
            </Col>
          </Row>
          <Row>
            <Col>
              <Button
                block
                onClick={this.reloadVehicles.bind(
                  this,
                  this.props.selectedAgency,
                  false
                )}
                color="primary"
                size="lg"
              >
                <Trans i18nKey="actualiser">Actualiser</Trans>
              </Button>
            </Col>
          </Row>
        </div>

        <div
          style={this.props.sectionReservation < 4 ? { display: "none" } : null}
          className="section vehicle"
        >
          <h2 className="red">
            <Trans i18nKey="ma_reservation">Ma réservation</Trans>
          </h2>
          <div id="sectionDate">
            {this.placeHolderDateStartTranslated}
            <br />
            {this.placeHolderDateEndTranslated}
          </div>
          <img
            alt="véhicule"
            src={
              this.props.selectedVehicle ? this.props.selectedVehicle.img : null
            }
          />
          <p className="grey alignWithBtn" style={{ fontSize: 10 }}>
            <Trans i18nKey="photo">
              Photo non contractuelle, la version et le coloris peuvent différer
              en fonction des disponibilités en agence.
            </Trans>
          </p>
          <h2 className="red">
            {this.props.selectedVehicle
              ? this.props.selectedVehicle.nomVehicule
              : null}
          </h2>
          <div id="tarif">
            <div>
              <Trans i18nKey="total_commande">Total commande</Trans>
            </div>
            <div>
              <span className={this.props.bookingPromoPrice==0 ? 'disabled-promo': ''}>{
              this.props.globalPrice
              ? parseFloat(this.props.globalPrice)
                .toFixed(2)
                .toString()
                .replace(".", ",")
              : ""}{" "}
            €<br/></span>
              <span className={this.props.bookingPromoPrice>0 ? 'old': ''}>
              {this.props.bookingTotalPrice
                ? parseFloat(this.props.bookingTotalPrice)
                  .toFixed(2)
                  .toString()
                  .replace(".", ",")
                : ""}{" "}
              €</span>
            </div>
          </div>
          <div className="clear"></div>
          <div className="flex">
            <div className="column-2">
              <span style={{ paddingBottom: "50px" }}>
                <Trans i18nKey="location">Location</Trans>
              </span>
              <br />
              {this.props.selectedVehicle
                ? this.props.selectedVehicle.kmInclu
                : null}km <Trans i18nKey="forfait">(forfait)</Trans> <br />+ {this.props.selectedVehicle
                  ? +this.props.addedKm
                  : null} km <Trans i18nKey="supplementaires">supplémentaires</Trans>

              {this.props.priceOption1 !== 0 && this.props.selectedVehicle ? (
                <span>
                  <br />{" "}
                  <Trans i18nKey="pack_zen">
                    {this.props.selectedVehicle.prestation[0].Label}
                  </Trans>
                </span>
              ) : null}
              {this.props.priceOption2 !== 0 && this.props.selectedVehicle ? (
                <span>
                  <br />
                  {this.props.priceOption2 /
                    getTotalDaysDifference(
                      this.props.startDate,
                      this.props.endDate
                    ) /
                    this.props.selectedVehicle.prestation[1].Tarifs.TTC}
                  <Trans i18nKey="pack_gants">
                    {" " + this.props.selectedVehicle.prestation[1].Label}
                  </Trans>
                </span>
              ) : null}
              {this.props.priceOption3 !== 0 && this.props.selectedVehicle ? (
                <span>
                  <br />
                  {this.props.priceOption3 /
                    getTotalDaysDifference(
                      this.props.startDate,
                      this.props.endDate
                    ) /
                    this.props.selectedVehicle.prestation[2].Tarifs.TTC}
                  <Trans i18nKey="pack_casque">
                    {" " + this.props.selectedVehicle.prestation[2].Label}
                  </Trans>
                </span>
              ) : null}
            </div>

            <div className="colum">
              <span>
                {this.props.selectedVehicle
                  ? this.props.selectedVehicle.tarif
                    .toString()
                    .replace(".", ",")
                  : null}{" "}
                €
              </span>
              <br />
              <br />
              <span>{this.getTotalKmSuppPrice()},00 €</span>
              <span>
                {this.props.priceOption1 !== 0 && this.props.selectedVehicle ? (
                  <span>
                    <br />{" "}
                    {this.props.priceOption1 *
                      this.props.selectedVehicle.totalDays}
                    ,00 €
                  </span>
                ) : null}
              </span>
              <span>
                {this.props.priceOption2 !== 0 && this.props.selectedVehicle ? (
                  <span>
                    <br /> {this.props.priceOption2},00 €
                  </span>
                ) : null}
              </span>
              <span>
                {this.props.priceOption3 !== 0 && this.props.selectedVehicle ? (
                  <span>
                    <br /> {this.props.priceOption3},00 €
                  </span>
                ) : null}
              </span>
            </div>
          </div>
        </div>
        {
            [4,5].includes(this.props.sectionReservation)
            ? (
          <div class="demande-devis">
                 <a
                onClick={this.sendDevis.bind(this)}
                href="javascript:void(0)" 
              >
                ⮕ <Trans i18nKey="Recevoir-mon-devis">Recevoir mon devis</Trans>
              </a>
          </div>) : null
        }
        <div className="section horaire">
          <h2 className="red" style={{ textTransform: "capitalize" }}>
            {this.props.selectedAgency.Intitule}
          </h2>
          <Row>
            <Col>
              <b>
                {this.props.selectedAgency.Addresse} <br />
                {this.props.selectedAgency.CodePostal}{" "}
                {this.props.selectedAgency.Ville}
                <br />
                <a href={switchHref} className="cta-side-agency-mobile">
                  <em>{labelTlf}</em>
                </a>
                <br />
                <em>{this.props.selectedAgency.Email}</em>
                <br />
                <em>{this.props.selectedAgency.tel}</em>
              </b>
              <br />
              <br />

              {/*TODO: afficher valeur de l'agence*/}
              {/*<strong>Portes ouvertes le 25 octobre</strong>*/}
            </Col>
          </Row>
        </div>
        {this.state.isLoading ? (
              <div id="loader" class="devis-loader">{this.props.t("Devis en cours...")}</div>
          ) : null }
              {this.state.Devis_ok ? (
                    <div id="Devis_ok">
                      <img class="close-devis" onClick={this.Devis_Close.bind(this)} src={arrowClose} alt="arrow-close"/>
                      <div id="Devis_icon"></div>
                      {this.props.t("Devis envoyé avec succès")}
                    </div>
          ) : null }

        <div className="section horaire">
          <h2 className="red">
            <Trans i18nKey="horaires_agence">Horaires de l'agence</Trans>
          </h2>

          {this.state.tabWeek.map((val, i) => {
            return (
              <Row key={i}>
                <Col
                  xs="2"
                  className="day-agency-hour"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  {val}
                </Col>
                <Col
                  xs="12"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    fontSize: 14,
                    marginBottom: 5,
                  }}
                >
                  {this.state.horaireAgence[i]}
                </Col>
              </Row>
            );
          })}
        </div>
        <div
          style={
            this.props.sectionReservation !== 2 ? { display: "none" } : null
          }
          className="section"
        >
          <h2 className="red">
            <Trans i18nKey="agences_proximite">Agences à proximité</Trans>
          </h2>
          {this.state.agencyArround.map((val, i) => {
            if (val.Intitule !== this.props.selectedAgency.Intitule) {
              return (
                <div
                  className="proximite"
                  onClick={this.reloadVehicles.bind(this, val, false)}
                  key={i}
                  style={{ textTransform: "capitalize" }}
                >
                  <span className="red">⟶</span>{" "}
                  {this.capitalizeFirstLetter(val.Intitule)}
                </div>
              );
            } else {
              return <div key={i}></div>;
            }
          })}
        </div>
      </Col>
    );
  }
}

function mapStateToProps(state) {
  return {
    bookingTotalPrice: bookingTotalPriceSelector(state),
    globalPrice: state.reducer.globalPrice,
    bookingPromoPrice: state.reducer.promoPrice,
    startDateUnformated: state.reducer.startDateUnformated,
    endDateUnformated: state.reducer.endDateUnformated,
    startDate: state.reducer.startDate,
    endDate: state.reducer.endDate,
    startHour: state.reducer.startHour,
    endHour: state.reducer.endHour,
    selectedAgency: state.reducer.selectedAgency,
    cityPosition: state.reducer.cityPosition,
    sectionReservation: state.reducer.sectionReservation,
    selectedVehicle: state.reducer.selectedVehicle,
    addedKm: state.reducer.addedKm,
    priceOption1: state.reducer.priceOption1,
    priceOption2: state.reducer.priceOption2,
    priceOption3: state.reducer.priceOption3,
    horaireAgency: state.reducer.horaireAgency,
    savedFilter: state.reducer.savedFilter,
    actualiserState: state.reducer.actualiserState,
    infoConducteur: state.reducer.infoConducteur,
    usolServices: state.reducer.selectedVehicle?.usol_tarif
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      saveSearchHour,
      saveUnformatedDate,
      saveAllVehiclesAvailable,
      saveSelectedAgency,
      updateListVehicle,
      manageMessageNoAvailableVehicle,
      saveHorairesAgency,
      saveActualiserState,
      setGlobalPrice,
      saveDevisStatus
    },
    dispatch
  );
}

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(SideInfoAgency)
);
