import React, { Component } from 'react';
//COMPONENTS
import Header from '../staticComponents/header'
import Footer from '../staticComponents/footer'
import SearchDealerLocator from "../modules/searchDealerLocator";

class OurAgencies extends Component {
  render() {
    return (
        <div>
            {/*<Container>*/}
                <Header/>
                <SearchDealerLocator/>
                <Footer/>
            {/*</Container>*/}
        </div>
    );
  }
}

export default OurAgencies;
