import axios from "axios/index";
import {
    ENVIRONMENT,
    GET_VEHICLES_TYPE_INFO,
    SERVER_ADDRESS_80,
    SERVER_ADDRESS_80_PP,
    GET_MODELE,
    GET_ALL_PRICES_DATE,
    GET_DETAIL_PRESATION,
    GET_ALL_VEHICLES,
    GET_ALL_PRICES_DATE_V2
} from "../configuration";

/*import {Trans, withTranslation} from 'react-i18next';*/
/*import ChangeLanguage, {choosenLanguage} from "../translations/change-langage"*/
import i18n from "../i18n";

//GET MODEL
export const babelGetModel = async (startDate, endDate, agency) => {
    let params = `?start_date=${startDate}&end_date=${endDate}&agency_code=${agency}`
    const request = {
        method: 'get',
        url: (ENVIRONMENT == "PROD" ? SERVER_ADDRESS_80 : SERVER_ADDRESS_80_PP) + GET_MODELE + params,
        headers: {
            'Content-Type': 'application/json',
            'Accept-Language':i18n.language === undefined ? 'fr' : i18n.language,
        }
    }

    return axios(request).then(data => {
        return data.data.response.data
    }).catch(error => {
        return error
    })
}

export const babelGetVehiclesTypeInfo = async (type = null) => {
    let url
    if (type === null) {
        url = (ENVIRONMENT == "PROD" ? SERVER_ADDRESS_80 : SERVER_ADDRESS_80_PP) + GET_VEHICLES_TYPE_INFO
    } else {
        let params = `?type_vehicule=${type}`
        url = (ENVIRONMENT == "PROD" ? SERVER_ADDRESS_80 : SERVER_ADDRESS_80_PP) + GET_VEHICLES_TYPE_INFO + params
    }
    return axios({
        method: 'get',
        url: url,
        headers: {
            'Content-Type': 'application/json',
            'Accept-Language':i18n.language === undefined ? 'fr' : i18n.language,
        }
    }).then(data => {
        if (data.data.code === 200) {
            return data.data.response
        } else {
            console.log('error')
        }


    }).catch(error => {
        console.log(error)
        return error
    })
}

//OLD
export const babelGetPriceVehicles = async (startDate, endDate, codeAgence, typeVehicle) => {
    let zone = 15
    let km = 50
    let params = `?zone_tarifaire=${zone}&agence_code=${codeAgence}&type_vehicule=${typeVehicle}&date_depart=${startDate}&date_retour=${endDate}&km_estime=${km}`

    return axios({
        method: 'get',
        url: (ENVIRONMENT == "PROD" ? SERVER_ADDRESS_80 : SERVER_ADDRESS_80_PP) + GET_ALL_PRICES_DATE + params,
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Accept-Language':i18n.language === undefined ? 'fr' : i18n.language,
        }

    }).then(data => {
        return data.data.response

    }).catch(error => {
        return error
    })
}

//NEW
export const babelGetTarifVehicles = async (startDate, endDate, codeAgence, typeVehicle) => {
    let zone = 15
    let km = 50
    let params = `?zone_tarifaire=${zone}&agence_code=${codeAgence}&type_vehicule=${typeVehicle}&date_depart=${startDate}&date_retour=${endDate}&km_estime=${km}`

    return axios({
        method: 'get',
        url: (ENVIRONMENT == "PROD" ? SERVER_ADDRESS_80 : SERVER_ADDRESS_80_PP) + GET_ALL_PRICES_DATE_V2 + params,
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Accept-Language':i18n.language === undefined ? 'fr' : i18n.language,
        }

    }).then(data => {
        return data.data.response

    }).catch(error => {
        return error
    })
}
export const babelGetPrestation = async (enseigne) => {

    let params = `?enseigne=${enseigne}`

    return axios({
        method: 'get',
        url: (ENVIRONMENT == "PROD" ? SERVER_ADDRESS_80 : SERVER_ADDRESS_80_PP) + GET_DETAIL_PRESATION + params,
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Accept-Language':i18n.language === undefined ? 'fr' : i18n.language,
        }


    }).then(data => {
        return data.data.response

    }).catch(error => {
        return error
    })
}

var models = [];
var lastSynchro :Date = null;
var storedLanguage = null

export const babelGetAllVehicles = async (type, lang= null) => {
    let language = lang ? lang : i18n.language === undefined ? 'fr' : i18n.language
    if (!lastSynchro || lastSynchro.getHours() < new Date().getHours() || language !== storedLanguage) {

        let params = `?vehicle_types=${type}`
        return axios({
            method: 'get',
            url: (ENVIRONMENT == "PROD" ? SERVER_ADDRESS_80 : SERVER_ADDRESS_80_PP) + GET_ALL_VEHICLES + params,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Accept-Language': language,
            }
        }).then(data => {
            models = data.data.response.vehicle_types;
            lastSynchro = new Date();
            storedLanguage = language;
            return JSON.parse(JSON.stringify(models));

        }).catch(error => {
            console.log(error.message)
            return error
        })
    } else {
        return JSON.parse(JSON.stringify(models))
    }
}
