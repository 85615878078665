import React, {Component} from 'react';
import Header from '../staticComponents/header'
import Footer from '../staticComponents/footer'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import SimpleReactValidator from 'simple-react-validator'
import Loader from 'react-loader-spinner'
import '../assets/css/styleForm.css'
import '../assets/css/style.css'
//TRANSLATE
import {Trans, withTranslation} from 'react-i18next';

class VosDroits extends Component {

    state = {
        lastname: '',
        firstname: '',
        email: '',
        address: '',
        zip: '',
        city: '',
        country: '',
        you_want: [],
        you_are: '',
        activity: '',
        more_infos: '',
        you_are_other: '',
        passport: '',
        passportFile: '',
        isLoading: false,
        sent: false
    }

    constructor(props) {
        super(props)
        this.validator = new SimpleReactValidator()
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleInputChange = this.handleInputChange.bind(this)
        this.handleYouWant = this.handleYouWant.bind(this)
        this.handleYouAreOther = this.handleYouAreOther.bind(this)
        this.handleYouAre = this.handleYouAre.bind(this)
    }

    handleSubmit(event) {
        event.preventDefault()


        if (this.validator.allValid()) {
            let form = new FormData()

            for (let field in this.state) {
                switch (field) {
                    case 'passport':
                        form.append('passport', this.state.passportFile)
                        break
                    case 'passportFile':
                        continue

                    default:
                        form.append(field, this.state[field])
                }
            }

            if (this.state.sent) {
                this.resetState()
            }

            this.setState({
                isLoading: true
            })
            var xhr = new XMLHttpRequest();
            xhr.open("POST", "https://yamaha-yourent-backend.herokuapp.com");
            xhr.onloadend = () => {
                this.setState({
                    sent: true
                })

                setTimeout(() => {
                    document.location.href = '/'
                }, 2000)
            }
            xhr.send(form)

        } else {
            this.validator.showMessages()
            this.forceUpdate()
        }
    }

    handleInputChange(event) {
        if (event.target.name === 'passport') {
            this.setState({
                passport: event.target.value,
                passportFile: event.target.files[event.target.files.length - 1]
            })
        } else {
            let value
            switch (event.target.type) {
                case 'checkbox':
                case 'radio':
                    if (event.target.checked) {
                        value = event.target.value
                    }
                    break
                default:
                    value = event.target.value
            }

            this.setState({
                [event.target.name]: value
            })
        }
    }

    handleYouWant(event) {
        let youWant = this.state.you_want

        const index = youWant.indexOf(event.target.value)
        if (index === -1) {
            youWant.push(event.target.value)
        } else {
            youWant.splice(index, 1)
        }

        this.setState({
            you_want: youWant
        })
    }

    handleYouAreOther(event) {
        this.setState({
            you_are_other: event.target.value,
            you_are: event.target.value
        })
    }

    handleYouAre(event) {
        let value = event.target.value === 'Other' ? this.state.you_are_other : event.target.value
        this.setState({
            you_are: value
        })
    }

    resetState() {
        this.setState({
            lastname: '',
            firstname: '',
            email: '',
            address: '',
            zip: '',
            city: '',
            country: '',
            you_want: [],
            you_are: '',
            activity: '',
            more_infos: '',
            you_are_other: '',
            passport: '',
            passportFile: '',
            isLoading: false
        })
    }

    render() {
        return (
            <div>
                <Header/>
                <form onSubmit={this.handleSubmit} noValidate>
                    <div className="container" id="vos-droits">
                        <h1><Trans i18nKey="formulaire_droit">Formulaire d'exercice des droits</Trans></h1>
                        <small><Trans i18nKey="champs_obligatoire">* champs obligatoires</Trans></small>
                        <br/><br/>
                        <div className="vos-droits-content">
                            <div className="col-fields">
                                <div className="field">
                                <span className="field-icon">
                                    <FontAwesomeIcon icon="user"/>
                                </span>
                                    <input type="text" name="lastname" placeholder={this.props.t("Nom *")}
                                           className="form-control"
                                           value={this.state.lastname}
                                           onChange={this.handleInputChange}/>
                                    {this.validator.message('lastname', this.state.lastname, 'required', false, {
                                        required: this.props.t('Le champ est obligatoire'),
                                        alpha: 'Le nom saisi est invalide'
                                    })}
                                </div>
                                <div className="field">
                                <span className="field-icon">
                                    <FontAwesomeIcon icon="user"/>
                                </span>
                                    <input type="text" name="firstname" placeholder={this.props.t("Prénom *")}
                                           className="form-control"
                                           value={this.state.firstname}
                                           onChange={this.handleInputChange}/>
                                    {this.validator.message('firstname', this.state.firstname, 'required', false, {
                                        required: this.props.t('Le champ est obligatoire'),
                                        alpha: 'Le prénom saisi est invalide'
                                    })}
                                </div>
                                <div className="field">
                                <span className="field-icon">
                                    <FontAwesomeIcon icon="envelope"/>
                                </span>
                                    <input type="email" name="email" placeholder={this.props.t("Email *")}
                                           value={this.state.email}
                                           onChange={this.handleInputChange}/>
                                    {this.validator.message('email', this.state.email, 'required|email', false, {
                                        required: this.props.t('Le champ est obligatoire'),
                                        email: this.props.t('L\'email saisi est invalide')
                                    })}
                                </div>
                            </div>
                            <div className="col-fields">
                                <div className="field">
                                <span className="field-icon">
                                    <FontAwesomeIcon icon="map-marker"/>
                                </span>
                                    <input type="text" name="address" placeholder={this.props.t("Addresse *")}
                                           value={this.state.address}
                                           onChange={this.handleInputChange}/>
                                    {this.validator.message('address', this.state.address, 'required', false, {
                                        required: this.props.t('Le champ est obligatoire'),
                                    })}
                                </div>
                                <div className="field">
                                <span className="field-icon">
                                    <FontAwesomeIcon icon="map-marker"/>
                                </span>
                                    <input type="text" name="zip" placeholder={this.props.t("Code Postal *")}
                                           maxLength="5"
                                           value={this.state.zip}
                                           onChange={this.handleInputChange}/>
                                    {this.validator.message('zip', this.state.zip, 'required|min:5|max:5|integer', false, {
                                        required: this.props.t('Le champ est obligatoire'),
                                        integer: this.props.t('Le code postal saisi est invalide'),
                                        min: this.props.t('Le code postal saisi est invalide'),
                                        max: this.props.t('Le code postal saisi est invalide'),
                                    })}
                                </div>
                                <div className="field">
                                <span className="field-icon">
                                    <FontAwesomeIcon icon="map-marker"/>
                                </span>
                                    <input type="text" name="city" placeholder={this.props.t("Ville *")}
                                           value={this.state.city}
                                           onChange={this.handleInputChange}/>
                                    {this.validator.message('city', this.state.city, 'required', false, {
                                        required: this.props.t('Le champ est obligatoire'),
                                        alpha: this.props.t('La ville saisie est invalide')
                                    })}
                                </div>
                                <div className="field">
                                <span className="field-icon">
                                    <FontAwesomeIcon icon="map-marker"/>
                                </span>
                                    <input type="text" name="country" placeholder={this.props.t("Pays *")}
                                           value={this.state.country}
                                           onChange={this.handleInputChange}/>
                                    {this.validator.message('country', this.state.country, 'required|alpha', false, {
                                        required: this.props.t('Le champ est obligatoire'),
                                        alpha: `Le pays saisi est invalide`
                                    })}
                                </div>
                            </div>
                        </div>
                        <div className="vos-droits-content">
                            <div className="field">
                                <strong><Trans i18nKey="valoir_droit">Vous souhaitez faire valoir le
                                    droit</Trans></strong><br/>
                                <em><Trans i18nKey="plusieurs_choix">(plusieurs choix possibles)</Trans></em>
                                <ul className="unstyled">
                                    <li>
                                        <label>
                                            <input type="checkbox" name="you_want[0]"
                                                   value="Accès vos à données personnelles"
                                                   onClick={this.handleYouWant}
                                            />
                                            <Trans i18nKey="donnees_personnelles">Accès vos à données
                                                personnelles</Trans>
                                        </label>
                                    </li>
                                    <li>
                                        <label>
                                            <input type="checkbox" name="you_want[1]"
                                                   value="Rectification de vos données personnelles"
                                                   onClick={this.handleYouWant}/>
                                            <Trans i18nKey="rectification_donnees">Rectification de vos données
                                                personnelles</Trans>
                                        </label>
                                    </li>
                                    <li>
                                        <label>
                                            <input type="checkbox" name="you_want[2]"
                                                   value="Suppression de vos données personnelles"
                                                   onClick={this.handleYouWant}
                                            />
                                            <Trans i18nKey="suppression_donnees">Suppression de vos données
                                                personnelles</Trans>
                                        </label>
                                    </li>
                                    <li>
                                        <label>
                                            <input type="checkbox" name="you_want[3]"
                                                   value="Portabilité de vos données personnelles"
                                                   onClick={this.handleYouWant}
                                            />
                                            <Trans i18nKey="portabilite_donnees">Portabilité de vos données
                                                personnelles</Trans>
                                        </label>
                                    </li>
                                    <li>
                                        <label>
                                            <input type="checkbox" name="you_want[4]"
                                                   value="Limitation de traitement de vos données personnelles"
                                                   onClick={this.handleYouWant}
                                            />
                                            <Trans i18nKey="limitation_donnees">Limitation de traitement de vos données
                                                personnelles</Trans>
                                        </label>
                                    </li>
                                </ul>
                            </div>
                            <div className="field">
                                <strong><Trans i18nKey="vous_etes">Vous êtes : </Trans></strong>
                                <ul className="unstyled">
                                    <li>
                                        <label>
                                            <input type="radio" name="you_are"
                                                   onClick={this.handleYouAre}
                                                   value="Candidat"/>
                                            <Trans i18nKey="candidat">Candidat</Trans>
                                        </label>
                                    </li>
                                    <li>
                                        <label>
                                            <input type="radio" name="you_are"
                                                   onClick={this.handleYouAre}
                                                   value="Ancien Salarié"/>
                                            <Trans i18nKey="ancien_salarie"> Ancien salarié</Trans>
                                        </label>
                                    </li>
                                    <li>
                                        <label>
                                            <input type="radio" name="you_are"
                                                   onClick={this.handleYouAre}
                                                   value="Franchisé / candidat à la franchise"/>
                                            <Trans i18nKey="candidat">Candidat</Trans>
                                        </label>
                                    </li>
                                    <li>
                                        <label>
                                            <input type="radio" name="you_are"
                                                   onClick={this.handleYouAre}
                                                   value="Client"/>
                                            <Trans i18nKey="client">Client</Trans>
                                        </label>
                                    </li>
                                    <li>
                                        <label>
                                            <input type="radio" name="you_are"
                                                   onClick={this.handleYouAre}
                                                   value="Fournisseur"/>
                                            <Trans i18nKey="fournisseur">Fournisseur</Trans>
                                        </label>
                                    </li>
                                    <li>
                                        <label>
                                            <input type="radio" name="you_are"
                                                   onClick={this.handleYouAre}
                                                   value="Other"/>
                                            <Trans i18nKey="autre">Autre</Trans>
                                            <input type="text" name="you_are_other" value={this.state.you_are_other}
                                                   onChange={this.handleYouAreOther}
                                            />
                                        </label>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="vos-droits-content">
                            <div className="col-fields">
                                <div className="field" id="vos-droits-activity-field">
                                    <strong><Trans i18nKey="activite_entite">Activité / Entité
                                        concernée</Trans></strong>
                                    <input type="text" name="activity" placeholder={this.props.t("Activité concernée")}
                                           onChange={(e) => this.setState({
                                               activity: e.target.value
                                           })}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="vos-droits-content">
                            <textarea placeholder={this.props.t("Informations Complémentaires")}
                                      onChange={(e) => this.setState({
                                          more_infos: e.target.value
                                      })}
                            ></textarea><br/>
                        </div>
                        <div className="vos-droits-content" id="vos-droits-more">
                            <div className="col-fields">
                                <div className="field">
                                    <Trans i18nKey='traiter_demande'>Afin que nous puissions traiter votre demande,
                                        merci de nous fournir une copie de votre
                                        carte
                                        d'identite ou passeport. * </Trans><br/>

                                    <label for="btn_text_choose" style={{
                                        backgroundColor: '#e6e3dc',
                                        width: '150px',
                                        height: '40px',
                                        margin: 'auto',
                                        textAlign:'center',
                                        paddingTop:'8px',
                                        borderRadius:'8px',
                                        cursor: 'pointer'
                                    }}>{this.props.t("Choisir un fichier")}</label> <i><strong>{this.state.passport}</strong></i>

                                    <input type="file" name="passport"
                                           id="btn_text_choose"
                                           style={{visibility: "hidden"}}
                                           accept=".jpg,.jpeg,.pdf"
                                           value={this.state.passport}
                                           onChange={this.handleInputChange}/>
                                    {this.validator.message('passport', this.state.passport, 'required', false, {
                                        required: this.props.t('Le champ est obligatoire'),
                                    })}
                                </div>
                            </div>
                        </div>
                        <div>
                            <Trans i18nKey="consulter_information">Pour plus d'informations, veuillez consulter
                                la </Trans>
                            <a href="https://www.yamaha-motor.eu/fr/fr/privacy/privacy-policy/"
                               target="_blank"
                               rel="noopener noreferrer"
                            >
                                <Trans i18nKey="protection_donnees">Politique de protection des
                                    données</Trans></a>.<br/>
                            <br/>
                            {this.state.isLoading && !this.state.sent ? (
                                <div>
                                    <Loader
                                        type="Triangle"
                                        color="#E71D1F"
                                        height="50"
                                        width="50"
                                    /> <span style={{
                                    color: '#E71D1F'
                                }}> Enregistrement en cours, merci de patienter</span>
                                </div>
                            ) : (
                                <div>
                                    {!this.state.sent &&
                                    <input type="submit" className="btn-primary" value={this.props.t("Valider")}
                                           id="vos-droits-submit"/>}

                                    {this.state.sent && <div>
                                        <span style={{
                                            color: '#E71D1F'
                                        }}>Votre demande a bien été prise en compte. Nous prendrons contact avec vous dans les meilleurs délais.</span>
                                    </div>}

                                </div>
                            )}

                            <br/><br/>
                        </div>
                    </div>
                </form>
                <Footer/>
            </div>
        );
    }
}

export default withTranslation()(VosDroits);
