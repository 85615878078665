import React, { Component } from 'react';
//COMPONENTS
import Header from '../staticComponents/header'
import Footer from '../staticComponents/footer'
import EbikeDetails from '../modules/ebikeDetails'

class ebikeDetails extends Component {

  render() {
    return (
      <div>
        <Header />
         <EbikeDetails
            type = 'e-bike'
            nom = {this.props.match.params.nom}
            lang = {this.props.match.params.lang}/>
        <Footer />
      </div>
    );
  }
}

export default ebikeDetails;
