import React, {Component} from 'react';
import {Trans, withTranslation} from "react-i18next";
import '../assets/css/style.css'
import Link from "react-router-dom/Link";
import imgSearchAgency from "../assets/img/bg-agences-de-location.jpg"
import { localStorageAgencyCode } from '../configuration';

class SearchAgency extends Component{
    componentDidMount() {
        localStorage.setItem('localStorageSetShopId', localStorageAgencyCode)
    }
    render(){
        let lang = this.props.i18n.language || 'fr'
        return(
            <div className="container-search-agency">
                <h2 className="dealer_locator_title"
                    id={this.props.t("nos-agences")}
                >
                    <Trans i18nKey="retrouvez">NOS
                    AGENCES DE LOCATION
                    DE SCOOTERS
                    ET MOTOS</Trans>
                </h2>
                <div className="bg-search-agency">
                    {/*<div className="container-img-search-agency"/>*/}
                    <div>
                        <img
                            src={imgSearchAgency}
                            className='img-search-agency'
                            alt="location-moto-scooter-yamaha"/>
                    </div>
                    <div className="content-search-agency">
                        <h2>
                            <Trans
                                i18nKey="title-find-agency"
                            >Trouver une agence de location de moto ou scooter Yamaha Rent
                            </Trans>
                        </h2>
                        <p className="text-search-agency">
                            <Trans
                                i18nKey="text-search-agency">
                                Yamaha Rent est l’offre de location de motos et scooters proposée par Yamaha Motor
                                et son réseau d'agences agréées. C’est la garantie de louer des motos et scooters
                                récents et entretenus, pour partir l’esprit libre !
                            </Trans>
                        </p>
                        <div>
                            <Link to={lang === 'fr' ? 'agences-de-location' : 'rental-agencies'}
                                  className="cta-search-agency"
                            ><Trans i18nKey="cta-rechercher-une-agence">Rechercher une Agence de location</Trans>

                            </Link>
                        </div>

                    </div>
                </div>

            </div>
        )
    }
}

export default withTranslation()(SearchAgency);