import React, { Component } from 'react';
// REDUX
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { saveAllVehiclesAvailable, manageMessageNoAvailableVehicle, saveFilteredTags } from '../redux/action'
//STYLE
import { Container, Row, Col, CustomInput, Input } from 'reactstrap';
//COMPONENTS
import VehicleAvailable from './vehicleAvailable'
import SideInfoAgency from './reservation_sideInfoAgency'
//HELPER
import { getVehiclesFromAgency } from '../helper/getVehiclesFromAgency'
import {getUserIP} from "../services/getIpAdress";

//TRANSLATION
import {Trans, withTranslation} from 'react-i18next';

const _ = require('lodash');


class ListAvailableVehicles extends Component {

    constructor(props) {
        super(props);
        this.state = {
            listAvailableCategorie: [],
            listFilteredVehicles: [],
            A: false,
            A2: false,
            A1: false,
            AM: false,
            tabA: [],
            tabA1: [],
            tabA2: [],
            tabAM: [],
            hasBeenUpdated: 0,
            filterType: 'prix',
            filterOrder: 'asc',
        };
    }

    async componentDidMount() {
        const allAvailableVehicles = await getVehiclesFromAgency(this.props.startDate, this.props.endDate, this.props.selectedAgency.AgenceCode)
        this.setState({ listAvailableCategorie: allAvailableVehicles, listFilteredVehicles: allAvailableVehicles })
        this.props.saveAllVehiclesAvailable(allAvailableVehicles)

        if (allAvailableVehicles.length === 0) {
            this.props.manageMessageNoAvailableVehicle(this.props.t("Aucun véhicule disponible"))
        } else {
            this.props.manageMessageNoAvailableVehicle(<div id="loader">{this.props.t("Recherche en cours...")}</div>)
        }

        this.setState({ filterType: 'prix', filterOrder: 'asc' })
        getUserIP((ip) => {
            this.pushTagGtm(ip)
        })
    }

    componentDidUpdate() {
        if (this.state.hasBeenUpdated !== this.props.hasBeenUpdated) {
            this.setState({
                listAvailableCategorie: this.props.allVehiclesAvailable,
                listFilteredVehicles: this.props.allVehiclesAvailable,
                hasBeenUpdated: this.props.hasBeenUpdated

            })
        }
    }

    /*----tag GTM-----*/
    pushTagGtm(ip){
        const internal = [
            '176.149.189.93',//IP Unitec Fibre
            '82.64.74.58',//IP Unitec Guest
            '82.229.26.224',//IP Eric
            '78.237.92.155',//IP CJA
            '172.19.0.1',
        ]

        let traffic = internal.indexOf(ip) === -1 ? 'externe' : 'interne'

    /*    window.dataLayer = window.dataLayer || [];*/
        window.dataLayer.push({
            'event':'virtualpageview',
            'virtualPath':window.location.pathname,
            'page_name': 'choix_du_vehicule',
            'page_type': 'tunnel_vehicule',
            'category': '',
            'sub_category_lvl1':'',
            'langage': 'fr',
            'country': 'FR',
            'product_name': '',
            'traffic': traffic,
            'agency':this.props.selectedAgency.AgenceCode,
        })
    }

    async handleChange(type, event) {

        const value = event.target.checked
        this.setState({ [type]: value })

        let tabTotalFilter = []
        let tabPermis = ['A', 'A2', 'A1', 'B*', 'AM'];
        let cp = {
            'S1': ["AM","A1","A2","A","B"],
            'S2': ["A1","A2","A","B*"],
            'S3': ["A1","A2","A","B*"],
            'S4': ["A2","A"],
            'S5': ["A2","A"],
            'M1': ["A1","A2","A","B*"],
            'M2': ["A1","A2","A","B*"],
            'M3': ["A2","A"],
            'M4': ["A"],
            'M5': ["A"],
            'M3B': ["A"],
            'E':["AM","A1","A2","A","B*"],
            'VAE1': [this.props.t("Sans permis")],
            'VAE2': [this.props.t("Sans permis")]
        }
        let savedFilter = []

        for (let i = 0; tabPermis.length > i; i++) {
            this.setState({ [tabPermis[i]]: false })
        }

        for (let nPermis = 0; tabPermis.length > nPermis; nPermis++) {            
            if (!this.state[type]) { this.setState({ [type]: true }) }
            if (type === tabPermis[nPermis]) {

                if (!this.state[tabPermis[nPermis]]) {
                    for (let i = 0; this.state.listAvailableCategorie.length > i; i++) {
                        for (let typePermis = nPermis; tabPermis.length > typePermis; typePermis++) {
                            await this.setState({ [tabPermis[typePermis + 1]]: true })
                            if ((this.state.listAvailableCategorie[i].permis === tabPermis[typePermis]
                                || 
                                cp[this.state.listAvailableCategorie[i].categorie].includes(tabPermis[typePermis]))
                                && tabTotalFilter.filter(x=>x.vehicle.ext_id == this.state.listAvailableCategorie[i].vehicle.ext_id).length == 0
                                ) {
                                tabTotalFilter.push(this.state.listAvailableCategorie[i])
                            }
                        }
                    }
                } else {
                    for (let i = 0; this.state.listAvailableCategorie.length > i; i++) {
                        for (let typePermis = nPermis + 1; tabPermis.length > typePermis; typePermis++) {
                            this.setState({ [tabPermis[typePermis]]: true })
                            if ((this.state.listAvailableCategorie[i].permis === tabPermis[typePermis]
                                || 
                                cp[this.state.listAvailableCategorie[i].categorie].includes(tabPermis[typePermis]))
                                && tabTotalFilter.filter(x=>x.vehicle.ext_id == this.state.listAvailableCategorie[i].vehicle.ext_id).length == 0
                                ) {
                                tabTotalFilter.push(this.state.listAvailableCategorie[i])
                            }
                        }
                    }
                }
            }
            if (this.state[tabPermis[nPermis]]) {
                savedFilter.push(tabPermis[nPermis])
            } else {
                if (!value) {
                    let idx = savedFilter.indexOf(type)
                    savedFilter.slice(idx, 1)
                }
            }
        }
        if (tabTotalFilter.length === 0) {
            this.props.manageMessageNoAvailableVehicle(this.props.t("Aucun véhicule ne correspond à ces critères de recherche"))
        } else {
            this.props.manageMessageNoAvailableVehicle(<div id="loader">{this.props.t("Recherche en cours...")}</div>)
        }
        this.setState({ listFilteredVehicles: tabTotalFilter }, () => {
        })

        this.props.saveAllVehiclesAvailable(tabTotalFilter)
        this.props.saveFilteredTags(savedFilter)
    }

    orderByFilter = (type, event) => {
        let filter = []
        
        if (type === 'type') {
            filter = _.orderBy(this.props.allVehiclesAvailable, [event.target.value], [this.state.filterOrder]);
            this.setState({ filterType: event.target.value })     
        } else {
            filter = _.orderBy(this.props.allVehiclesAvailable, [this.state.filterType], [event.target.value]);
            this.setState({ filterOrder: event.target.value })
        }

        this.setState({ listFilteredVehicles: filter }, () => {
        })
        
        this.props.saveAllVehiclesAvailable(filter)
    }

    render() {
        const permisA=<Trans i18nKey="permis_A">"Permis A"</Trans>
        const permisA2=<Trans i18nKey="permis_A2">"Permis A2"</Trans>
        const permisA1=<Trans i18nKey="permis_A1">"Permis A1"</Trans>
        const permisB1=<Trans i18nKey="permis_B1">"Permis B*"</Trans>
        // const permisB=<Trans i18nKey="permis_B">"Permis B"</Trans>
        const permisAM=<Trans i18nKey="permis_AM">"Permis AM"</Trans>

        let isTablet = window.innerWidth < 768
        return (
            <div>
                <Container id="allVehicles">
                    <Row>
                        <SideInfoAgency isTablet={isTablet ? 'none' : 'block'} />
                        <Col md="8">
                            <h1><Trans i18nKey="choix_vehicule">Choix du véhicule</Trans></h1>

                            <p style={{ fontSize: '13px' }}><Trans i18nKey="en_cas_de_permis">En cas de permis étranger, se rapprocher de
                                votre agence Yamaha Rent pour valider la location</Trans></p>
                            <Row id="filter">
                                <Col md="4"><CustomInput checked={this.state.A}
                                    onChange={this.handleChange.bind(this, 'A')} type="checkbox"
                                    id="permisA" label={permisA} inline /></Col>
                                <Col md="4"><CustomInput checked={this.state.A2}
                                    onClick={this.handleChange.bind(this, 'A2')} type="checkbox"
                                    id="permisA1" label={permisA2} inline /></Col>
                                <Col md="4"><CustomInput checked={this.state.A1}
                                    onChange={this.handleChange.bind(this, 'A1')} type="checkbox"
                                    id="permisB" label={permisA1} inline /></Col>
                                <Col md="4"><CustomInput checked={this.state['B*']}
                                    onChange={this.handleChange.bind(this, 'B*')} type="checkbox"
                                    id="permisS" label={permisB1} inline /></Col>
                                {/* <Col md="4"><CustomInput checked={this.state.B}
                                    onChange={this.handleChange.bind(this, 'B')} type="checkbox"
                                    id="permisE" label={permisB} inline /></Col> */}
                                <Col md="4"><CustomInput checked={this.state.AM}
                                    onChange={this.handleChange.bind(this, 'AM')} type="checkbox"
                                    id="permisAM" label={permisAM} inline /></Col>
                                <Col md="8"></Col>
                                <Col xs="12" style={{ margin: '15px 0px', fontSize: 14 }}><Trans i18nKey="2_ans_permis">* 2 ans de permis et 7h de formation</Trans></Col>
                                <Col md="6"><Input type="select" name="select" onChange={this.orderByFilter.bind(this, 'type')}>
                                    <option value="tarif">{this.props.t("Prix")}</option>
                                    <option value="cylindree">{this.props.t("Cylindrée")}</option>
                                </Input></Col>
                                <Col md="6"><Input type="select" name="order" onChange={this.orderByFilter.bind(this, 'order')}>
                                    <option value="asc">{this.props.t("croissant")}</option>
                                    <option value="desc">{this.props.t("décroissant")}</option>
                                </Input></Col>

                            </Row>
                            {this.props.allVehiclesAvailable.length > 0 ? this.props.allVehiclesAvailable.length + this.props.t(' résultat(s)') : ''}


                            {this.props.allVehiclesAvailable.length > 0 ? this.props.allVehiclesAvailable.map((val, i) => {

                                return (
                                    <VehicleAvailable key={i} nom={val.nomVehicule} tarif={val.tarif}
                                        allInfoVehicle={val} />
                                )
                            }) : this.props.noVehicleMessage}
                        </Col>
                        <SideInfoAgency isTablet={isTablet ? 'block' : 'none'} />

                    </Row>
                </Container>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        startDate: state.reducer.startDate,
        endDate: state.reducer.endDate,
        cityPosition: state.reducer.cityPosition,
        selectedAgency: state.reducer.selectedAgency,
        allVehiclesAvailable: state.reducer.allVehiclesAvailable,
        hasBeenUpdated: state.reducer.vehicleListHasBeenUpdated,
        noVehicleMessage: state.reducer.noVehicleMessage,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        saveAllVehiclesAvailable,
        manageMessageNoAvailableVehicle,
        saveFilteredTags,
    }, dispatch)
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ListAvailableVehicles));
