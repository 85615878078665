import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import axios from "axios";
import i18n from "../i18n";

// REDUX
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
    changeSectionReservation,
    setPromoPrice, setPromoCode, setGlobalPrice
} from '../redux/action'
import {
    ENVIRONMENT,
    SERVER_ADDRESS_PACK_CADEAU,
    SERVER_ADDRESS_PACK_CADEAU_PP
} from "../configuration";
//DAY PICKER
import { formatDate } from 'react-day-picker/moment';

//STYLE
import { Container, Row, Col, Button } from 'reactstrap';
import { getUserIP } from "../services/getIpAdress";

//TRANSLATION
import { Trans, withTranslation } from 'react-i18next';
import { IdTagGeolid } from "../services/analytics/geolid/idTagGeolid";
import { stringToBoolean } from "../helper/stringToBoolean";
import { bookingTotalPriceSelector } from "../redux/selectors";

const moment = require('moment');

class Recap extends Component {
    constructor(props) {
        super(props);

        this.state = {
            bookingPromoPrice: 0,
            globalPrice: 0,
        }

        this.ValidateResaWithPromo = this.ValidateResaWithPromo.bind(this);
    }

    componentDidMount() {
        this.props.setPromoCode("");

        const isTagGeolidSended = stringToBoolean(
            localStorage.getItem('isRecapBookingGeolidTagSended')
        )

        getUserIP((ip) => {
            this.pushTagGtm(ip)
        })

        this.pushTagGtmEbusiness()

        if (!isTagGeolidSended) {
            localStorage.setItem('isRecapBookingGeolidTagSended', true)
            this.pushTagGeolid()
        }
    }

    componentWillMount() {
        if (this.props.bookingPromoPrice != 0 && this.props.globalPrice != 0) {
            this.ValidateResaWithPromo();
        } else {
            this.setState({ bookingPromoPrice: this.props.bookingPromoPrice });
            this.setState({ globalPrice: this.props.globalPrice });

            this.props.setPromoCode("");
            this.props.setPromoPrice(0);
            this.props.setGlobalPrice(0);

            setTimeout(() => {
                localStorage.setItem('storeRedux', JSON.stringify(this.props.store));
            }, 500);
        }

        // if (JSON.parse(localStorage.getItem('storeRedux'))) {
        //     if (this.props.store.startDateUnformated.length === 0 && JSON.parse(localStorage.getItem('storeRedux')).selectedAgency) {
        //         this.props.storeToUpdate(JSON.parse(localStorage.getItem('storeRedux')))
        //     }
        // } else {
        //     this.redirectToHomepage()
        // }
    }

    ValidateResaWithPromo() {
        const bookingReference = localStorage.getItem("bookingReference");
        // e.preventDefault();
        const globalPrice = this.props.globalPrice;

        return axios({
            method: "POST",
            url: (ENVIRONMENT == "PROD" ? SERVER_ADDRESS_PACK_CADEAU : SERVER_ADDRESS_PACK_CADEAU_PP) + "pack_cadeau_confirmation&amountT=" + this.props.bookingTotalPrice + "&code_promo=" + this.props.code_promo + "&reference=" + bookingReference,
            headers: {
                "Accept-Language": i18n.language === undefined ? "fr" : i18n.language,
                "Content-Type": "application/json",
            },
        })
            .then((response) => {
                if (response.data != null && response.data != undefined && response.data != "") {
                    this.setState({ bookingPromoPrice: this.props.bookingPromoPrice });
                    this.setState({ globalPrice: this.props.globalPrice });

                    this.props.setPromoCode("");
                    this.props.setPromoPrice(0);
                    this.props.setGlobalPrice(0);
                    
                    setTimeout(() => {
                        localStorage.setItem('storeRedux', JSON.stringify(this.props.store));
                    }, 500);
                }
            })
            .catch((error) => {
            });
    }

    /*----tag GTM-----*/
    pushTagGtm(ip) {
        const internal = [
            '176.149.189.93',//IP Unitec Fibre
            '82.64.74.58',//IP Unitec Guest
            '82.229.26.224',//IP Eric
            '78.237.92.155',//IP CJA
            '172.19.0.1',
        ]

        let traffic = internal.indexOf(ip) === -1 ? 'externe' : 'interne'

        window.dataLayer.push({
            'event': 'virtualpageview',
            'virtualPath': window.location.pathname + '/recapitulatif',
            'page_name': 'confirmation_paiement',
            'page_type': 'tunnel_recapitulatif',
            'category': this.getVehicleType(),
            'sub_category_lvl1': this.props.selectedVehicle.cylindree.toString(),
            'langage': 'fr',
            'country': 'FR',
            'product_name': this.props.selectedVehicle.nomVehicule.toLowerCase().replace(/ /g, '_'),
            'traffic': traffic,
            'agency': this.props.selectedAgency.AgenceCode,
        })
    }

    pushTagGtmEbusiness() {
        /*----tag GTM-----*/
        window.dataLayer.push({
            'ecommerce': {
                'purchase': {
                    'actionField': {
                        'id': localStorage.getItem('bookingReference'),
                        'revenue': this.getTotalPrice().toString(),
                        'currency': 'EUR',
                    },
                    'products': [{
                        'name': this.props.selectedVehicle.nomVehicule.toLowerCase().replace(/ /g, '_'),
                        'id': this.props.selectedVehicle.vehicle.ext_id,
                        'price': this.props.selectedVehicle.tarif.toString(),
                        'brand': this.props.selectedVehicle.vehicle.brand,
                        'category': this.getVehicleType(),
                        'variant': this.props.selectedVehicle.cylindree.toString(),
                        'quantity': 1,
                        'dimension10': this.props.selectedAgency.AgenceCode,
                    }]
                }
            }
        });
    }

    pushTagGeolid() {
        IdTagGeolid.setShopId(localStorage.getItem('localStorageSetShopId'))

        if (this.props.infoConducteur != null) {
            if ((this.props.infoConducteur.email != "" && this.props.infoConducteur.email != null)
                && (this.props.infoConducteur.tel != "" && this.props.infoConducteur.tel != null)
                && (this.props.infoConducteur.prenom != "" && this.props.infoConducteur.prenom != null)
                && (this.props.infoConducteur.nom != "" && this.props.infoConducteur.nom != null)) {
                const gt = IdTagGeolid.getGt();

                console.log("Before send lead");

                //PYSW-116 - on commente l'appel de sendlead => Rollback suite mise en prod
                gt('sendLead', 'form-confirmed-booking', {
                    'last_name': this.props.infoConducteur.nom,
                    'first_name': this.props.infoConducteur.prenom,
                    'address': this.props.infoConducteur.adresse,
                    'zip_code': this.props.infoConducteur.cp,
                    'city': this.props.infoConducteur.ville,
                    'email': this.props.infoConducteur.email,
                    'phone': this.props.infoConducteur.tel,
                    'age': this.props.infoConducteur.age,
                    'licence': this.props.infoConducteur.permis,
                })

                console.log("After send lead");
            }
        }
    }

    getVehicleType() {
        const typeCode = this.props.selectedVehicle.type

        switch (typeCode) {
            case 'sco':
                return 'Scooter'
            case 'mot':
                return 'Moto'
        }
    }

    getTotalDaysDifference() {
        var date1 = new Date(this.props.startDate);
        var date2 = new Date(this.props.endDate);
        var timeDiff = Math.abs(date2.getTime() - date1.getTime());
        var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24))

        return diffDays
    }

    getTotalPrice() {
        return this.state.bookingPromoPrice != 0 ? this.state.globalPrice : this.props.bookingTotalPrice.toFixed(2)
    }

    getTotalSuppKmPrice() {
        return Math.round(
            this.props.selectedVehicle ? this.props.selectedVehicle.prixKm * this.props.addedKm : null
        ).toFixed(2)
    }

    replaceDotbyComma = (num) => {
        let string = num.toString()
        return string.replace('.', ',');
    }

    capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    get dayStartDateTranslated() {
        let choosenLanguage = this.props.i18n.language

        if (choosenLanguage === 'fr') {
            return `${formatDate(this.props.startDateUnformated, 'dddd' + ' ' + 'LL', 'fr')}`
        } else {
            return `${formatDate(this.props.startDateUnformated, 'dddd' + ' ' + 'LL', 'en')}`
        }
    }

    get dayEndDateTransLated() {
        let choosenLanguage = this.props.i18n.language

        if (choosenLanguage === 'fr') {
            return `${formatDate(this.props.endDateUnformated, 'dddd' + ' ' + 'LL', 'fr')}`
        } else {
            return `${formatDate(this.props.endDateUnformated, 'dddd' + ' ' + 'LL', 'en')}`
        }
    }

    get deductible() {
        const deductible = this.props.selectedVehicle.usol_tarif.filter((service) => service.code === 'prix_franchise_dom')
        return parseFloat(deductible[0].price_with_VAT).toFixed(2)
    }

    render() {
        let dayStartDate = this.dayStartDateTranslated
        let dayEndDate = this.dayEndDateTransLated

        return (
            <Container id="allVehicles" className="recap">
                <h1><Trans i18nKey="recap_commande">Récapitulatif de la commande</Trans></h1>
                <div id="sectionGreen">
                    <h2><Trans i18nKey="reservation_confirmee">Votre réservation est confirmée.</Trans></h2>
                    <h2><Trans i18nKey="votre_paiement">Votre paiement
                        de </Trans>{this.replaceDotbyComma(this.getTotalPrice())} €<Trans i18nKey="validee"> a été
                            validé.</Trans></h2>

                    <p><Trans i18nKey="email_confirm">Un email récapitulatif vous à été envoyé à l'adresse suivante
                        : </Trans>{this.props.infoConducteur.email}</p>
                    <p><Trans i18nKey="un_depot_de_garantie">Un dépôt de garantie de </Trans>{this.replaceDotbyComma(this.deductible)} euros <Trans i18nKey="vous_sera_demande">vous sera demandé en agence</Trans></p>
                </div>
                <div id="sectionBlue">
                    <h2><Trans i18nKey="doc_a_fournir">Documents à fournir pour le retrait du véhicule</Trans></h2>
                    <p>
                        • <Trans i18nKey="piece_id">Pièce d’identité</Trans><br />
                        • <Trans i18nKey="permis_formation">Permis de conduire (ou attestation de formation, lors d’une
                            location d’un 125)</Trans><br />
                        • <Trans i18nKey="justif_dom">Justificatif de domicile de moins de 3 mois</Trans><br />
                        • <Trans i18nKey="cb">Carte bancaire</Trans>
                    </p>
                    <p><Trans i18nKey="attention_la_carte">Attention, la carte qui servira à la prise de caution en
                        agence devra être la même que celle
                        utilisée dans cette étape de paiement.</Trans></p>
                </div>

                <Row className="vehicleAvailable">
                    <Col xs="4">
                        <h2>{this.props.selectedAgency.Intitule}</h2>
                        <p>
                            {this.props.selectedAgency.Addresse} <br />
                            {this.props.selectedAgency.CodePostal} {this.props.selectedAgency.Ville}<br />
                            Tél : {this.props.selectedAgency.Telephone}<br />
                            {this.props.selectedAgency.Email}<br />

                        </p>
                    </Col>
                    <Col xs="8">
                        <div className="flex" style={{ 'marginBottom': '25px', marginTop: 25 }}>
                            <div className="column" style={{ 'flexGrow': '2', textAlign: 'center' }}>
                                <Trans i18nKey="depart"> Départ</Trans>

                                <h2>{this.capitalizeFirstLetter(dayStartDate)}</h2>
                                <Trans i18nKey="a_partir"> à partir
                                    de </Trans>{moment(this.props.startDate).get('hour')} h 00
                            </div>
                            <div className="column" style={{ 'flexGrow': '1', textAlign: 'center' }}><span
                                className="grey">⟶</span></div>
                            <div className="column" style={{ 'flexGrow': '2', textAlign: 'center' }}>
                                <Trans i18nKey="retour">Retour</Trans>
                                <h2>{this.capitalizeFirstLetter(dayEndDate)}</h2>
                                <Trans i18nKey='jusqu_a'>jusqu'à </Trans>{moment(this.props.endDate).get('hour')} h 00
                            </div>
                        </div>
                        <Row>
                            <Col xs="5"><img alt="véhicule sélectionné" src={this.props.selectedVehicle.img} /></Col>
                            <Col xs="4" style={{ background: 'none' }}><h2 className="red" style={{ 'fontSize': '25px' }}>
                                {this.props.selectedVehicle.nomVehicule}</h2>
                            </Col>
                            <Col xs="3" className="tarif">{this.replaceDotbyComma(this.getTotalPrice())} €</Col>
                            <p className="grey alignWithBtn ml-3" style={{ fontSize: 10 }}>
                                <Trans i18nKey="photo">Photo non contractuelle, la version et le coloris peuvent
                                    différer en fonction des
                                    disponibilités en agence.</Trans>
                            </p>
                        </Row>
                        <div className="optionRecap">
                            <Trans i18nKey="options">Options</Trans>
                        </div>
                        <p> • <Trans i18nKey="rachat_franchise">Rachat partiel de franchise</Trans>
                            : {this.replaceDotbyComma((this.props.selectedVehicle.franchise).toFixed(2))} €<br />
                            • <Trans i18nKey="forfait_km">Forfait
                                kilométrique </Trans>{this.props.selectedVehicle ? +this.props.selectedVehicle.kmInclu + this.props.addedKm : null} km
                            <Trans
                                i18nKey="dont">(dont </Trans>{this.props.selectedVehicle ? this.props.selectedVehicle.kmInclu : null} km <Trans
                                    i18nKey="kms_offerts">offerts</Trans>)
                            : {this.replaceDotbyComma(this.getTotalSuppKmPrice())} €<br />
                            {this.props.priceOption1 !== 0 && this.props.selectedVehicle
                                ? '• ' + this.props.t(this.props.selectedVehicle.prestation[0].Label) + ' : ' + (this.props.priceOption1 * this.props.selectedVehicle.totalDays) + ',00 €'
                                : null}<br />
                            {this.props.priceOption2 !== 0 && this.props.selectedVehicle
                                ? '• ' + (this.props.priceOption2 / this.getTotalDaysDifference()) / this.props.selectedVehicle.prestation[1].Tarifs.TTC + ' ' + this.props.t(this.props.selectedVehicle.prestation[1].Label) + ' : ' + this.props.priceOption2 + ',00 €'
                                : null}<br />
                            {this.props.priceOption3 !== 0 && this.props.selectedVehicle
                                ? '• ' + (this.props.priceOption3 / this.getTotalDaysDifference()) / this.props.selectedVehicle.prestation[2].Tarifs.TTC + ' ' + this.props.t(this.props.selectedVehicle.prestation[2].Label) + ' : ' + this.props.priceOption3 + ',00 €'
                                : null}<br /></p>
                    </Col>
                </Row>
                <Link to="/"><Button className="roundBtn" color="primary" size="lg" block><Trans i18nKey="Accueil">Retour
                    à l'accueil</Trans> ⟶</Button></Link>
                <br /><br />
            </Container>
        );
    }
}

function mapStateToProps(state) {
    return {
        store: state.reducer,
        bookingTotalPrice: bookingTotalPriceSelector(state),
        code_promo: state.reducer.code_promo,
        globalPrice: state.reducer.globalPrice,
        bookingPromoPrice: state.reducer.promoPrice,
        startDateUnformated: state.reducer.startDateUnformated,
        endDateUnformated: state.reducer.endDateUnformated,
        selectedAgency: state.reducer.selectedAgency,
        startDate: state.reducer.startDate,
        endDate: state.reducer.endDate,
        cityPosition: state.reducer.cityPosition,
        allVehiclesAvailable: state.reducer.allVehiclesAvailable,
        selectedVehicle: state.reducer.selectedVehicle,
        addedKm: state.reducer.addedKm,
        infoConducteur: state.reducer.infoConducteur,
        priceOption1: state.reducer.priceOption1,
        priceOption2: state.reducer.priceOption2,
        priceOption3: state.reducer.priceOption3,
        sectionReservation: state.reducer.sectionReservation
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        changeSectionReservation,
        setPromoPrice,
        setPromoCode,
        setGlobalPrice
    }, dispatch)
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Recap));
