import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import en from './translations/en'

// the translations
// (tip move them in a JSON file and import them)
const resources = {
    en: {
        translation: {
            ...en
        }
    }
};

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        fallbackLng: "fr",
        /*lng: "en",*/

        keySeparator: false, // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false // react already safes from xss
        }
       /* fallbackLng: 'en',
        debug: true,

        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        }*/
    });

export default i18n;