import React, { Component } from "react";
import axios from "axios";
// REDUX
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  changeSectionReservation,
  saveConducteurInfo,
  saveReference,
  setPromoPrice,
  setPromoCode,
  saveDevisStatus
} from "../redux/action";
//STYLE
import { Container, Row, Col, Input, CustomInput, Button } from "reactstrap";
//COMPONENTS
import SideInfoAgency from "./reservation_sideInfoAgency";
//HELPER
import { stringToBoolean } from "../helper/stringToBoolean";
//DOC
import { ENVIRONMENT,BABEL_V2_URL, BABEL_V2_URL_VERSION,BABEL_V2_URL_PP, BABEL_V2_URL_VERSION_PP,SERVER_ADDRESS_PACK_CADEAU,SERVER_ADDRESS_PACK_CADEAU_PP, SERVER_ASSETS, SERVER_ASSETS_PP } from "../configuration";
import Popup from "./popup";
import Loader from "react-loader-spinner";
import { BookingCartCreationFailedException } from "../services/bookingCart/exceptions/BookingCartCreationFailedException";
import { Crashlytics } from "../services/crashlytics/Crashlytics";
import { getUserIP } from "../services/getIpAdress";

//TRANSLATE
import { Trans, withTranslation } from "react-i18next";
import i18n from "../i18n";
import { withRouter } from "react-router-dom";
import { bookingTotalPriceSelector } from "../redux/selectors";

class Conducteur extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCheckoutTagPushed: false,
      showErrorMessage: false,
      isPendingState: false,
      civilite: props.infoConducteur.civilite,
      nom: props.infoConducteur.nom,
      prenom: props.infoConducteur.prenom,
      adresse: props.infoConducteur.adresse,
      cp: props.infoConducteur.cp,
      ville: props.infoConducteur.ville,
      email: props.infoConducteur.email,
      tel: props.infoConducteur.tel,
      age: props.infoConducteur.age,
      check: props.infoConducteur.check,
      rgpd: props.infoConducteur.rgpd,
      permis: props.infoConducteur.permis,
      erreur: "",
      emptyInput: "",
      code_promo_error: "",
      code_promo: "",
      code_promo_before: 0,
      code_promo_after: 0,
      devisStatus: false,
      availablePermis: {
        M4: {
          23: ["A"],
          25: ["A"],
        },
        S4: {
          23: ["A"],
          25: ["A"],
        },
        M5: {
          23: [],
          25: ["A"],
        },
        S5: {
          23: [],
          25: ["A"],
        },
        M3: {
          23: [],
          25: ["A2", "A"],
        },
        S3: {
          23: [],
          25: ["A2", "A"],
        },
        M1: {
          23: [],
          25: ["A1", "A2", "A", "AM", "B+formation"],
        },
        M2: {
          23: [],
          25: ["A1", "A2", "A", "AM", "B+formation"],
        },
        S1: {
          23: [],
          25: ["A1", "A2", "A", "AM", "B+formation"],
        },
        S2: {
          23: [],
          25: ["A1", "A2", "A", "AM", "B+formation"],
        },
      },
    };
    this.goToOptions();
  }

  componentDidMount() {
    if(this.props.selectedVehicle.categorie === "VAE1" || this.props.selectedVehicle.categorie === "VAE2"){
      this.setState({permis: 'Sans permis'});
    }
    this.setBrowserBackButtonClick(3);
    getUserIP((ip) => {
      this.pushTagGtm(ip);
    });
    this.pushTagGtmEbusiness();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.devisStatus !== this.props.devisStatus) {
      let devis_ = this.props.devisStatus == false && !this.state.erreur ? this.callMissingError():null;
    }
}

  goToOptions() {
    var conducteur = JSON.parse(sessionStorage.getItem("conducteur"));
    if (conducteur && conducteur != null && conducteur != "") {
      this.state.email = conducteur.email.toString();
      this.state.civilite = conducteur.gender;
      this.state.prenom = conducteur.first_name.toString();
      this.state.nom = conducteur.last_name.toString();
      this.state.tel = conducteur.phone_number;
      this.state.age = conducteur.age_range;
      this.state.permis = conducteur.driving_license_category;
      this.state.adresse = conducteur.address.toString();
      this.state.cp = conducteur.zip;
      this.state.ville = conducteur.city.toString();
    }
  }
  /*----tag GTM-----*/
  pushTagGtm(ip) {
    const internal = [
      "176.149.189.93", //IP Unitec Fibre
      "82.64.74.58", //IP Unitec Guest
      "82.229.26.224", //IP Eric
      "78.237.92.155", //IP CJA
      "172.19.0.1",
    ];

    let traffic = internal.indexOf(ip) === -1 ? "externe" : "interne";

    /*   window.dataLayer = window.dataLayer || [];*/
    window.dataLayer.push({
      event: "virtualpageview",
      virtualPath: window.location.pathname + "conducteur",
      page_name: "informations_conducteur",
      page_type: "tunnel_conducteur",
      category: this.getVehicleType(),
      sub_category_lvl1: this.props.selectedVehicle.cylindree.toString(),
      langage: "fr",
      country: "FR",
      product_name: this.props.selectedVehicle.nomVehicule
        .toLowerCase()
        .replace(/ /g, "_"),
      traffic: traffic,
      agency: this.props.selectedAgency.AgenceCode,
    });
  }

  pushTagGtmEbusiness() {
    if (!this.state.isCheckoutTagPushed) {
      /*----tag GTM-----*/
      window.dataLayer.push({
        event: "checkout",
        ecommerce: {
          checkout: {
            actionField: { step: 2, option: "" },
            products: [
              {
                name: this.props.selectedVehicle.nomVehicule
                  .toLowerCase()
                  .replace(/ /g, "_"),
                id: this.props.selectedVehicle.vehicle.ext_id,
                price: this.props.bookingTotalPrice.toString(),
                brand: this.props.selectedVehicle.vehicle.brand,
                category: this.getVehicleType(),
                variant: this.props.selectedVehicle.cylindree.toString(),
                quantity: 1,
                dimension10: this.props.selectedAgency.AgenceCode,
              },
            ],
          },
        },
      });
      this.state.isCheckoutTagPushed = true;
    }
  }

  getVehicleType() {
    const typeCode = this.props.selectedVehicle.type;

    switch (typeCode) {
      case "sco":
        return "Scooter";
      case "mot":
        return "Moto";
    }
  }

  onBrowserBackButtonClick = (step) => {
    this.props.history.go(1);
    this.handleChangeSection(step);
  };

  setBrowserBackButtonClick = (step) => {
    this.props.history.listen((location, action) => {
      if (action === "POP") {
        this.onBrowserBackButtonClick(step);
      }
    });
    //window.addEventListener("popstate", this.onBrowserBackButtonClick.bind(this, step))
  };

  handleChange(type, event) {
    this.setState({ erreur: "" });
    if (type === "rgpd" || type === "check") {
      this.setState({ [type]: event.target.checked });
    } else {
      this.setState({ [type]: event.target.value });
    }

    if (type === "age") {
      this.handleChangePermis(event.target.value);
    }

    setTimeout(() => {
      this.handlesaveConducteurInfo(this);
    }, 300);
  }

  handleChangePermis(age) {}

  handlesaveConducteurInfo() {
    let objConducteur = { ...this.state };
    this.props.saveConducteurInfo(objConducteur);
    return true;
  }
  missingInput() {
    let message = (
      <Trans i18nKey="infos_manquantes">
        'Informations manquantes dans le formulaire'
      </Trans>
    );
    let emptyInput = (
      <Trans i18nKey="champ_obligatoire">'Ce champ est obligatoire'</Trans>
    );
    let msgRGPD = (
      <Trans i18nKey="accept_RGPD">
        "Veuillez accepter le Réglement Général pour la Protection des Données"
      </Trans>
    );
    let msgLocation = (
      <Trans i18nKey="accept_CGL">
        "Veuillez accepter les conditions générales de location"
      </Trans>
    );
    this.setState({ erreur: message, emptyInput, msgLocation, msgRGPD });
  }

  buildCreateBookingCartRequest() {
    let usolPrestations = this.props.usolServices.filter((service) => {
      return (
        service.code === "assZen" ||
        service.code === "location" ||
        service.code === "assRachat" ||
        service.code === "prix_franchise_dom"
      );
    });

    let services = usolPrestations.map((prestation) => {
      let service = { ...prestation };
      let multiplicator = 1;
      if (
        service.code !== "location" &&
        service.code !== "prix_franchise_dom"
      ) {
        multiplicator = this.props.selectedVehicle.totalDays;
      }
      if (service.code === "assZen" && this.props.priceOption1 > 0) {
        service.subscription = true;
      }

      service.subscription = stringToBoolean(service.subscription);
      service.price_with_VAT = service.price_with_VAT;
      service.price_with_VAT =
        Math.round(service.price_with_VAT * 100) * multiplicator;
      service.price_without_VAT =
        Math.round(service.price_without_VAT * 100) * multiplicator;
        
      return service;
    });

    var bookingTotalPrice = this.props.bookingTotalPrice;
    bookingTotalPrice = parseFloat(this.props.bookingPromoPrice) > 0 ? parseFloat(this.props.globalPrice) : bookingTotalPrice;
    if(bookingTotalPrice < 0){
      bookingTotalPrice = -bookingTotalPrice;
    }

    return {
      is_devis:false,
      periods: [
        {
          start: this.props.startDate.replace("T", " "),
          end: this.props.endDate.replace("T", " "),
        },
      ],
      duration: this.props.selectedVehicle.totalDays,
      price_with_VAT: bookingTotalPrice * 100,
      included_distance: this.props.selectedVehicle.kmInclu,
      chosen_distance: this.props.addedKm + this.props.selectedVehicle.kmInclu,
      additional_distance_price_with_VAT:
        this.props.addedKm * +this.props.selectedVehicle.prixKm * 100,
      user: {
        email: this.state.email,
        gender: this.state.civilite,
        first_name: this.state.prenom,
        last_name: this.state.nom,
        phone_number: this.state.tel,
        age_range: this.state.age,
        driving_license_category: this.state.permis,
        address: this.state.adresse,
        zip: this.state.cp,
        city: this.state.ville,
      },
      vehicle: this.props.selectedVehicle.vehicle,
      agency: {
        code: this.props.selectedAgency.AgenceCode,
        label: this.props.selectedAgency.Intitule,
        email: this.props.selectedAgency.Email,
        phone_number: this.props.selectedAgency.Telephone,
        address: this.props.selectedAgency.Addresse,
        zip: this.props.selectedAgency.CodePostal,
        city: this.props.selectedAgency.Ville,
      },
      tariff_title: this.props.selectedVehicle.tariff_title,
      services,
      accessories: [
        {
          key: "gloves",
          quantity:
            this.props.priceOption2 /
            this.props.selectedVehicle.prestation[1].Tarifs.TTC,
          price_with_VAT: this.props.priceOption2 * 100,
        },
        {
          key: "helmet",
          quantity:
            this.props.priceOption3 /
            this.props.selectedVehicle.prestation[2].Tarifs.TTC,
          price_with_VAT: this.props.priceOption3 * 100,
        },
      ],
    };
  }

  async wait(time) {
    return await new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve();
      }, time);
    });
  }

  async createBookingCart(requestBody) {
    return await axios({
      method: "POST",
      url: (ENVIRONMENT == "PROD" ? BABEL_V2_URL : BABEL_V2_URL_PP) + "/" + (ENVIRONMENT == "PROD" ? BABEL_V2_URL_VERSION : BABEL_V2_URL_VERSION_PP) + "/bookings",
      headers: {
        "Accept-Language": i18n.language === undefined ? "fr" : i18n.language,
        "Content-Type": "application/json",
      },
      data: requestBody,
    })
      .then((response) => response)
      .catch((error) => {
        throw new BookingCartCreationFailedException(
          "Booking creation failed",
          error
        );
      });
  }

  async handleChangeSection(position) {
    this.togglePendingState();
    window.scroll(0, 0);

    if (position && typeof position === "number") {
      this.props.changeSectionReservation(position);
    } else {
      /*this.pushTagGtmEbusiness()*/
      const requestBody = this.buildCreateBookingCartRequest();
      let response;
      try {
        response = await this.createBookingCart(requestBody);
        this.togglePendingState();
        localStorage.setItem("bookingReference", response.data.data.reference);
        this.props.saveReference(response.data.data.reference);
        let objConducteur = { ...this.state };
        this.props.saveConducteurInfo(objConducteur);
        this.props.changeSectionReservation(5);
      } catch (e) {
        Crashlytics.captureException(e);
        this.togglePendingState();
        this.toggleErrorPopup();
      }
    }
  }

  
  async ApplyPromoCode() {
    return await axios({
      method: "POST",
      url: (ENVIRONMENT == "PROD" ? SERVER_ADDRESS_PACK_CADEAU : SERVER_ADDRESS_PACK_CADEAU_PP) +"pack_cadeau_code&code_promo="+this.state.code_promo,
      headers: {
        "Accept-Language": i18n.language === undefined ? "fr" : i18n.language,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if(response.data != null && response.data != undefined && response.data != "") {
          
        this.setState({
          code_promo_before: response.data.PriceCadeau,
        });
        var codepromoafter = this.props.bookingTotalPrice - response.data.PriceCadeau;
        this.setState({
          code_promo_after: codepromoafter > 0 ? 0 : -codepromoafter,
        });      

        if(response.data.PriceCadeau == 0){
          this.setState({
            code_promo_error: "Votre solde est insuffisant.",
          });
        }else{
          this.setState({
            code_promo_error: "",
          });
        }

        this.props.setPromoCode(this.state.code_promo);
        this.props.setPromoPrice(parseFloat(response.data.PriceCadeau).toFixed(2));
        localStorage.setItem('storeRedux', JSON.stringify(this.props.store))
      }else{
        this.setState({
          code_promo_error: "Ce code ne correspond à aucune carte cadeau active. A-t-il été entré correctement ?",
        });
        this.props.setPromoCode("");
        this.props.setPromoPrice(0);
        this.setState({
          code_promo_before: 0,
        });
      }
      })
      .catch((error) => {
   
      });
  }

  toggleErrorPopup() {
    this.setState({
      showErrorMessage: !this.state.showErrorMessage,
    });
  }

  togglePendingState() {
    this.setState({
      isPendingState: !this.state.isPendingState,
    });
  }

  get hrefChangeLanguage() {
    let choosenLanguage = this.props.i18n.language;
    if (choosenLanguage === "fr") {
      return "https://www.yamaha-motor.eu/fr/fr/privacy/privacy-policy/";
    } else {
      return "https://www.yamaha-motor.eu/fr/fr/en/privacy/legal-statement/";
    }
  }

  get hrefChangeLanguageCgl() {
    let choosenLanguage = this.props.i18n.language;
    if (choosenLanguage === "fr") {
      return (ENVIRONMENT == "PROD" ? SERVER_ASSETS : SERVER_ASSETS_PP) +"/doc/conditions-generales-de-location.pdf";
    } else {
      return (ENVIRONMENT == "PROD" ? SERVER_ASSETS : SERVER_ASSETS_PP) +"/doc/conditions-generales-de-location-en.pdf";
    }
  }
  callMissingError() {
    document.getElementById("deviserror").click();
}

  render() {
    let lang = this.props.i18n.language || "fr";
    /*let url = this.props.path ? this.props.path.url : ''*/
    const paths = {
      vosDroits: lang === "fr" ? "/vos-droits" : this.props.t("url_vosDroits"),
    };

    const loaderStyle = {
      position: "fixed",
      width: "50px",
      height: "auto",
      top: "500px",
      left: 0,
      right: 0,
      margin: "auto",
      zIndex: 2,
    };
    let isTablet = window.innerWidth < 768;

    let regexMail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let regexTel = /^(\+\d{1,4})?(\d{10})$/;
    let checkEmail = regexMail.test(this.state.email);
    let checkTel = regexTel.test(this.state.tel);
    let isFormCompleted =
      this.state.civilite &&
      this.state.nom &&
      this.state.prenom &&
      checkEmail &&
      checkTel &&
      this.state.permis &&
      this.state.age &&
      this.state.rgpd &&
      this.state.check;
    var errorStyle = {
      color: "red",
    };

    let tabPermis = ["AM", "B", "B*", "A1", "A2", "A","Sans permis"];
    let validePermis = false;
    const pendingState = this.state.isPendingState && (
      <div style={loaderStyle}>
        <Loader type="Oval" color="#E71D1F" height="80" width="80" />
      </div>
    );

    const errorMessage = this.state.showErrorMessage && (
      <Popup
        message={this.props.t(
          "Nos services sont momentanément indisponibles. Veuillez renouveler votre réservation ultérieurement. Merci."
        )}
        handleClose={() => this.toggleErrorPopup()}
      />
    );

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    let isCallCenter = false;
    if (queryString.length != 0) {
      var agent = urlParams.get('agent');
      var origine = urlParams.get('origine');

      if(agent != null && origine != null){
        isCallCenter = true;
      }
    }

    // Forcer la veleur sans permis pour les E-Bikes
    if (['VAE1', 'VAE2'].indexOf(this.props.selectedVehicle.categorie) != -1) {
      this.props.selectedVehicle.permis = "Sans permis"
    }

    return (
      <Container id="allVehicles">
        {pendingState}
        {errorMessage}
        <Row>
          <SideInfoAgency isTablet={isTablet ? "none" : "block"} />

          <Col md="8">
            <h1>
              <Trans i18nKey="conducteur">Informations Conducteur</Trans>
            </h1>
            <div class="promo-code">
              <div class="promo-inputs">
              <Input
                      name="code"
                      onChange={this.handleChange.bind(this, "code_promo")}
                      value={this.state.code_promo}
                      placeholder={this.props.t("Code cadeau")}
                    />
              </div>
           <div class="btns">
           <Button
                className="roundBtn"
                id="btn-promo-code"
                style={{ marginTop: 0 }}
                onClick={
                  this.state.code_promo != ""
                    ? this.ApplyPromoCode.bind(this)
                    : null
                }
                color="primary"
                size="lg"
                block
              >
                <Trans i18nKey="appliquer">Appliquer</Trans>
              </Button>
           </div>
            </div>
            { this.state.code_promo_error != "" ?    
              <div className="promo-error" style={errorStyle}>
                <Trans i18nKey={this.state.code_promo_error == 'Votre solde est insuffisant.' ? 'solde_promo' : 'promo_incorrect'}>{this.state.code_promo_error}</Trans></div>
                : null 
            }
            {this.state.code_promo_before != 0 ?          
            <div class="promo-code-result">
            <label>Solde avant utilisation :</label> <span>{this.state.code_promo_before} €</span> <br/>
            
            <label>Solde après utilisation :</label> <span>{this.state.code_promo_after} €</span>
        </div>
        : ""
          }
            <div style={errorStyle}>{this.state.erreur}</div>
            <div className="vehicleAvailable formConducteur">
              <p>
                <em>
                  <Trans i18nKey="champs_marques">
                    Les champs marqués d'un * sont obligatoires{" "}
                  </Trans>
                </em>
              </p>
              <form name="form-driver-informations">
                <Row>
                  <Col>
                    <Input
                      onChange={this.handleChange.bind(this, "civilite")}
                      type="select"
                      name="civilite"
                    >
                      <option value="">{this.props.t("Civilité*")}</option>
                      <option
                        value="Mme"
                        selected={"Mme" == this.state.civilite}
                      >
                        {this.props.t("Mme")}
                      </option>
                      <option value="M" selected={"M" == this.state.civilite}>
                        {this.props.t("M")}
                      </option>
                    </Input>
                    <span style={errorStyle}>
                      {!this.state.civilite && this.state.emptyInput}
                    </span>
                  </Col>
                  <Col>
                    <Input
                      name="lname"
                      onChange={this.handleChange.bind(this, "nom")}
                      value={this.state.nom}
                      maxLength="15"
                      placeholder={this.props.t("Nom*")}
                    />
                    <span style={errorStyle}>
                      {!this.state.nom && this.state.emptyInput}
                    </span>
                  </Col>
                  <Col>
                    <Input
                      name="fname"
                      onChange={this.handleChange.bind(this, "prenom")}
                      maxLength="15"
                      value={this.state.prenom}
                      placeholder={this.props.t("Prénom*")}
                    />
                    <span style={errorStyle}>
                      {!this.state.prenom && this.state.emptyInput}
                    </span>
                  </Col>
                </Row>
                <Row>
                  <Col md="6" className="col">
                    <Input
                      name="adresse"
                      onChange={this.handleChange.bind(this, "adresse")}
                      value={this.state.adresse}
                      maxLength="50"
                      placeholder={this.props.t("Adresse")}
                    />
                  </Col>
                  <Col md="3" className="col">
                    <Input
                      name="cp"
                      onChange={this.handleChange.bind(this, "cp")}
                      value={this.state.cp}
                      maxLength="16"
                      placeholder={this.props.t("Code Postal")}
                    />
                  </Col>
                  <Col md="3" className="col">
                    <Input
                      name="ville"
                      onChange={this.handleChange.bind(this, "ville")}
                      value={this.state.ville}
                      maxLength="50"
                      placeholder={this.props.t("Ville")}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Input
                      name="email"
                      type="email"
                      onChange={this.handleChange.bind(this, "email")}
                      value={this.state.email}
                      maxLength="120"
                      placeholder={this.props.t("Email*")}
                    />
                    <div style={errorStyle}>
                      {!checkEmail &&
                        this.state.erreur &&
                        this.props.t("format d'adresse email invalide")}
                    </div>
                  </Col>
                  <Col>
                    <Input
                      name="tel"
                      onChange={this.handleChange.bind(this, "tel")}
                      value={this.state.tel}
                      maxLength="50"
                      placeholder={ isCallCenter ? "+33XXXXXXXXX" : this.props.t("Téléphone*")}
                      title={ isCallCenter ? "Utiliser le format international (ex pour la france +33612340001)" : this.props.t("Téléphone*")}
                    />
                    <div style={errorStyle}>
                      {!checkTel &&
                        this.state.erreur &&
                        this.props.t("format de numéro de téléphone invalide")}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Input
                      onChange={this.handleChange.bind(this, "age")}
                      type="select"
                      name="age"
                      placeholder="Age"
                      >
                      
                      <option value="">Age*</option>
                      {
                        (
                          this.props.selectedVehicle.categorie === "S1" ||
                          this.props.selectedVehicle.categorie === "S2" ||
                          this.props.selectedVehicle.categorie === "S3" ||
                          this.props.selectedVehicle.categorie === "M1" ||
                          this.props.selectedVehicle.categorie === "M2" ||
                          this.props.selectedVehicle.categorie === "M3" ||
                          (this.props.selectedVehicle.categorie === "S4" && this.props.selectedVehicle.permis !== "B*")
                        )
                        ?
                          <option value="23">
                            {this.props.t("23 ans et 2 ans de permis")}
                          </option> 
                        :
                        (this.props.selectedVehicle.categorie === "VAE1" || this.props.selectedVehicle.categorie === "VAE2") 
                        ?
                          <option value="18 ans et plus">
                            {this.props.t("18 ans et plus")}
                          </option>
                        :
                        (this.props.selectedVehicle.categorie === "E" || (this.props.selectedVehicle.categorie ==="S4" || this.props.selectedVehicle.permis === "B*")) 
                        ?
                          <option value="23">
                            {this.props.t("23 ans et 2 ans de permis (possible permis B + 7h formation)")}
                          </option>
                        :
                          <option value="25">
                            {this.props.t("25 ans et 2 ans de permis")}
                          </option>
                      }

                      {/* <option value="25" selected={"25" == this.state.age}>
                        {this.props.t("plus de 25 ans et 3 ans de permis")}
                      </option> */}
                    </Input>
                    <span style={errorStyle}>
                      {!this.state.age && this.state.emptyInput}
                    </span>
                  </Col>
                  <Col>
                    <Input
                      onChange={this.handleChange.bind(this, "permis")}
                      type="select"
                      ref={this.selectPermis}
                      name="selectPermis"
                      disabled={this.props.selectedVehicle.categorie === "VAE1" || this.props.selectedVehicle.categorie === "VAE2" ? true : false}
                    >
                      {(this.props.selectedVehicle.categorie != "VAE1" && this.props.selectedVehicle.categorie != "VAE2") && <option value="">{this.props.t("Permis*")}</option>}
                      {tabPermis.map((val, i) => {
                        validePermis = false;

                        if (val === this.props.selectedVehicle.permis) {
                          if((this.props.selectedVehicle.categorie != "VAE1" && this.props.selectedVehicle.categorie != "VAE2") && val == "Sans permis"){
                            validePermis = false;
                          }else{
                            validePermis = true;
                          }
                        }

                        if (validePermis) {
                          return (
                            <option
                              key={i}
                              value={val}
                              selected={val == this.state.permis}
                            >
                              {val === "B*"
                                ? this.props.t(
                                    "B (2 ans de permis et 7h de formation)"
                                  )
                                : this.props.t(val)}{" "}
                            </option>
                          );
                        } else {
                          return null;
                        }
                      })}
                    </Input>
                    <span style={errorStyle}>
                      {!this.state.permis && this.state.emptyInput}{" "}
                    </span>
                  </Col>
                </Row>
              </form>
              <Row>
                <Col>
                  <div className="fix-accept-cgl">
                    <CustomInput
                      onChange={this.handleChange.bind(this, "check")}
                      checked={this.state.check}
                      type="checkbox"
                      id="exampleCustomInline"
                      label=""
                      inline
                    /> 
                    <p style={{ marginLeft: -10 }}>
                      <label className="accept-cgl">
                        <Trans i18nKey="j_accepte">J’accepte les </Trans>
                      </label>
                      <a
                        className="cgl-link"
                        rel="noopener noreferrer"
                        href={this.hrefChangeLanguageCgl}
                        target="_blank"
                      >
                        <Trans i18nKey="conditions">
                          {" "}
                          conditions générales de réservation, de paiement en
                          ligne et de location.
                        </Trans>
                      </a>
                    </p>
                  </div>
                  <span id="fix-error-cgl" style={errorStyle}>
                    {!this.state.check && this.state.msgLocation}
                  </span>
                  <CustomInput
                    onChange={this.handleChange.bind(this, "rgpd")}
                    checked={this.state.rgpd}
                    type="checkbox"
                    id="exampleCustomInlineRGPD"
                    label=""
                    inline
                  />
                  <p className="littleTxt">
                    <Trans i18nKey="je_reconnais">
                      Je reconnais que les informations portées sur ce
                      formulaire me concernant ont un caractère obligatoire et
                      sont recueillies par YAMAHA MOTOR EUROPE dans le cadre
                      d'une location de moto et de la relation commerciale qui
                      peut en découler. Conformément au règlement européen n°
                      2016/679, dit Règlement Général sur la Protection des
                      Données à caractère personnel du 14 avril 2016, vous
                      bénéficiez d'un droit d'accès, de rectification, de
                      portabilité, d'opposition d'effacement des données
                      personnelles vous concernant à tout moment soit : En
                      remplissant le formulaire en ligne dédié à l'expression
                      des droits à l'adresse suivante :{" "}
                    </Trans>
                    <a
                      rel="noopener noreferrer"
                      href={paths.vosDroits}
                      target="_blank"
                    >
                      <Trans i18nKey="form_exercice">
                        Formulaire d'exercice des droits
                      </Trans>
                    </a>
                    <Trans i18nKey="contact_DPO">
                      , soit en contactant le DPO à l'adresse suivante :{" "}
                    </Trans>
                    <a
                      rel="noopener noreferrer"
                      href="mailto:dpo@yamaha-motor.nl"
                    >
                      dpo@yamaha-motor.nl
                    </a>
                    .
                    <Trans i18nKey="plus_infos">
                      Pour plus d'information, veuillez consulter la{" "}
                    </Trans>
                    <a
                      rel="noopener noreferrer"
                      href={this.hrefChangeLanguage}
                      target="_blank"
                    >
                      <Trans i18nKey="politique">
                        Politique de Protection des Données
                      </Trans>
                    </a>
                  </p>
                  <span style={errorStyle}>
                    {!this.state.rgpd && this.state.msgRGPD}
                  </span>
                </Col>
              </Row>
            </div>
            <div
              style={{
                display: isTablet ? "block" : "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button
                style={{
                  marginRight: isTablet ? 0 : 10,
                  marginBottom: isTablet ? 10 : 0,
                }}
                id="btninfoconducteur"
                className="roundBtn"
                onClick={() => this.handleChangeSection(3)}
                color="primary"
                size="lg"
                block
              >
                ⟵ <Trans i18nKey="etape_precedente">Etape précédente</Trans>
              </Button>
              {this.handlesaveConducteurInfo.bind(this) ? (
              <Button
                className="roundBtn"
                id="btninfoconducteurdeux"
                style={{ marginTop: 0 }}
                onClick={
                  isFormCompleted
                    ? this.handleChangeSection.bind(this)
                    : this.missingInput.bind(this)
                }
                color="primary"
                size="lg"
                block
              >
                <Trans i18nKey="continuer">Etape suivante</Trans> ⟶
              </Button>
              ) : null}
              <Button
                id="deviserror"
                hidden
                style={{ marginTop: 0 }}
                onClick={!isFormCompleted ? this.missingInput.bind(this) : null}
                color="primary"
                size="lg"
                block
              ></Button>
            </div>
          </Col>
        </Row>
        <div className="clear"></div>
        <SideInfoAgency isTablet={isTablet ? "block" : "none"} />
      </Container>
    );
  }
}

function mapStateToProps(state) {
  return {
    store: state.reducer,
    bookingTotalPrice: bookingTotalPriceSelector(state),
    globalPrice: state.reducer.globalPrice,
    bookingPromoPrice:state.reducer.promoPrice,
    selectedVehicle: state.reducer.selectedVehicle,
    usolServices: state.reducer.selectedVehicle.usol_tarif,
    selectedAgency: state.reducer.selectedAgency,
    startDate: state.reducer.startDate,
    endDate: state.reducer.endDate,
    addedKm: state.reducer.addedKm,
    priceOption1: state.reducer.priceOption1,
    priceOption2: state.reducer.priceOption2,
    priceOption3: state.reducer.priceOption3,
    infoConducteur: state.reducer.infoConducteur,
    devisStatus: state.reducer.devisStatus,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      changeSectionReservation,
      saveConducteurInfo,
      saveReference,
      setPromoPrice,
      setPromoCode,
      saveDevisStatus
    },
    dispatch
  );
}

const exportComponent = withRouter((props) => <Conducteur {...props} />);
export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(exportComponent)
);
/*export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Conducteur));*/
