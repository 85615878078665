import React, {Component} from 'react';
//COMPONENTS
import Header from '../staticComponents/header'
import Footer from '../staticComponents/footer'
import {Container, Row, Col} from 'reactstrap';
//IMG
import casque from '../assets/img/accessoires-casque.jpg'
import gants from '../assets/img/accessoires-gants.jpg'
import veste from '../assets/img/accessoires-veste.jpg'
import pantalon from '../assets/img/accessoires-pantalon.jpg'
import {getUserIP} from "../services/getIpAdress";

//TRANSLATION
import {Trans, withTranslation} from 'react-i18next';
import i18n from "../i18n";//TODO verif

import {withRouter} from 'react-router-dom';
import Helmet from "react-helmet/es/Helmet";
import {Link} from 'react-router-dom';
import { localStorageAgencyCode } from '../configuration';

//TODO verif

class Solutions extends Component {
    /*----tag GTM-----*/
    pushTagGtm(ip) {
        const internal = [
            '176.149.189.93',//IP Unitec Fibre
            '82.64.74.58',//IP Unitec Guest
            '82.229.26.224',//IP Eric
            '78.237.92.155',//IP CJA
            '172.19.0.1',
        ]

        let traffic = internal.indexOf(ip) === -1 ? 'externe' : 'interne'

        /* window.dataLayer = window.dataLayer || [];*/
        window.dataLayer.push({
            'event': 'virtualpageview',
            'virtualPath': window.location.pathname,
            'page_name': 'accessoires_et_securite',
            'page_type': 'contenu',
            'category': '',
            'sub_category_lvl1': '',
            'langage': 'fr',
            'country': 'FR',
            'product_name': '',
            'traffic': traffic,
            'agency': '',
        })
    }

    componentDidMount(){
        localStorage.setItem('localStorageSetShopId', localStorageAgencyCode)

        setTimeout(() => {
            if (this.props.match.params.hasOwnProperty('lang')) {
                i18n.changeLanguage(this.props.match.params.lang)
            }
        }, 0)//TODO verif
    }

    render() {
        let lang = this.props.i18n.language || 'fr'
        getUserIP((ip) => {
            this.pushTagGtm(ip)
        })

        return (
            <div>
                <Header path={this.props.match}/>
                <Helmet>
                    <meta name="description" content={this.props.t("Pour garder intact votre plaisir de piloter une moto ou un scooter Yamaha, portez toujours un casque, des gants et les accessoires de sécurité adéquats. Dans votre agence Yamaha rent vous pourrez louer ces accessoires moto et scooter.")}/>
                    <title>{this.props.t("Location accessoires motos et scooters – Yamaha Rent")}</title>
                </Helmet>
                <Container style={{height: 400, backgroundPosition: 'top center'}} id="searchBg"
                           className="accessoires"/>
                <Container className="textAccessoire">
                    <Row>
                        <Col xs="2"/>
                        <Col>
                            <h1><Link
                                to={lang === 'fr' ? 'agences-de-location' : 'rental-agencies'}
                                className="internal-linking-accessories"
                            ><Trans i18nKey="accessoires_titre">Location d’accessoires Moto et Scooter</Trans></Link></h1>
                            <p><Trans i18nKey="pour_garder_intact">
                                Pour garder intact votre plaisir de piloter une Yamaha, portez toujours un casque, des
                                protections
                                oculaires, des gants et un habillement adéquat. Yamaha vous encourage à rouler avec
                                prudence et à
                                respecter les autres conducteurs, l'environnement et la réglementation en vigueur. La
                                sécurité routière
                                est l'affaire de tous et de chaque conducteur. De cette prise de conscience dépend la
                                préservation de
                                notre outil de mobilité préféré.</Trans>
                            </p>
                            <div style={{display: 'flex'}}>
                                <div style={{flexBasis: 0, flexGrow: 1}}>
                                    <img alt="casque" style={{maxWidth: '100%'}} src={casque}/>
                                </div>
                                <div style={{flexBasis: 0, flexGrow: 1}}>
                                    <img alt="gants" style={{maxWidth: '100%'}} src={gants}/>
                                </div>
                                <div style={{flexBasis: 0, flexGrow: 1}}>
                                    <img alt="pantalon" style={{maxWidth: '100%'}} src={pantalon}/>
                                </div>
                                <div style={{flexBasis: 0, flexGrow: 1}}>
                                    <img alt="veste" style={{maxWidth: '100%'}} src={veste}/>
                                </div>
                            </div>
                            <h2><Trans i18nKey="besoins_specifiques">Besoins spécifiques ?</Trans></h2>
                            <p>
                                <Trans i18nKey="n_hesitez_pas">N'hésitez pas à contacter votre </Trans>
                                <Link
                                    to={lang === 'fr' ? 'agences-de-location' : 'rental-agencies'}
                                    className="internal-linking-dealerlocator"
                                >
                                    <Trans i18nKey="agence-yamaha">agence Yamaha Rent</Trans>
                                </Link>
                                <Trans i18nKey="afin-de-connaitre"> afin de connaître les équipements
                                    et accessoires disponibles à la location.</Trans>
                            </p>
                        </Col>
                        <Col xs="2"></Col>
                    </Row>
                </Container>
                <Footer/>
            </div>
        );
    }
}

const exportComponent = withRouter(props => <Solutions {...props}/>);//TODO verif
export default withTranslation()(exportComponent);//TODO verif
