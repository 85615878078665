import { Component } from 'react';

class Version extends Component {
    componentDidMount() {
        console.log('06/10/2021')
    }

    render() {
        return ('');
    }
}

export default Version;
