import React, {Component} from 'react';
import {Trans, withTranslation} from 'react-i18next';
import "../assets/css/style.css"

import i18n from "../i18n";
import {withRouter} from 'react-router-dom';
import {connect} from "react-redux";
export let choosenLanguage = "fr";

class ChangeLanguage extends Component {

    constructor(props) {
        super(props);
        this.setState({
            language: 'fr'
        })
    }

    componentDidMount() {
        setTimeout(() => {
            this.lang = this.props.i18n.language || 'fr'
            this.props.i18n.changeLanguage(this.lang)
        }, 0)
    }

    render() {
        return (
            <div>
                <button className={'btnsChangeLanguage ' + (this.lang === 'fr' ? 'active' : '')} onClick={() => {
                    //choosenLanguage = 'fr'
                    //i18n.changeLanguage("fr")
                    document.location.href = '/'
                }}>FR</button>
                <button className={'btnsChangeLanguage ' + (this.lang === 'en' ? 'active' : '')} onClick={() => {
                    choosenLanguage = 'en'
                    //i18n.changeLanguage("en")
                    document.location.href = '/en'
                }}>EN</button>
            </div>
        );
    }

};

// extended main view with translate hoc
const exportComponent = withRouter(props => <ChangeLanguage {...props}/>);
export default withTranslation()(exportComponent);
