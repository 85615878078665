import React, {Component} from 'react';
import {Link} from 'react-router-dom'
//STYLE
import {Container, Row, Col} from 'reactstrap';
import "../assets/css/style.css"
//COMPONENTS
import Header from '../staticComponents/header'
import Footer from '../staticComponents/footer'
import CookieConsent from "react-cookie-consent";
// REDUX
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import {changeSectionReservation, saveAllTypeVehicles, cleanStore} from '../redux/action'
//MODULES
import Search from '../modules/search'
import SliderVehicles from '../modules/sliderVehicles'
//FUNCTION
import {getYamahaAgencies} from '../babel/babelAgenciesService'
import {babelGetVehiclesTypeInfo} from '../babel/babelVehiclesService'
//IMG
import you from '../assets/img/white_you.png'
import rent from '../assets/img/white_yamahaMotor.png'
import moto from '../assets/img/red_moto.png'
import {getUserIP} from "../services/getIpAdress";
//TRANSLATION
import {Trans, withTranslation} from 'react-i18next';

import {withRouter} from 'react-router-dom';
import i18n from '../i18n';
import SearchAgency from "../modules/search-agency";
import {Helmet} from "react-helmet";
import { ENVIRONMENT,localStorageAgencyCode,SERVER_ADDRESS_PACK_CADEAU,SERVER_ADDRESS_PACK_CADEAU_PP } from '../configuration';
import axios from "axios";

class Home extends Component {

    constructor(props) {
        super(props);
        this.state = {
            activeOnglet: 'scooter',
            allAgenciesList: [],
            pack_cadeau_infos:[]
        };
        this.searchRef = React.createRef();
    }

    componentDidMount() {
        this.GET_PACK_CADEAU_INFOS()
        localStorage.setItem('localStorageSetShopId', localStorageAgencyCode)
        localStorage.setItem('isRecapBookingGeolidTagSended', false)
        if (this.props.match.params.hasOwnProperty('lang')) {
            i18n.changeLanguage(this.props.match.params.lang)
        }

        getUserIP((ip) => {
            this.pushTagGtm(ip)
        })

        babelGetVehiclesTypeInfo().then(data => {
            let tabType = []
            for (let i = 0; data.length > i; i++) {
                if (data[i].Type === 'mot' || data[i].Type === 'sco') {
                    tabType.push(data[i])
                }
            }
            this.props.saveAllTypeVehicles(tabType)
            localStorage.setItem('typesVehicles', JSON.stringify(tabType))
        })
        this.props.cleanStore()
        this.props.changeSectionReservation(2)
        localStorage.setItem('storeRedux', JSON.stringify(this.props.store))

        if (localStorage.getItem('listAllAgencies')) {
            this.setState({allAgenciesList: JSON.parse(localStorage.getItem('listAllAgencies'))})
        } else {
            getYamahaAgencies().then(data => {
                localStorage.setItem('listAllAgencies', JSON.stringify(data))
                this.setState({allAgenciesList: data})
            })
        }
    }

    /*----tag GTM-----*/
    pushTagGtm(ip) {

        const internal = [
            '176.149.189.93',//IP Unitec Fibre
            '82.64.74.58',//IP Unitec Guest
            '82.229.26.224',//IP Eric
            '78.237.92.155',//IP CJA
            '172.19.0.1',
        ]

        let traffic = internal.indexOf(ip) === -1 ? 'externe' : 'interne'

        /* window.dataLayer = window.dataLayer || [];*/
        window.dataLayer.push({
            'event': 'virtualpageview',
            'virtualPath': window.location.pathname,
            'page_name': 'homepage',
            'page_type': 'homepage',
            'category': '',
            'sub_category_lvl1': '',
            'langage': 'fr',
            'country': 'FR',
            'product_name': '',
            'traffic': traffic,
            'agency': '',
        })
    }

    autoSearch = (agency) => {
        this.searchRef.handleSelectAgency(agency);
        document.querySelector('#searchBg').scrollIntoView()
    }

    handleOngletClick(type) {
        this.setState({activeOnglet: type})
    }
    GET_PACK_CADEAU_INFOS() {
         axios({
            method: 'GET',
            url: (ENVIRONMENT == "PROD" ? SERVER_ADDRESS_PACK_CADEAU : SERVER_ADDRESS_PACK_CADEAU_PP) +"pack_cadeau_infos",
            //url: "http://localhost:20887/pack_cadeau.aspx/LoadCadeauInfos",
            headers: {
                'Content-Type': 'application/json',
            },
            data: null            
            }).then(data => {
                this.setState({pack_cadeau_infos: data.data})
            
            }).catch(error => {
                console.log(error.message)
                return error
            })
    }

    render() {
        let lang = this.props.i18n.language || 'fr'
        /*let url = this.props.path ? this.props.path.url : ''*/
        const paths = {
            cookieProtectionDonnees: lang === 'fr' ? '/politique-de-protection-des-donnees' : this.props.t('url_cookieProtectionDonnees')
        }

        return (
            <div id="home">
                <Header path={this.props.match}/>
                {/*copie bg search*/}
                <Container id="searchBg">
                    <Helmet>
                        <meta name="description" content={this.props.t("Yamaha Rent est l’offre de location de motos et scooters proposée par Yamaha Motor et son réseau d agences de location agréées. Yamaha Rent, c’est la garantie de louer des motos et scooters récents et entretenus, pour partir l’esprit libre !")} />
                        <title>{this.props.t("Location Moto et scooter Yamaha - Yamaha Rent Officiel")}</title>
                    </Helmet>
                    
                    {/* <h1><Trans i18nKey="exigez">Location de motos et scooters Yamaha ….</Trans></h1>
                    <h1><Trans i18nKey="exigezh2">La mobilité l’esprit libre</Trans></h1> */}

                    <Row id="NoelRow">
                        <Col md="10">
                        <h1>{this.props.i18n.language === 'en' ? this.state.pack_cadeau_infos.title1_en : this.state.pack_cadeau_infos.title1}</h1>
                            <h1 dangerouslySetInnerHTML={{
                                __html: this.props.i18n.language === 'en' ? this.state.pack_cadeau_infos.title2_en : this.state.pack_cadeau_infos.title2,
                            }}></h1>
                        </Col>
                        <Col md="2" id="Colimg">
                            <a href="/pack-cadeau.aspx">
                            <img alt="Pack cadeau"  title="Pack cadeau" src={"/assets/img/"+ (this.props.i18n.language === 'en' ? this.state.pack_cadeau_infos.img_name_en : this.state.pack_cadeau_infos.img_name)} />
                            </a>
                        </Col>
                    </Row>
                    {/* <h1 className="home-h1"><Trans i18nKey="baseLine">Kit Vacances composé d'une serviette de bain,
                            d'une casquette et d'une paire de lunettes de soleil.</Trans></h1>
                    <h2 className="home-h2"><Trans i18nKey="baseLineh2">Kit Vacances offert pour toute location de 3 jours minimum,</Trans></h2>
                    <h2 className="home-h2"><Trans i18nKey="baseLineh3">Offre valable jusqu'au 31 aout 2020</Trans></h2> */}

                    <Search onAutoSearch={ref => (this.searchRef = ref)}/>
                </Container>
                {/*end copie*/}

                <Container id="ongletsVehicles">
                    <Row>
                        <Col onClick={this.handleOngletClick.bind(this, 'scooter')}
                             className={this.state.activeOnglet === 'scooter' ? 'active' : ''}>
                            <div id="preSlider_img_scooter">
                            </div>
                           {this.props.t('Scooters Yamaha à louer')}
                        </Col>
                        <Col onClick={this.handleOngletClick.bind(this, 'moto')}
                             className={this.state.activeOnglet === 'moto' ? 'active' : ''}>
                            <div id="preSlider_img_moto">
                            </div>
                            {this.props.t('Motos Yamaha à louer')}
                        </Col>
                        <Col onClick={this.handleOngletClick.bind(this, 'E-BIKE')}
                            className={this.state.activeOnglet === 'E-BIKE' ? 'active' : ''}>
                            <div id="preSlider_img_ebike">
                            </div>
                            {this.props.t('Vélos électriques Yamaha à louer')}
                        </Col>
                    </Row>
                    <SliderVehicles type={this.state.activeOnglet}/>

                    <Row id="see_all">
                        <div className="arrow">

                        </div>
                        <div className="link" style={{display: 'inline-block', width: 'auto'}}>
                            <Link to={this.state.activeOnglet == 'E-BIKE' ? 'e-bikes' : `vehicules/${this.state.activeOnglet}s`}><Trans>
                                {this.state.activeOnglet === 'scooter' ? 'Tous nos scooters en location' :  (this.state.activeOnglet === 'E-BIKE' ? 'Tous nos vélos électriques en location' :'Toutes nos motos en location')}</Trans>
                            </Link>
                        </div>
                    </Row>
                </Container>

                <Container id="mapAccueil">
                    <SearchAgency />
                </Container>

                <Container id="blackSectionHome">
                    <Row>
                        <Col md="6">
                            <img alt="logo you" src={you}
                                 style={{height: 40, display: 'block', marginBottom: 25, marginTop: 15}}/>
                            <h2 className="red"><Trans i18nKey="titre_offre_you">L'offre de services YOU</Trans></h2>

                            <Trans i18nKey="texte_you">L'offre de services YOU (Yamaha Offers You) regroupe différents
                                services liés à l'achat et à
                                la vie de votre Yamaha. Ces offres nous permettent de vous garantir un niveau de service
                                élevé pour que votre expérience des produits Yamaha soit la plus agréable possible.
                                <br/>
                                Les services YOU rendent l'achat de chaque Yamaha plus accessible - et les propriétaires
                                de
                                Yamaha peuvent bénéficier de la tranquillité d'esprit qui accompagne chaque service YOU.
                                <br/>
                                En détaillant la gamme de services YOU, vous découvrirez que ces services vont bien
                                au-delà
                                du simple achat de votre Yamaha et qu'ils contribuent à créer une relation longue et
                                durable.</Trans></Col>
                        <Col md="6">
                            <img alt="logo yamaha motor" src={rent}
                                 style={{height: 50, marginBottom: 15, marginTop: 15}}/>
                            <img alt="moto et scooter" src={moto}
                                 style={{float: 'right', height: 50, marginBottom: 15, marginTop: 15}}/>
                            <h2 className="red"><Trans i18nKey="location_par_yamaha">La location de motos et scooters par Yamaha</Trans></h2>
                            <Trans i18nKey="location_paragraphe_1">Yamaha Rent est l’offre de location courte durée de
                                motos et scooters Yamaha faisant partie des services YOU </Trans>(<a
                                    rel="noopener noreferrer" href="https://www.yamaha-motor.eu/fr/fr/" target="_blank">Yamaha
                                    Offers You</a>)<Trans i18nKey="location_paragraphe_1bis"> que propose notre marque,
                            afin de vous offrir un service et une expérience produit de premier ordre. Que vous soyez
                            à la recherche d’un deux / trois roues en location, pour le plaisir de rouler lors d’un
                            week-end moto entre amis ou à la recherche d’un moyen de locomotion rapide, sécuritaire
                            et pratique lors de déplacements urbains, Yamaha Rent est la solution idéale qui répond à
                            tous vos besoins de location courte durée de motos et scooters.​
                        </Trans><br/>
                            <Trans i18nKey="location_paragraphe_2">A travers un réseau d’agences agrées Yamaha Rent,
                                reconnu pour sa qualité et son service, vous trouverez le deux / trois roues adapté à
                                votre usage. Yamaha Rent, c’est la garantie d’utiliser des véhicules récents et
                                entretenus, pour partir l’esprit libre.​
                            </Trans><br/>
                            <Link to={lang === 'fr' ? 'agences-de-location' : 'rental-agencies'}
                            ><Trans i18nKey="location_paragraphe_3">Les agences Yamaha Rent</Trans></Link><Trans
                            i18nKey="location_paragraphe_4"> sont à votre disposition pour vous conseiller et vous
                            proposer la moto, le scooter à louer et les accessoires</Trans><span className="red">*</span>
                            <Trans i18nKey="location_paragraphe_5"> nécessaires au bon déroulement de votre voyage.​</Trans>
                            <br/><em style={{fontSize: 13}}><span className="red">*</span><Trans
                            i18nKey="retrouvez_accessoires">Retrouvez la liste des
                            accessoires moto et les accessoires du pilote chez votre
                            agence Yamaha Rent.</Trans></em>
                        </Col>
                    </Row>
                </Container>
                <Footer/>
                
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        store: state.reducer
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        changeSectionReservation,
        saveAllTypeVehicles,
        cleanStore
    }, dispatch)
}

const exportComponent = withRouter(props =>
    <Home {...props}/>
);
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(exportComponent));
/*
test push*/
