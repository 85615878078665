import React, {Component} from 'react';
//STYLE
import {Container} from 'reactstrap';
//IMG
import phone from '../assets/img/phone.png'
//DOC
import conditionPermis from '../assets/doc/condition_permis.pdf'
import noticeAssurance from '../assets/doc/notice_assurance.pdf'
import conditionPermisEn from '../assets/doc/condition_permis_en.pdf'
import noticeAssuranceEn from '../assets/doc/notice_assurance_en.pdf'
import CGU from '../assets/doc/CGU.pdf'
import GRU from '../assets/doc/GRU.pdf'


//TRANSLATION
import {Trans, withTranslation} from 'react-i18next';
import i18n from "../i18n";
import { ENVIRONMENT, SERVER_ASSETS, SERVER_ASSETS_PP } from '../configuration';

const mobileBreakpoint = 450;
class Footer extends Component {
    constructor() {
        super();
        this.state ={
            isMobile: window.innerWidth <= mobileBreakpoint,
        }
    }

    componentDidMount() {
        window.addEventListener('resize', this.resize)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resize)
    }

    resize = () => {
        this.forceUpdate()
    }

    switchPhoneToCta(){
        if (this.state.isMobile){
            return this.props.t('Appeler')
        }
        else {
            return '09.69.39.22.23'
        }
    }

    get conditions() {
        if (i18n.language==="fr"){
            return [
                {name: 'Conditions de permis', link: conditionPermis},
                {name: 'Mentions légales', link: "https://www.yamaha-motor.eu/fr/fr/privacy/"},
                {
                    name: 'Politique de protection des données',
                    link: "https://www.yamaha-motor.eu/fr/fr/privacy/privacy-policy/"
                },
                {name: 'Conditions Générales', link: (ENVIRONMENT == "PROD" ? SERVER_ASSETS : SERVER_ASSETS_PP) +"/doc/conditions-generales-de-location.pdf"},
                {name: 'CGU', link: CGU},
                {name: 'Notice d\'assurance', link: noticeAssurance}
            ]
        }else {
            return [
                {name: 'Conditions of license', link: conditionPermisEn},
                {name: 'Mentions légales', link: "https://www.yamaha-motor.eu/fr/fr/en/privacy/"},
                {
                    name: 'Politique de protection des données',
                    link: "https://www.yamaha-motor.eu/fr/fr/en/privacy/legal-statement/"
                },
                {name: 'General Conditions ', link: (ENVIRONMENT == "PROD" ? SERVER_ASSETS : SERVER_ASSETS_PP) +"/doc/conditions-generales-de-location-en.pdf"},
                {name: 'GRU', link: GRU},
                {name: 'Insurance note', link: noticeAssuranceEn}
            ]
        }
    }

    get href(){
        if (this.state.isMobile){
            return ("tel:+33969392223")
        }else {
            return null
        }
    }

    render() {
        let lang = this.props.i18n.language || 'fr';

        return (
            <Container id="footer">

                <div className="footer_element" id="footer_telephone">
                    <a href={this.href} className="tlf-hotline-footer"><img src={phone} alt="tel" height="35" width="25"/>
                    {this.switchPhoneToCta()}<br/><b>Hotline</b></a>
                </div>

                {this.conditions.map((val, i) => {
                    return (
                        <div key={i} className="footer_element" style={{textAlign: 'center'}}>
                            <a rel="noopener noreferrer" href={val.link} target="_blank"
                               style={{fontSize: '12px'}}><Trans>{val.name}</Trans></a>
                        </div>
                    )
                })}

                <div className="footer_element" id="footer_solutions">
                    <a href={lang === 'fr' ? '/solutions' : this.props.t('url_solutions')} style={{fontSize: '12px'}}>
                    <Trans
                            i18nKey="accessoires">Accessoires et
                            Sécurité</Trans>
                    </a>
                </div>
                <a rel="noopener noreferrer" href="https://www.yamaha-motor.eu/fr/fr/" target="_blank">
                    <div className="footer_element" id="logoYamaha">
                    </div>
                </a>
            </Container>
        );
    }
}

export default withTranslation()(Footer);