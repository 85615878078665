import React, { Component } from 'react';
//COMPONENTS
import Header from '../staticComponents/header'
import Footer from '../staticComponents/footer'
//MODULE
import Search from '../modules/search'

class Reservations extends Component {
  render() {
    return (
      <div>
        <Header />
        <Search />
        <Footer />
      </div>
    );
  }
}

export default Reservations;
