import React, { Component } from 'react';
//STYLE
import { Container } from 'reactstrap';
//COMPONENTS
import Header from '../staticComponents/header'
import Footer from '../staticComponents/footer'
import EbikePresentation from '../modules/EbikePresentation'

class Ebike extends Component {
  render() {
    return (
      <div>
        <Header path={this.props.match} />
        <Container id="allVehicles" className="edit_pd">
          <EbikePresentation
            type = {this.props.match.params.type}
           />
        </Container>
        <Footer />
      </div>
    );
  }
}

export default Ebike;
