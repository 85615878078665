import React from "react";
import { Trans, withTranslation } from "react-i18next";
import "../assets/css/style.css";
import { babelGetAllVehicles } from "../babel/babelVehiclesService";
import { Crashlytics } from "../services/crashlytics/Crashlytics";
import { Link } from "react-router-dom";

/***
 * But du composant :
 * La description d'une agence arrive depuis Babel sous cette forme :
 * {
 *     general_text: "lorem ipsum",
 *     text: "lorem ipsum",
 *     images_url: ["babel.ucarnet.net/files/YA01/image.png", "..."]
 * }
 * Le composant analyse le "text"(qui décrit l'agence) et le "general_text" (qui décrit l'enseigne) et crée du jsx.
 * Il va chercher dans le texte des éléments présents dans le BasePermutor et remplace ces elements par des liens vers différentes pages du site.
 * Il remplace également le nom des modèles par des liens vers les pages qui decrivent ces modèles.
 *
 * Le composant gère le français et l'anglais.
 * Il utilise du jsx <Link/> et non pas du html <a/> car si l'on navigue en dehors de la navigation react des effets de bord surviennent.
 *
 */

// ["string à remplacer", "/lien/a/insérer"]
const basePermutor = [
  //COMMON
  ["Yamaha Rent", "/"],

  //FR
  ["location moto et scooter", "/"],
  ["agences de location motos et scooter", "/agences-de-location"],
  ["Louer une moto", "/"],
  ["Louer un scooter", "/"],
  ["Louer une moto ou un scooter", "/"],

  //EN
  ["motorcycle and scooter rental", "/"],
  ["The motorcycle and scooter rental agencies", "/en/rental-agencies"],
  ["Rent a motorcycle", "/"],
  ["rent a motorcycle", "/"],
  ["Rent a motorcycle or a scooter ", "/"],
];

class AgencyDescription extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      models: [],
    };
  }

  async componentDidMount() {
    // gettings all models from Babel
    // We only keep needed data
    babelGetAllVehicles("SCO,MOT")
      .then((models) => {
        let shittyFormattedModels = models.SCO.concat(models.MOT);
        this.setState({
          models: shittyFormattedModels.map((model) => {
            return {
              name: model.name,
              type: model.type + "s",
              reference: model.reference,
            };
          }),
        });
      })
      .catch((e) => {
        this.setState({ models: [] });
        Crashlytics.captureException(e);
      });
  }

  getPermutor() {
    let language = this.props.i18n.language || "fr";
    let permutor = [...basePermutor];

    // Dynamically add model to permutor
    for (let i = 0; i < this.state.models.length; i++) {
      let link =
        (language === "fr" ? "/" : "/en/") +
        "vehicule/" +
        this.state.models[i].type +
        "/" +
        this.state.models[i].reference;
      permutor.push([this.state.models[i].name, link]);
    }
    return permutor;
  }

  getSplittedText(arrayToSplit, key) {
    let workArray = [];
    arrayToSplit.map((element) => {
      let intermediateArray = element.split(key);

      // add the key between each splitted value
      // intermediateArray = ["", "some text", "some otherText" ]
      intermediateArray = intermediateArray.reduce(
        (myArray, a) => myArray.concat(a, key),
        [key]
      );
      // intermediateArray = ["", "Yamaha Rent","some text", "Yamaha Rent", "some otherText" ]
      intermediateArray.shift();
      intermediateArray.pop();
      workArray = workArray.concat(intermediateArray);
    });
    return workArray;
  }

  getReplacedValues(textArray, permutor) {
    /*
        This function parse the text array and replace every keyword like "Yamaha Rent" or a model's name by a <Link to>
        Other elements are return as <span></span>
        */

    let results = [];
    for (let i = 0; i < textArray.length; i++) {
      let found = false;
      for (let a = 0; a < permutor.length; a++) {
        if (permutor[a][0] === textArray[i]) {
          results.push(
            <Link key={i} to={permutor[a][1]}>
              {permutor[a][0]}
            </Link>
          );
          found = true;
          break;
        }
      }
      if (!found) {
        results.push(<span key={i}>{textArray[i]}</span>);
      } else {
        found = false;
      }
    }
    return results;
  }

  getJsx(text: string, permutor) {
    let initialArray = [text];
    // initialArray = ["Yamaha Rent Cette enseigne est super Louez une yamaha TMAX500 Yamaha c'est vraiment top"]
    for (let i = 0; i < permutor.length; i++) {
      initialArray = this.getSplittedText(initialArray, permutor[i][0]);
    }
    /*
        initialArray = [
             "Yamaha Rent",
             "Cette enseigne est super",
             "",
             "Louez une yamaha",
             "",
             "TMAX500",
             "Yamaha c'est vraiment top"
        ]
       */

    // Getting rid of empty elements
    initialArray = initialArray.filter((el) => {
      return el !== "";
    });
    return this.getReplacedValues(initialArray, permutor);
  }

  render() {
    return (
      <div className={""}>
        <div className="img-yam-agency">
          {this.props.details.description !== null
            ? this.props.details.description.images_url.map((url, i) => {
                return (
                  <img
                    className="img-agency"
                    key={i}
                    src={url+"?"+Math.floor(Math.random()*90000) + 10000}
                    alt={
                      this.props.t("Location scooter moto Yamaha ") +
                      this.props.details.city +
                      " " +
                      i
                    }
                  />
                );
              })
            : ""}
        </div>
        {this.props.details.description !== null ? (
          <h2 className="title-your-agency">
            <Trans>Votre agence</Trans>
          </h2>
        ) : (
          ""
        )}
        {/* <p>
          {this.getJsx(
            this.props.details.description.general_text,
            this.getPermutor()
          )}
        </p>
        <p>{this.props.details.description.text}</p> */}
        {/* <p>
                    {this.getJsx(this.props.details.description.text, this.getPermutor())}
                </p> */}
        <div
          dangerouslySetInnerHTML={{
            __html: this.props.details.description.general_text,
          }}
        />
        <br/>
        <div
          dangerouslySetInnerHTML={{
            __html: this.props.details.description.text,
          }}
        />

        <br />
        <br />
      </div>
    );
  }
}

export default withTranslation()(AgencyDescription);
