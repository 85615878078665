import React, { Component } from 'react';

//DAY PICKER
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import MomentLocaleUtils, { formatDate, parseDate } from 'react-day-picker/moment';
import 'moment/locale/fr';

// REDUX
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { saveSearchDate, saveUnformatedDate, saveChangeMonth, saveHorairesAgency, saveActualiserState } from '../redux/action'

//BABEL
import { babelGetHoraires } from '../babel/babelAgenciesService'
import {Crashlytics} from "../services/crashlytics/Crashlytics";
import {withTranslation} from "react-i18next";

class DayPicker extends Component {
    constructor(props) {
        super(props);

        this.state = {
            startDate: undefined,
            endDate: undefined,
            changeMonth: '',
            horaire: [],
            locale: 'fr'
        };

        this.props.saveActualiserState(false);
    }

    componentDidUpdate(prev) {
        if (this.props.selectedAgency !== prev.selectedAgency && this.props.selectedAgency && prev.time === 'startDate') {
            babelGetHoraires(this.props.selectedAgency.AgenceCode).then(data => {
                this.props.saveHorairesAgency(data)
            })
        }
    }

    handleDayChange(type, day) {
        type === 'startDate' && this.setState({ startDateU: day })
        let allDate
        let startDay = /([A-Z][a-z]{2}) ([0-9]{2}) (20[0-9]{2})/g.exec(day)

        if (startDay) {
            let month = null
            let tabMonth = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

            for (let i = 0; i < tabMonth.length; i++) {
                if (startDay[1] === tabMonth[i]) {
                    if (i < 9) {
                        month = '0'
                    }

                    month = month + (i + 1)
                }
            }

            let hour = type === 'startDate' ? this.props.startHour : this.props.endHour
            allDate = startDay[3] + '-' + month + '-' + startDay[2] + 'T' + hour + ':00:00'
            let changeMonth = /([0-9]{4})-([0-9]{2})/g.exec(allDate)
            changeMonth = new Date(changeMonth[1], changeMonth[2] - 1)
            this.props.saveSearchDate(type, allDate)
            this.props.saveUnformatedDate(type, allDate);
            this.props.saveChangeMonth(changeMonth)

            this.props.saveActualiserState(true)
        }
    }

    isDayDisable = (date) => {
        try {
            let holidays = this.props.closeDays.map((closedDay)=> {return new Date(closedDay.annee, closedDay.mois -1, closedDay.jour, 12 )})

            for (let i=0; holidays.length > i ; i++) {
                if (holidays[i].getDate() === date.getDate() && holidays[i].getMonth() === date.getMonth() && holidays[i].getFullYear() === date.getFullYear()) return true
            }

            if (holidays.includes(date)) 
                return true

            let beforeLimit = this.props.time === 'startDate' ?  new Date() : new Date(Date.parse(this.props.startDateUnformated))
            beforeLimit.setHours(beforeLimit.getHours()-12)

            if (date < beforeLimit){
                return true
            }

            let afterLimit = this.props.time === 'startDate' ? new Date().setFullYear(new Date().getFullYear()+1) :
                new Date(Date.parse(this.props.startDateUnformated)).setMonth(new Date(Date.parse(this.props.startDateUnformated)).getMonth()+1)

            if(date > afterLimit){
                return true
            }
            
            let tabHoraire = this.props.horaireAgency
            let closeAgency = []
            
            for (let i = 0; tabHoraire.length > i; i++) {
                if (tabHoraire[i].ouverture_matin === "None") {
                    let day = i + 1
            
                    if(day === 7) 
                        day = 0
                    
                    closeAgency.push(day)
                }
            }

            if (closeAgency.includes(date.getDay())) 
                return true
        } catch (e) {
            console.log(e)
        }
    }

    render() {
        let tabHoraire = this.props.horaireAgency
        let closeAgency = []
        
        for (let i = 0; tabHoraire.length > i; i++) {
            if (tabHoraire[i].ouverture_matin === "None") {
                let day = i + 1
                
                if(day === 7) 
                    day = 0
                
                closeAgency.push(day)
            }
        }

        /*const WEEKDAYS_SHORT ={
            en: ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'],
        };

        const MONTHS = {
            en:[
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December',
            ]
        }*/

        return (
            <DayPickerInput
                inputProps={{readOnly: true}}
                onDayChange={this.handleDayChange.bind(this, this.props.time)}
                formatDate={formatDate}
                parseDate={parseDate}
                format="LL"
                placeholder={this.props.placeholder}
        
                dayPickerProps={{
                    locale: this.props.hasOwnProperty('i18n') ? this.props.i18n.language : 'fr',
                    localeUtils: MomentLocaleUtils,
                    disabledDays: this.isDayDisable,
                    month: this.props.changeMonth,
                }}
        
                value={this.props.DateDepart}
            />
        );
    }
}

function mapStateToProps(state) {
    return {
        startDateUnformated: state.reducer.startDateUnformated,
        endDateUnformated: state.reducer.endDateUnformated,
        startHour: state.reducer.startHour,
        endHour: state.reducer.endHour,
        selectedAgency: state.reducer.selectedAgency,
        startDate: state.reducer.startDate,
        changeMonth: state.reducer.changeMonth,
        sectionReservation: state.reducer.sectionReservation,
        horaireAgency: state.reducer.horaireAgency,
        actualiserState : state.reducer.actualiserState
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        saveSearchDate,
        saveUnformatedDate,
        saveChangeMonth,
        saveHorairesAgency,
        saveActualiserState
    }, dispatch)
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(DayPicker))