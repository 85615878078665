import React, { Component } from 'react';
import { Switch, Route, withRouter } from 'react-router-dom'

// CSS
import './assets/css/style.css'

//BABEL
import { getYamahaAgencies } from './babel/babelAgenciesService'

//PAGES
import Home from './pages/home'
import Vehicles from './pages/vehicles'
import Reservations from './pages/reservations'
import Solutions from './pages/solutions'
import StepReservation from './pages/stepReservation'
import VehicleDetails from './pages/vehicleDetails'

import VosDroits from "./pages/VosDroits";
import PaymentAccepted from "./pages/PaymentAccepted";
import Cookies from './pages/infoCookies'
import Version from './pages/version'
import OurAgencies from './pages/nos-agences'
import AgencyDetails from './pages/agencyDetails'

//COMPONENTS
import { library } from '@fortawesome/fontawesome-svg-core'
import {
    faGhost,
    faUser,
    faEnvelope,
    faMapMarker
} from '@fortawesome/free-solid-svg-icons'

//POPUP
import Popup from './modules/popup';
import { IdTagGeolid } from "./services/analytics/geolid/idTagGeolid";
import { ENVIRONMENT } from "../src/configuration";

//TRANSLATE
import { Trans, withTranslation } from 'react-i18next';
import i18n from "./i18n";
import Ebike from './pages/Ebike';
import EbikeDetails from './pages/ebikeDetails';

library.add(
    faGhost,
    faUser,
    faEnvelope,
    faMapMarker
)

class App extends Component {
    is_popup = /*@cc_on!@*/false || !!document.documentMode;

    constructor(props) {
        super(props)

        this.state = {
            userName: '',
            password: '',
            showLoginForm: false,
            showPopup: false
        }
    }

    /*-------taggage geolid-------*/

    implementTagGeolid() {
        //localstorage for reload setShopId
        const getLocalStorageSetShopId = localStorage.getItem('localStorageSetShopId')

        IdTagGeolid.initService(window.gt)

        //Taggage geolid - PYSW-143 - On a enlevé le référencement du store (déjà pris en compte dans le GTM)
        /*
        if (!this.props.location.pathname.includes('/nos-agences/')) {
            if (ENVIRONMENT === 'PROD') {
                IdTagGeolid.initShop('GT-1887-1', getLocalStorageSetShopId)
            } else {
                IdTagGeolid.initShop('GT-6124-1', getLocalStorageSetShopId)
            }
            //IdTagGeolid.setShopId(getLocalStorageSetShopId)
        }
        */

        IdTagGeolid.trackPageView()
    }

    implementTagGtm() {
        window.dataLayer = window.dataLayer || [];

        if (ENVIRONMENT === 'PROD') {
            //PYSW-115 - On commente parce que le Google Tag Manager est de trop => Rollback suite mise en prod
            (function (w, d, s, l, i) {
                 w[l] = w[l] || [];
                 w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });

                 var f = d.getElementsByTagName(s)[0],
                     j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : '';
                 j.async = true;
                 j.src = '//www.googletagmanager.com/gtm.js?id=' + i + dl;
                 f.parentNode.insertBefore(j, f);
             })(window, document, 'script', 'dataLayer', 'GTM-5ZHST8T');
        } else {
            //PYSW-115 - On commente parce que le Google Tag Manager est de trop => Rollback suite mise en prod
            //  (function (w, d, s, l, i) {
            //      w[l] = w[l] || [];
            //      w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
                 
            //      var f = d.getElementsByTagName(s)[0],
            //          j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : '';
                 
            //      j.async = true; j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
            //      f.parentNode.insertBefore(j, f);
            //  })(window, document, 'script', 'dataLayer', 'GTM-5MM7K6L');

            //PYSW-143 - Tests en preprod du GTM de prod pour validation
            (function (w, d, s, l, i) {
                w[l] = w[l] || [];
                w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
                
                var f = d.getElementsByTagName(s)[0],
                    j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : '';
                
                j.async = true; j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
                f.parentNode.insertBefore(j, f);
            })(window, document, 'script', 'dataLayer', 'GTM-5ZHST8T');
        }
    }

    componentDidMount() {
        const split = this.props.location.pathname.split('/');
        let lang;

        if (split[1]) {
            lang = split[1]
        }

        if (lang === 'en') {
            i18n.changeLanguage(lang)
        }

        this.implementTagGtm()
        this.implementTagGeolid()

        getYamahaAgencies().then(data => {
            localStorage.setItem('listAllAgencies', JSON.stringify(data))
        })

        if (localStorage.getItem('isLoged') === 'someRandom') {
            this.setState({ showLoginForm: false, })
        }
    }

    handleChange(type, event) {
        this.setState({ [type]: event.target.value });
    }

    handleLogin() {
        let userName = 'YamahaDemo'
        let password = '_YamahaDemo'

        if (this.state.userName === userName && this.state.password === password) {
            this.setState({ showLoginForm: false })
            localStorage.setItem('isLoged', 'someRandom')
        }
    }

    toggleErrorPopup() {
        this.setState({ showPopup: !this.state.showPopup })
        this.is_popup = false;
    }

    handleClick = () => {
        this.setState({ sideIsOpen: !this.state.sideIsOpen })
    }

    render() {
        // Internet Explorer 6-11
        if (this.is_popup) {
            alert("Ce site n’est pas optimisé pour être utilisé avec Internet Explorer. Nous vous invitons à accéder à notre site depuis le navigateur Edge ou tout autre navigateur (Chrome, Safari, Firefox). Merci de votre compréhension.")
        }

        return (
            <div>
                {this.is_popup && <Popup
                    message={this.props.t("Ce site n’est pas optimisé pour être utilisé avec Internet Explorer. Nous vous invitons à accéder à notre site depuis le navigateur Edge ou tout autre navigateur (Chrome, Safari, Firefox). Merci de votre compréhension.")}
                    handleClose={() => this.toggleErrorPopup()}
                />}
                <Switch>
                    <Route exact path='/:lang(en)?' component={Home} />
                    <Route path='/:lang?/(agences-de-location|rental-agencies)' component={OurAgencies} />
                    {/*<Route path='/:lang?/(nos-agences|our-agencies)' component={OurAgencies} />*/}
                    <Route path='/:lang?/(nos-agences|our-agencies)/:slug' component={AgencyDetails} />
                    <Route path='/:lang?/(vos-droits|your-rights)' component={VosDroits} />
                    <Route path='/:lang?/(vehicules|vehicles)/:type' component={Vehicles} />
                    <Route path='/:lang?/e-bikes' component={Ebike} />
                    <Route path="/:lang?/reservations" component={Reservations} />
                    <Route path="/:lang?/solutions" component={Solutions} />
                    <Route path="/:lang?/(etapereservation|stepreservation)" component={StepReservation} />
                    <Route path="/:lang?/(vehicule|vehicle)/:type/:nom" component={VehicleDetails} />
                    <Route path="/:lang?/e-bike/:nom" component={EbikeDetails} />
                    <Route path="/:lang?/(paiement-accepte|payment-accepted)" component={PaymentAccepted} />
                    <Route path="/version" component={Version} />
                    <Route path="/:lang?/(politique-de-protection-des-donnees|data-protection-policy)" component={Cookies} />
                </Switch>
            </div>
        );
    }
}

const exportComponent = withRouter(props => <App {...props} />);

export default withTranslation()(exportComponent);