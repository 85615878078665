import React, { Component } from 'react'
import { Redirect, browserHistory } from 'react-router'
// REDUX
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { changeSectionReservation, savePriceOption, cleanStore, saveStartDate, saveAllTypeVehicles, saveEndDate, saveAllVehiclesAvailable, saveSelectedVehicle, saveSelectedAgency, saveHorairesAgency, saveStartDateUnformated, saveEndDateUnformated, saveAddedKm, storeToUpdate, saveConducteurInfo } from '../redux/action'
import { VehiclesOffersSearch } from "../services/vehiclesOffers/VehiclesOffersSearch";
//STYLE
import { Container, Row, Col } from 'reactstrap';
import Loader from "react-loader-spinner";
//COMPONENTS
import Header from '../staticComponents/header'
import Footer from '../staticComponents/footer'
//RESERVATION
import ListAvailableVehicles from '../modules/reservation_listAvailableVehicles'
import OptionVehicle from '../modules/reservation_optionVehicle'
import Conducteur from '../modules/reservation_conducteur'
import Paiement from '../modules/reservation_paiement'
import Recap from '../modules/reservation_recap'
import { getVehiclesFromAgency } from '../helper/getVehiclesFromAgency'
import { babelGetVehiclesTypeInfo } from '../babel/babelVehiclesService'
import { getYamahaAgencies } from '../babel/babelAgenciesService'
import axios from "axios";

import { ENVIRONMENT, BABEL_V2_URL, BABEL_V2_URL_VERSION, BABEL_V2_URL_PP, BABEL_V2_URL_VERSION_PP } from "../configuration";

//MODULES

//TRAD
import { Trans, withTranslation } from 'react-i18next';

class StepReservation extends Component {

    constructor(props) {

        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const STATUS3D = urlParams.get('a')
        const ENROLLED3D = urlParams.get('b')
        const SIGNVAL3D = urlParams.get('c')
        const ID3D = urlParams.get('d')
        const STATUSPBX = urlParams.get('e')
        if (STATUS3D != null && ENROLLED3D != null && SIGNVAL3D != null && ID3D != null && STATUSPBX != null) {
            window.sessionStorage.setItem("a", STATUS3D);
            window.sessionStorage.setItem("b", ENROLLED3D);
            window.sessionStorage.setItem("c", SIGNVAL3D);
            window.sessionStorage.setItem("d", ID3D);
            window.sessionStorage.setItem("e", STATUSPBX);
        }
        super(props);
        this.state = {
            store: JSON.parse(localStorage.getItem('storeRedux')),
            count: 0,
            selected: 0,
            agence_selected: false,
            activeOnglet: 'scooter',
            allAgenciesList: [],
            isCallCenter: false,
            bookingReference: false,
            bookingDelaiOff: false,
            bookingPayed: false,
        };
        this.searchRef = React.createRef();
        this.goToOptions();
    }

    componentWillMount = () => {
        if (JSON.parse(localStorage.getItem('storeRedux'))) {
            if (this.props.store.startDateUnformated.length === 0 && JSON.parse(localStorage.getItem('storeRedux')).selectedAgency) {
                this.props.storeToUpdate(JSON.parse(localStorage.getItem('storeRedux')))
            }
        } else {
            this.redirectToHomepage()
        }
    }

    componentDidUpdate = (prev) => {
        if (prev.sectionReservation < this.props.sectionReservation || this.props.sectionReservation === 2 || prev.sectionReservation > this.props.sectionReservation) {
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);

            var option1 = urlParams.get('option1');
            var option2 = urlParams.get('option2');
            var option3 = urlParams.get('option3');

            if (option1 != null && option2 != null && option3 != null) {
                const key = 42;
                var priceOption1 = this.decryptStringToNumber(option1, key);
                var priceOption2 = this.decryptStringToNumber(option2, key);
                var priceOption3 = this.decryptStringToNumber(option3, key);

                this.props.store.priceOption1 = parseInt(decodeURIComponent(priceOption1));
                this.props.store.priceOption2 = parseInt(decodeURIComponent(priceOption2));
                this.props.store.priceOption3 = parseInt(decodeURIComponent(priceOption3));
            }

            localStorage.setItem('storeRedux', JSON.stringify(this.props.store))
        }
    }

    handleChangeStep(step) {
        this.props.changeSectionReservation(step)
        if (step === 2) {
            // this.props.saveSelectedVehicle(undefined)
            this.props.saveAddedKm(0)
        }
    }

    redirectToHomepage() {
        this.props.history.push('/')
    }

    decryptStringToNumber(encryptedStr, key) {
        let decrypted = '';
        for (let i = 0; i < encryptedStr.length; i++) {
            decrypted += String.fromCharCode(encryptedStr.charCodeAt(i) ^ key);
        }
        return parseInt(decrypted);
    }

    async goToOptions() {

        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        if (queryString.length != 0) {
            var StartPeriods = urlParams.get('StartPeriods');
            var EndPeriods = urlParams.get('EndPeriods');
            var StartTime = urlParams.get('StartTime');
            var EndTime = urlParams.get('EndTime');
            var AgenceCode = urlParams.get('AgenceCode');
            var email = urlParams.get('email');
            var civilite = urlParams.get('civilite');
            var first_name = urlParams.get('firstName');
            var last_name = urlParams.get('lastName');
            var tel = urlParams.get('tel');
            var age = urlParams.get('age');
            var permis = urlParams.get('permis');
            var adresse = urlParams.get('adresse');
            var cp = urlParams.get('cp');
            var ville = urlParams.get('ville');
            var vehicle = urlParams.get('vehicle');
            var agent = urlParams.get('agent');
            var origine = urlParams.get('origine');
            var bookingReference = urlParams.get('bookingReference');

            if (agent != null && origine != null) {
                this.state.isCallCenter = true;
            }
            localStorage.setItem('isRecapBookingGeolidTagSended', false)
            babelGetVehiclesTypeInfo().then(data => {
                let tabType = []
                for (let i = 0; data.length > i; i++) {
                    if (data[i].Type === 'mot' || data[i].Type === 'sco') {
                        tabType.push(data[i])
                    }
                }
                this.props.saveAllTypeVehicles(tabType)
                localStorage.setItem('typesVehicles', JSON.stringify(tabType))
            })
            this.props.cleanStore()
            localStorage.setItem('storeRedux', JSON.stringify(this.props.store))

            if (localStorage.getItem('listAllAgencies')) {
                this.setState({ allAgenciesList: JSON.parse(localStorage.getItem('listAllAgencies')) })
            } else {
                getYamahaAgencies().then(data => {
                    localStorage.setItem('listAllAgencies', JSON.stringify(data))
                    this.setState({ allAgenciesList: data })
                })
            }
            if (StartPeriods != null && EndPeriods != null && StartTime != null && EndTime != null && AgenceCode != null && email != null
                && civilite != null && first_name != null && last_name != null && tel != null && age != null
                && permis != null && adresse != null && cp != null && ville != null && vehicle != null) {
                this.state.count = 1;
                this.state.agence_selected = true;
                let conducteur = {
                    email: email,
                    gender: civilite,
                    first_name: first_name,
                    last_name: last_name,
                    phone_number: tel,
                    age_range: age,
                    age: age,
                    driving_license_category: permis,
                    address: adresse,
                    zip: cp,
                    city: ville
                }

                this.props.saveConducteurInfo(conducteur);
                sessionStorage.setItem("conducteur", JSON.stringify(conducteur));

                StartPeriods = new Date(StartPeriods);
                this.props.saveStartDateUnformated(StartPeriods)
                StartTime = parseInt(StartTime) + 2;
                StartPeriods.setHours(StartTime, 0, 0);
                StartPeriods = new Date(StartPeriods).toISOString();
                StartPeriods = StartPeriods.replace('/', '-');
                // StartPeriods = StartPeriods.replace('T', ' ');
                StartPeriods = StartPeriods.replace('.000Z', '');

                EndPeriods = new Date(EndPeriods);
                this.props.saveEndDateUnformated(EndPeriods)
                EndTime = parseInt(EndTime) + 2;
                EndPeriods.setHours(EndTime, 0, 0);
                EndPeriods = new Date(EndPeriods).toISOString();
                EndPeriods = EndPeriods.replace('/', '-');
                // EndPeriods = StartPeriods.replace('T', ' ');
                EndPeriods = EndPeriods.replace('.000Z', '');

                this.props.saveStartDate(StartPeriods)
                this.props.saveEndDate(EndPeriods)

                const allAvailableVehicles = await getVehiclesFromAgency(StartPeriods, EndPeriods, AgenceCode);
                this.props.saveAllVehiclesAvailable(allAvailableVehicles);
                var FiltredVehicleInfo = allAvailableVehicles.filter(data => data.categorie == vehicle);
                
                if(FiltredVehicleInfo.length > 0){
                    localStorage.setItem("allInfoVehicle", JSON.stringify(FiltredVehicleInfo[0]));

                    var priceOption = this.manageOptions(FiltredVehicleInfo[0]?.prestation, FiltredVehicleInfo[0])
                    for (let i = 1; i <= 3; i++) {
                        let option = 'priceOption' + i
                        this.props.savePriceOption(option, priceOption[option])
                    }
                }
                var Agencies = JSON.parse(localStorage.getItem("listAllAgencies"));
                var FiltredAgencies = Agencies.filter(data => data.AgenceCode == AgenceCode);
                if (FiltredAgencies.length > 0 && FiltredVehicleInfo.length > 0) {
                    this.props.changeSectionReservation(3)

                    this.handleSelectVehicle(FiltredVehicleInfo[0]);
                    this.handleSelectAgency(FiltredAgencies[0]);
                    this.state.selected = 1;
                    this.forceUpdate();
                }
            } else {
                return this.redirectToHomepage();
            }
        } else {
            this.state.selected = 1;
            this.forceUpdate();
        }

        if (bookingReference != null) {
            localStorage.setItem("bookingReference", bookingReference);
            const boundCheckBooking = this.CheckBooking.bind(this, bookingReference);
            boundCheckBooking();
        }
    }
    CheckBooking(bookingReference) {
        const setbookingReference = this.setbookingReference.bind(this);
        const setbookingDelaiOff = this.setbookingDelaiOff.bind(this);
        const setbookingPayed = this.setbookingPayed.bind(this);
        const props = this.props;
        return axios({
            method: "GET",
            url: (ENVIRONMENT == "PROD" ? BABEL_V2_URL : BABEL_V2_URL_PP) + "/" + (ENVIRONMENT == "PROD" ? BABEL_V2_URL_VERSION : BABEL_V2_URL_VERSION_PP) + "/bookings/get_by_reference/" + bookingReference,
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then(function (response) {
                if (response.data) {
                    // Get the current date
                    var currentDate = new Date();
                    var created_at = response.data.created_at;
                    var givenDate = new Date(created_at);
                    var transactions = response.data.transactions;
                    // givenDate.setHours(givenDate.getHours() - 1);

                    // Configurable minimum number of minutes
                    var minMinutes = 10;
                    // Calculate the difference in minutes
                    var timeDifference = (currentDate - givenDate) / (1000 * 60);

                    if (timeDifference < minMinutes && transactions.length == 0) {
                        setbookingReference();
                        props.changeSectionReservation(5);
                    } else {
                        if(transactions.length > 0){
                            setbookingPayed();
                        }else{
                            setbookingDelaiOff();
                        }
                    }
                } else {
                    setbookingDelaiOff();
                }
            })
            .catch(function (error) {
                setbookingDelaiOff();
            });
    }

    setbookingReference = () => {
        this.setState({ bookingReference: true });
    }

    setbookingDelaiOff = () => {
        this.setState({ bookingDelaiOff: true });
    }
    setbookingPayed = () => {
        this.setState({ bookingPayed: true });
    }

    manageOptions(vehicle, allInfoVehicle) {
        var date1 = new Date(this.props.startDate);
        var date2 = new Date(this.props.endDate);
        var timeDiff = Math.abs(date2.getTime() - date1.getTime());

        var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24))
        let tabOption = ['priceOption1', 'priceOption2', 'priceOption3']
        let objOption = {}

        for (let i = 0; i < tabOption.length; i++) {
            if (tabOption[i] === 'priceOption1') {
                let ttc_price = ['VAE1', 'VAE2'].indexOf(allInfoVehicle.vehicle.category_code) != -1 ? 0 : vehicle[i].Tarifs.TTC;
                objOption = { ...objOption, [tabOption[i]]: ttc_price }
            }
            else {
                objOption = { ...objOption, [tabOption[i]]: vehicle[i].Tarifs.TTC * diffDays }
            }
        }

        return objOption
    }
    handleSelectAgency = (agence) => {
        localStorage.setItem("selectedAgency", JSON.stringify(agence));
        this.props.saveSelectedAgency(undefined)
        this.props.saveSelectedAgency(agence)
        this.props.saveHorairesAgency(agence.HorairesOuverture)
        VehiclesOffersSearch.setAgencySchedule(agence.HorairesOuverture)
        VehiclesOffersSearch.setAgencyClosingDays(agence.JoursFermetures)
    }
    handleSelectVehicle = (Vehicle) => {
        localStorage.setItem("selectedVehicle", JSON.stringify(Vehicle));
        this.props.saveSelectedVehicle(Vehicle)
    }
    count = () => {
        this.setState({ count: this.state.count + 1 })
    }

    render() {
        this.state.agence_selected = !!JSON.parse(localStorage.getItem('storeRedux'))
        const loaderStyle = {
            position: 'fixed',
            width: '50px',
            height: 'auto',
            top: '500px',
            left: 0,
            right: 0,
            margin: 'auto',
            zIndex: 2

        }
        const pendingState = <div style={loaderStyle}><Loader
            type="Oval"
            color="#E71D1F"
            height="80"
            width="80"
        /></div>

        if (this.state.selected > 0) {
            var test
            switch (this.props.sectionReservation) {
                case 2:
                    test = <ListAvailableVehicles />
                    break;
                case 3:
                    test = <OptionVehicle history={this.props.history} />
                    break
                case 4:
                    test = <Conducteur history={this.props.history} />
                    break
                case 5:
                    test = <Paiement history={this.props.history} />
                    break
                case 6:
                    test = <Recap />
                    break
                default:
                    console.log('not a recap step')

            }
        } else {
            test = pendingState
        }

        if (this.state.agence_selected && this.state.count > 0) {
            return (
                <div>
                    <Header />
                    {!this.state.bookingDelaiOff && !this.state.bookingPayed && <Container id="reservationHeader">
                    </Container>}
                    {!this.state.bookingDelaiOff && !this.state.bookingPayed && <Container id="stepReservation">
                        <Row>
                            {!this.state.bookingReference && <Col onClick={this.handleChangeStep.bind(this, 2)}>
                                <div className={this.props.sectionReservation >= 2 ? 'active' : 'nope'}>
                                    <span>1</span><Trans i18nKey="choix_vehicule">Choix du véhicule</Trans>
                                </div>
                            </Col>}
                            {!this.state.bookingReference && <Col
                                onClick={this.props.sectionReservation > 2 ? this.handleChangeStep.bind(this, 3) : null}>
                                <div className={this.props.sectionReservation >= 3 ? 'active' : 'nope'}>
                                    <span>2</span><Trans i18nKey="options">Options</Trans>
                                </div>
                            </Col>}
                            {!this.state.bookingReference && <Col
                                onClick={this.props.sectionReservation > 3 ? this.handleChangeStep.bind(this, 4) : null}>
                                <div className={this.props.sectionReservation >= 4 ? 'active' : 'nope'}>
                                    <span>3</span><Trans i18nKey="conducteur">Conducteur</Trans>
                                </div>
                            </Col>}

                            <Col
                                onClick={this.props.sectionReservation > 4 ? this.handleChangeStep.bind(this, 5) : null}>
                                <div className={this.props.sectionReservation >= 5 ? 'active' : 'nope'}>
                                    <span>{!this.state.bookingReference ? 4 : 1}</span><Trans i18nKey="paiement">Paiement</Trans>
                                </div>
                            </Col>
                            {
                                (!this.state.isCallCenter || (this.state.isCallCenter && this.state.bookingReference))
                                &&
                                <Col
                                    onClick={this.props.sectionReservation > 5 ? this.handleChangeStep.bind(this, 6) : null}>
                                    <div className={this.props.sectionReservation >= 6 ? 'active' : 'nope'}>
                                        <span>{!this.state.bookingReference ? 5 : 2}</span><Trans i18nKey="recap">Récapitulatif</Trans>
                                    </div>
                                </Col>
                            }

                        </Row>
                    </Container>}
                    {!this.state.bookingDelaiOff && !this.state.bookingPayed && test}
                    {this.state.bookingDelaiOff &&
                        <div id="error_payment_deadline">
                            {this.props.t("Vous avez dépassé le délai de paiement. Veuillez rappeler notre call center.")}
                        </div>
                    }
                    {this.state.bookingPayed &&
                        <div id="error_payment_deadline">
                            {this.props.t("Votre réservation est déjà payée. Veuillez rappeler notre call center.")}
                        </div>
                    }
                    <Footer />
                </div>
            );
        } else {
            if (this.state.count === 0) {
                this.count()
                return <div>chargement</div>
            } else {
                return <Redirect to='/' />;
            }

        }


    }
}

function mapStateToProps(state) {
    return {
        store: state.reducer,
        sectionReservation: state.reducer.sectionReservation,
        selectedAgency: state.reducer.selectedAgency,
        startDate: state.reducer.startDate,
        endDate: state.reducer.endDate,
        selectedVehicle: state.reducer.selectedVehicle,
        allInfoVehicle: state.reducer.allInfoVehicle,
        AllVehiclesAvailable: state.reducer.AllVehiclesAvailable,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        changeSectionReservation,
        saveSelectedVehicle,
        saveSelectedAgency,
        saveHorairesAgency,
        saveAllTypeVehicles,
        savePriceOption,
        saveAllVehiclesAvailable,
        saveStartDateUnformated,
        saveEndDateUnformated,
        saveStartDate,
        saveEndDate,
        saveAddedKm,
        cleanStore,
        storeToUpdate,
        saveConducteurInfo
    }, dispatch)
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(StepReservation));
